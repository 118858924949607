import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../app/store";
import { selectError } from "../app/store/appSlice";
import { LinkButton } from "../style";

const ModalOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: height 0.2s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);
  background: linear-gradient(0deg, rgba(11, 10, 10, 0.72) 0%, rgba(13, 12, 12, 0.48) 100%);
  padding-right: 8px;
`

const Modal = styled.div`
  animation: fadeInUp 0.4s linear;
  opacity: 1;

  background: #fff;
  max-width: 240px;
  width: 100%;
  z-index: 101;
  border: 1px solid #E6E6E6;
  box-shadow: 0 24px 64px #0000001f;
  border-radius: 12px;
  padding: 12px;

  h3 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -.05em;
    color: #000;
    font-weight: 400;
    margin: 0 0 6px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.005em;
    color: #999;
    margin: 0;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 10px;
`


export const ErrorModal: React.FC = () => {
    const isActive = useAppSelector(selectError)

    return (
        isActive ? (
            <ModalOverlay>
                <Modal>
                    <h3>Oops</h3>
                    <p>Something went wrong. Please try again.</p>
                    <ButtonWrapper>
                        <LinkButton href="/">Ok</LinkButton>
                    </ButtonWrapper>
                </Modal>
            </ModalOverlay>
        ) : null
    )
}