import React from "react";
import styled from "styled-components";
import { VStack } from "../layout";
import { Modal, ModalProps, MODAL_BOTTOM_OFFSET, MODAL_TOP_OFFSET } from "./Modal";

const Container = styled(VStack)`
    height: calc(100vh - ${MODAL_TOP_OFFSET + MODAL_BOTTOM_OFFSET}px);
    overflow: hidden;
`;

export const LightBox: React.FC<ModalProps> = ({ children, ...props }) => (
    <Modal {...props}>
        <Container width="936px" minWidth="936px">
            {children}
        </Container>
    </Modal>
);
