import React from "react";
import { useAppSelector } from "../app/store";
import { selectSelectedInstrument } from "../app/store/appSlice";
import { SkeletonTitleWrapper, SubTitle } from "./Card";
import { SkeletonLine } from "../style";
import moment from "moment/moment";
import styled from "styled-components";
import { onMobile } from "../const";

const SubTitleSkeletonLine = styled(SkeletonLine)`
  width: 5.3rem;
`

const CurrentDate = styled.span`
  ${onMobile} {
    display: none;
  }
`

export const TableSubTitle: React.VFC = () => {
    const instrument = useAppSelector(selectSelectedInstrument);

    const getSubTitle = () => {
        const [firstCurrency, secondCurrency] = instrument!.split('/');

        return (
            <>
                All prices are in <b>{secondCurrency}</b> per <b>{firstCurrency}</b>
            </>
        )
    }

    return (
        instrument ? (
            <>
                <SubTitle>
                    {
                        instrument && getSubTitle()
                    }
                </SubTitle>
            </>
        ) : (
            <SkeletonTitleWrapper>
                <SubTitleSkeletonLine/>
            </SkeletonTitleWrapper>
        )
    )
}

export const TableTitle: React.FC = () => {
    const text = 'Real-time Index';
    const currentDate = moment().format('DD MMMM YYYY')

    return (
        <>
            {text}
            <CurrentDate>{currentDate}</CurrentDate>
        </>
    )
}