import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useIsActivePath = () => {
    const location = useLocation();

    return {
        isActivePath: useCallback(
            (path: string) => {
                return location.pathname.includes(path);
            },
            [location],
        ),
    };
};
