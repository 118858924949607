import moment from "moment";
import { Config } from "../../app/store/appSlice.types";
import { AnalyticsPeriods } from "../../app/store/turnoverSlice.types";
import { ONE_THOUSAND } from "../../const";
import { SelectItem } from "../../types";

export const mapInstruments = (config: Config) => {
    if (!config?.instruments?.length) {
        return [];
    }

    return config.instruments
        .map((item) => {
            return {
                label: item,
                value: item,
            } as SelectItem;
        })
        .sort((left, right) => left.value.localeCompare(right.value));
};

export const getVolumeLabel = (volume: number) => {
    let label = "$";

    if (volume < 1) {
        label += `${volume * ONE_THOUSAND}k`;
    }
    if (volume >= 1 && volume < ONE_THOUSAND) {
        label += `${volume}M`;
    }
    if (volume >= 1000) {
        label += `${volume / ONE_THOUSAND}B`;
    }

    return label + "+";
};

export const mapVolumes = (config: Config) => {
    if (!config.volumes.length) {
        return [];
    }

    return config.volumes.map((volume) => {
        return {
            label: getVolumeLabel(volume),
            value: volume,
        } as SelectItem;
    });
};

export const mapPeriods = (config: Config) => {
    if (!config.periods.length) {
        return [];
    }

    return config.periods.map((item) => {
        return {
            label: item,
            value: item,
        } as SelectItem;
    });
};

export const mapAmounts = (config: Config, selectedInstrument: string, baseCurrency: string) => {
    if (!Object.keys(config.sizes).length || !selectedInstrument || !baseCurrency) {
        return [];
    }

    return config.sizes[selectedInstrument].map((item) => {
        return {
            label: `${item} ${baseCurrency}`,
            value: item,
        } as SelectItem;
    });
};

export const mapBaseCurrency = (instrument: string) => {
    return instrument?.split("/")?.[0];
};

export const validateQueryItem = (list: SelectItem[], queryItem: number | string | undefined) => {
    if (!list?.length || !queryItem) {
        return undefined;
    }

    return list.find((i) => i.value === queryItem)?.value;
};

export const getPeriodLabel = (periodKey: string) => {
    if (periodKey === AnalyticsPeriods["24h"]) {
        return "Last 24h";
    }
    if (periodKey === AnalyticsPeriods.Today) {
        const dayOfWeek = moment().format("ddd");
        return `Today, ${dayOfWeek}`;
    }
    if (periodKey === AnalyticsPeriods["7d"]) {
        return "Last 7d";
    }
    if (periodKey === AnalyticsPeriods["30d"]) {
        return "Last 30d";
    }
    if (periodKey === AnalyticsPeriods.Month) {
        return moment().format("MMMM YYYY");
    }

    return "";
};

export const isUpTrend = (trend: number) => {
    return trend >= 0;
};
