import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { SignInSteps } from "./SignIn";
import { Button, Error, FormError, FormRow, HeadTitle } from "./style";
import { ValidationRules } from "./utils";
import { Loading } from "../../components/Loading";

export const PasswordResetSubmit: React.FC<{
    email?: string,
    setStep: (step: SignInSteps) => void
}> = ({
          email,
          setStep
      }) => {
    const {
        watch,
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<any>({
        mode: "onChange"
    });

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const password = watch('password');

    const onSubmit = (data: any) => {
        if(!isValid || !email) {
            return;
        }

        setLoading(true);

        Auth.forgotPasswordSubmit(email, data.code, data.password)
            .then((user) => {
                setError(undefined);
                setLoading(false);
                setStep(SignInSteps.Login);
            })
            .catch((error) => {
                setLoading(false);
                setError(error?.message);
            });
    }


    return (
        <>
            <HeadTitle>Reset your password</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    <label>Code</label>
                    <input {...register('code',
                        {
                            ...ValidationRules.code
                        })}
                           placeholder='000000'
                           type={'number'}
                    />
                    {
                        errors.code && (
                            <Error>{errors.code.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    <label>Password</label>
                    <input {...register('password',
                        {
                            ...ValidationRules.password
                        })}
                           placeholder='Password'
                           type={'password'}/>
                    {
                        errors.password && (
                            <Error>{errors.password.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    <label>Confirm Password</label>
                    <input {...register('confirmPassword',
                        {
                            ...ValidationRules.confirmPassword(password),
                        })}
                           placeholder='Confirm Password'
                           type={'password'}/>
                    {
                        errors['confirmPassword'] && (
                            <Error>{errors['confirmPassword'].message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    {
                        error && (
                            <FormError>{error}</FormError>
                        )
                    }
                    <Loading loading={loading}>
                        <Button type={'submit'}>Reset Password</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    )
}