import { FC } from "react";
import styled from "styled-components";
import { ChartData } from "~components/linerChart/LinerChart";
import { fmt } from "~utils/format";
import { REST_CHART_COLOR } from "./utils";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2px 0;
`;
const Sum = styled.div`
    color: white;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
`;
const Period = styled.div`
    color: white;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 4px;
    opacity: 0.8;
`;
const Name = styled.div`
    color: white;
    flex: 1;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const Value = styled.div`
    color: white;
    font-size: 12px;
    line-height: 16px;
`;
const Client = styled.div`
    display: flex;
    margin: 2px 0;
    height: 16px;
    gap: 20px;

    ${Name}, ${Value} {
        color: ${(p) => p.theme.colors.uiWhite72};
    }
`;
const MostValuableClient = styled(Client)`
    ${Name}, ${Value} {
        color: ${(p) => p.theme.colors.uiWhite100};
    }
`;
const OtherClient = styled.div`
    display: flex;
    padding: 2px 0;

    ${Name}, ${Value} {
        color: ${(p) => p.theme.colors.uiWhite72};
    }
`;

const formatValue = (value: number) => fmt(value, { significantDigits: 2, type: "currency" }).formattedValue;

const MAX_TOOLTIP_ITEMS_COUNT = 15;

type BatChartItemTooltipType = {
    period?: string;
    items: ChartData[];
};

export const BatChartItemTooltip: FC<BatChartItemTooltipType> = ({ period, items }) => {
    const mostValuableClients = items.filter((item) => item.color !== REST_CHART_COLOR && item.y > 0);
    const otherClients = items.filter((item) => item.color === REST_CHART_COLOR && item.y > 0);
    const clientsDeficit = MAX_TOOLTIP_ITEMS_COUNT - mostValuableClients.length;
    const restClients = otherClients.slice(0, clientsDeficit);
    const hiddenClients = otherClients.slice(clientsDeficit);
    const entireSum = items.reduce((acc, item) => acc + (item.y < 0 ? 0 : item.y), 0);
    const hiddenSum = hiddenClients.reduce((acc, item) => acc + (item.y < 0 ? 0 : item.y), 0);

    return (
        <Container>
            <Sum>${formatValue(entireSum)}</Sum>
            <Period>{period}</Period>
            {mostValuableClients.map((client) => (
                <MostValuableClient key={client.clientId}>
                    <Name>{client.clientName}</Name>
                    <Value>${formatValue(client.y)}</Value>
                </MostValuableClient>
            ))}
            {restClients.map((client) => (
                <Client key={client.clientId}>
                    <Name>{client.clientName}</Name>
                    <Value>${formatValue(client.y)}</Value>
                </Client>
            ))}
            {hiddenClients.length === 1 && (
                <MostValuableClient key={hiddenClients[0].clientId}>
                    <Name>{hiddenClients[0].clientName}</Name>
                    <Value>${formatValue(hiddenClients[0].y)}</Value>
                </MostValuableClient>
            )}
            {hiddenClients.length > 1 && (
                <OtherClient>
                    <Name>Other ({hiddenClients.length})</Name>
                    <Value>${formatValue(hiddenSum)}</Value>
                </OtherClient>
            )}
        </Container>
    );
};
