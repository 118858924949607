import { useModalControl } from "@fm-frontend/utils";
import { FC, useState } from "react";
import styled from "styled-components";
import { Popover } from "../Popover";
import { PSmall } from "../typography";
import { DatePickerCalendar } from "./Calendar";
import { DatePickerInput } from "./Input";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Label = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

type DatePickerProps = {
    startDate?: Date | null;
    endDate?: Date | null;
    onConfirm?: ({ startDate, endDate }: { startDate?: Date | null; endDate?: Date | null }) => void;
    onReset?: () => void;
    label?: string;
    fullWidth?: boolean;
    simple?: boolean;
    size?: "small" | "medium" | "large";
    minDate?: Date;
    maxDate?: Date;
};

export const DatePicker: FC<DatePickerProps> = ({
    startDate,
    endDate,
    onConfirm,
    onReset,
    label,
    fullWidth = false,
    simple = false,
    size = "small",
    minDate,
    maxDate,
}) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);
    const [value, setValue] = useState({ startDate, endDate });

    const handleConfirm = () => {
        closeModal();
        onConfirm?.(value);
    };
    const handleReset = () => {
        closeModal();
        onReset?.();
    };

    return (
        <Popover
            isOpen={isModalOpen}
            padding={9}
            positions={["bottom"]}
            align="start"
            onClickOutside={handleReset}
            content={
                <DatePickerCalendar
                    startDate={value.startDate}
                    endDate={value.endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={setValue}
                    onConfirm={handleConfirm}
                    onReset={handleReset}
                />
            }
        >
            <Container>
                {label && <Label>{label}</Label>}
                <DatePickerInput
                    startDate={value.startDate}
                    endDate={value.endDate}
                    onClick={openModal}
                    fullWidth={fullWidth}
                    simple={simple}
                    size={size}
                />
            </Container>
        </Popover>
    );
};
