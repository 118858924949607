import styled, { css } from "styled-components";
import { getSpaceCss } from "../../layout/types";
import { CellProps } from "./types";

const cellCss = css<CellProps>`
    text-align: ${(p) => p.textAlign};

    width: ${(p) => p.width};
    min-width: ${(p) => p.minWidth};
    max-width: ${(p) => p.maxWidth};

    margin: ${(p) => getSpaceCss(p.margin)};
    padding: ${(p) => getSpaceCss(p.padding)};

    background-color: ${(p) => p.theme.colors.uiWhite100};
`;

const stickyCss = css<CellProps>`
    ${(p) =>
        p.sticky &&
        css`
            position: sticky;
            left: ${p.leftStickyCellInset ?? 0}px;
            z-index: 1;
        `}

    ${(p) =>
        p.lastStickyColumn &&
        css`
            padding-right: 12px;

            ::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                bottom: 0;
                border-right: 1px solid ${(p) => p.theme.colors.ui8};
            }
        `}
`;

export const Td = styled.td<CellProps>`
    position: relative;
    text-align: left;
    padding: ${(p) => (p.expand ? "0 0 0 8px" : "0 0 0 12px")};
    height: 44px;
    min-height: 44px;
    cursor: pointer;
    ${cellCss};
    ${stickyCss};
`;
export const Th = styled.th<CellProps>`
    text-align: left;
    white-space: nowrap;
    padding: ${(p) => (p.expand ? "0 0 8px 8px" : "0 0 8px 12px")};
    height: 24px;
    min-height: 24px;
    ${cellCss};
    ${stickyCss};

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};
`;

const ROW_SELECTED_CSS = css`
    color: ${(p) => p.theme.colors.brand100};

    ${Td}::after {
        pointer-events: none;
        content: "";
        display: block;
        background-color: ${(p) => p.theme.colors.brand8};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    * > * {
        font-weight: 600;
    }
`;

const ROW_GROUP_CSS = css`
    ${Td}::after {
        pointer-events: none;
        content: "";
        display: block;
        background-color: ${(p) => p.theme.colors.ui4};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const Tr = styled.tr<{ isGroup?: boolean; isSelected?: boolean }>`
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};

    :last-of-type {
        border-bottom: none;
    }

    ${Th}:last-of-type, ${Td}:last-of-type {
        padding-right: 12px;
    }

    ${(p) => p.isGroup && ROW_GROUP_CSS}
    ${(p) => p.isSelected && ROW_SELECTED_CSS}

   :hover ${Td}::after {
        pointer-events: none;
        content: "";
        display: block;
        background-color: ${(p) => (p.isGroup ? p.theme.colors.ui8 : p.theme.colors.brand4)};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
export const Tbody = styled.tbody`
    font-size: 14px;
`;
export const Thead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 2;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -1px;
        border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    }
`;
export const Tfoot = styled.tfoot`
    font-size: 14px;
`;
export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    position: relative;
    z-index: 0;
`;
