import { RadioGroup } from "@fm-frontend/uikit";
import { memo, VFC } from "react";
import { useAppDispatch, useAppSelector } from "~app/store";
import { CompanyTypes } from "~app/store/appSlice.types";
import { setTurnoverConfig } from "~app/store/turnoverSlice";

const SUBACCOUNTS_VIEW_TYPE = "SUBACCOUNTS_VIEW_TYPE";
const COUNTERPARTIES_VIEW_TYPE = "COUNTERPARTIES_VIEW_TYPE";

export const MasterSwitcher: VFC = memo(() => {
    const dispatch = useAppDispatch();
    const masterConfig = useAppSelector((state) => state.turnover.masterConfig);
    const currentTurnoverConfig = useAppSelector((state) => state.turnover.config);

    if (!masterConfig) {
        return null;
    }

    const currentViewType =
        currentTurnoverConfig?.company_type === CompanyTypes.MasterSubaccounts
            ? SUBACCOUNTS_VIEW_TYPE
            : COUNTERPARTIES_VIEW_TYPE;
    const handleViewTypeChange = () => {
        dispatch(
            setTurnoverConfig(
                currentViewType === COUNTERPARTIES_VIEW_TYPE
                    ? masterConfig.subaccountsConfig
                    : masterConfig.counterpartiesConfig,
            ),
        );
    };

    return (
        <RadioGroup value={currentViewType} onChange={handleViewTypeChange}>
            <RadioGroup.Radio value={COUNTERPARTIES_VIEW_TYPE}>with counterparties</RadioGroup.Radio>
            <RadioGroup.Radio value={SUBACCOUNTS_VIEW_TYPE}>with sub-accounts</RadioGroup.Radio>
        </RadioGroup>
    );
});
