import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

interface SocketSlice {
    isConnected: boolean
}

const initialState: SocketSlice = {
    isConnected: false
}

const socketSlice = createSlice({
    name: 'socket',
    initialState: initialState,
    reducers: {
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.isConnected = action.payload;
        },
        startConnecting: (state) => {
            return
        },
        restartConnecting: (state) => {
            return
        },
        forceRestartConnecting: (state) => {
            return
        }
    }
})

export const {
    startConnecting,
    restartConnecting,
    forceRestartConnecting,
    setConnected,
} = socketSlice.actions

export const selectIsConnected = (state: RootState) => state.socket.isConnected

export default socketSlice.reducer;