import { EMAIL_REGEX } from "../../const/regex";

export const INCORRECT_LOGIN_PASSWORD = "Incorrect username or password.";
const requiredError = 'Field Required';

export const ValidationRules = {
    required: {
        required: requiredError

    },
    email: {
        required: requiredError,
        pattern: {
            value: EMAIL_REGEX,
            message: 'Invalid Email'
        }
    },
    password: {
        required: requiredError,
        minLength: {
            value: 8,
            message: 'Must be at least 8 characters'
        },
        validate: {
            hasNumber: (v: string) => /\d/.test(v) || 'Must contain at least 1 digit',
            hasUpperCase: (v: string) => /[A-Z]/.test(v) || 'Must contain at least 1 upper case',
            hasLowerCase: (v: string) => /[a-z]/.test(v) || 'Must contain at least 1 lower case',
            hasSpecialSymbol: (v: string) => /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v)
                || 'Must contain at least 1 special character'
        }
    },
    code: {
        required: requiredError,
        validate: {
            isDigit: (v: string) => /^\d{6}$/.test(v) || 'Code must be 6-digit'
        }
    },
    confirmPassword(password: string) {
        return {
            required: requiredError,
            validate: {
                isMatch: (v: string) => password === v || 'Your passwords must match'
            }
        }
    }
}