import { useTheme } from "styled-components";

export const IconLogo = () => {
    return (
        <svg width="213" height="28" viewBox="0 0 213 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M166.842 4.90724L163.928 15.8472H165.968L167.077 11.6854H168.81C171.188 11.6854 173.274 10.5532 173.564 7.86027C173.771 5.94769 172.502 4.90724 170.247 4.90724H166.842ZM168.969 10.2166H167.465L168.468 6.46791H169.849C171.107 6.46791 171.68 6.98813 171.569 8.01328C171.417 9.42094 170.549 10.2166 168.969 10.2166Z"
                fill="#16C7B0"
            />
            <path
                d="M178.105 16.0002C180.882 16.0002 182.499 14.5161 183.196 11.8997L185.07 4.90724H183.015L181.194 11.6854C180.683 13.5827 179.831 14.3631 178.343 14.3631C176.641 14.3631 176.043 13.5062 176.622 11.41L178.367 4.90724H176.311L174.586 11.3641C173.764 14.4396 175.129 16.0002 178.105 16.0002Z"
                fill="#16C7B0"
            />
            <path
                d="M187.646 4.90724L184.732 15.8472H191.451L191.887 14.2254H187.208L189.687 4.90724H187.646Z"
                fill="#16C7B0"
            />
            <path
                d="M197.19 16.0002C199.844 16.0002 201.554 14.5161 201.775 12.4658C201.965 10.7062 200.94 9.97177 198.72 9.35974C197.126 8.91602 196.749 8.57941 196.828 7.84497C196.925 6.94223 197.593 6.2996 198.882 6.2996C200.032 6.2996 200.753 6.72802 200.73 7.93678H202.601C202.812 5.84058 201.486 4.76953 199.154 4.76953C196.7 4.76953 195.039 6.2231 194.845 8.02858C194.656 9.77286 195.635 10.5073 197.874 11.0887C199.406 11.5324 199.825 11.915 199.737 12.7259C199.618 13.8275 198.796 14.4855 197.432 14.4702C195.714 14.4702 195.175 13.4909 195.242 12.4505H193.278C193.062 14.4549 194.322 16.0002 197.19 16.0002Z"
                fill="#16C7B0"
            />
            <path
                d="M205.991 4.90724L203.077 15.8472H210.195L210.629 14.2407H205.536L206.403 11.0428H210.269L210.664 9.51275H206.798L207.597 6.51381H212.368L212.802 4.90724H205.991Z"
                fill="#16C7B0"
            />
            <path
                d="M80.3593 4.93215H83.2655L88.5737 18.3106L93.7137 4.93215H96.5959V22.1055H94.5063V7.64627L88.8138 22.1055H88.1653L82.2808 7.64627V22.1055H80.3593V4.93215Z"
                fill="#05090B"
            />
            <path
                d="M103.013 22.3217C102.436 22.3217 101.892 22.2497 101.38 22.1055C100.867 21.9614 100.419 21.7373 100.035 21.433C99.6662 21.1288 99.37 20.7445 99.1458 20.2801C98.9377 19.8158 98.8336 19.2633 98.8336 18.6228C98.8336 17.9023 99.0097 17.2938 99.362 16.7974C99.7143 16.301 100.179 15.9007 100.755 15.5965C101.348 15.2922 102.02 15.0761 102.773 14.948C103.541 14.8038 104.326 14.7318 105.127 14.7318H106.928V13.8191C106.928 12.8103 106.72 12.0897 106.303 11.6574C105.887 11.209 105.199 10.9849 104.238 10.9849C102.508 10.9849 101.548 11.7375 101.356 13.2426H99.362C99.4261 12.5221 99.6022 11.9136 99.8904 11.4172C100.195 10.9208 100.571 10.5205 101.019 10.2163C101.484 9.89602 101.996 9.67185 102.556 9.54375C103.133 9.39963 103.725 9.32758 104.334 9.32758C104.926 9.32758 105.503 9.39963 106.063 9.54375C106.624 9.67185 107.112 9.91203 107.528 10.2643C107.945 10.6006 108.281 11.0569 108.537 11.6334C108.793 12.2098 108.921 12.9384 108.921 13.8191V22.1055H106.928V20.5203C106.464 21.1128 105.927 21.5611 105.319 21.8654C104.71 22.1696 103.942 22.3217 103.013 22.3217ZM103.205 20.7365C103.733 20.7365 104.222 20.6724 104.67 20.5443C105.135 20.4002 105.527 20.2001 105.847 19.9439C106.183 19.6877 106.448 19.3754 106.64 19.0071C106.832 18.6228 106.928 18.1905 106.928 17.7101V16.1489H105.199C104.574 16.1489 103.99 16.1969 103.445 16.293C102.917 16.3731 102.452 16.5092 102.052 16.7013C101.668 16.8935 101.364 17.1497 101.139 17.4699C100.931 17.7742 100.827 18.1585 100.827 18.6228C100.827 19.2793 101.003 19.7997 101.356 20.184C101.708 20.5523 102.324 20.7365 103.205 20.7365Z"
                fill="#05090B"
            />
            <path
                d="M110.998 9.54375H112.992V11.8015C113.184 11.4492 113.4 11.129 113.64 10.8408C113.897 10.5365 114.185 10.2803 114.505 10.0722C114.825 9.84798 115.202 9.67185 115.634 9.54375C116.066 9.41565 116.563 9.34359 117.123 9.32758V11.201C116.499 11.2331 115.93 11.3131 115.418 11.4412C114.905 11.5693 114.465 11.7855 114.097 12.0897C113.744 12.394 113.472 12.8023 113.28 13.3147C113.088 13.8111 112.992 14.4436 112.992 15.2122V22.1055H110.998V9.54375Z"
                fill="#05090B"
            />
            <path
                d="M119.109 3.53906H121.103V15.3323L126.315 9.54375H128.645L123.265 15.3803L129.101 22.1055H126.675L121.103 15.5965V22.1055H119.109V3.53906Z"
                fill="#05090B"
            />
            <path
                d="M135.269 22.3217C134.372 22.3217 133.556 22.1696 132.819 21.8654C132.082 21.5611 131.442 21.1368 130.897 20.5924C130.353 20.0319 129.929 19.3594 129.624 18.5748C129.336 17.7902 129.192 16.9175 129.192 15.9567V15.7646C129.192 14.8199 129.336 13.9552 129.624 13.1706C129.913 12.3699 130.313 11.6894 130.825 11.129C131.354 10.5525 131.978 10.1122 132.699 9.80795C133.419 9.4877 134.212 9.32758 135.077 9.32758C135.797 9.32758 136.494 9.44767 137.166 9.68786C137.855 9.91203 138.463 10.2723 138.992 10.7687C139.52 11.2651 139.937 11.9136 140.241 12.7142C140.561 13.4988 140.721 14.4516 140.721 15.5724V16.221H131.258C131.306 17.6621 131.666 18.7669 132.339 19.5355C133.011 20.2881 133.996 20.6644 135.293 20.6644C137.262 20.6644 138.375 19.9358 138.631 18.4787H140.625C140.401 19.7437 139.808 20.7044 138.848 21.361C137.887 22.0015 136.694 22.3217 135.269 22.3217ZM138.68 14.6117C138.583 13.3147 138.215 12.386 137.575 11.8255C136.95 11.2651 136.118 10.9849 135.077 10.9849C134.052 10.9849 133.211 11.3131 132.555 11.9696C131.914 12.6101 131.506 13.4908 131.33 14.6117H138.68Z"
                fill="#05090B"
            />
            <path
                d="M146.68 22.2737C145.527 22.2737 144.67 21.9694 144.11 21.361C143.565 20.7525 143.293 19.9519 143.293 18.9591V11.2251H141.492V9.54375H143.293V6.68552H145.287V9.54375H148.217V11.2251H145.287V18.7669C145.287 19.9839 145.807 20.5924 146.848 20.5924C147.168 20.5924 147.448 20.5683 147.688 20.5203C147.929 20.4723 148.161 20.4082 148.385 20.3282V22.0095C148.177 22.0895 147.945 22.1536 147.688 22.2016C147.432 22.2497 147.096 22.2737 146.68 22.2737Z"
                fill="#05090B"
            />
            <path
                d="M154.37 22.3217C152.865 22.3217 151.68 21.9694 150.815 21.2649C149.967 20.5443 149.51 19.5355 149.446 18.2385H151.392C151.472 18.9911 151.728 19.5836 152.16 20.0159C152.609 20.4482 153.337 20.6644 154.346 20.6644C155.307 20.6644 156.003 20.5043 156.436 20.184C156.868 19.8478 157.084 19.3434 157.084 18.6709C157.084 18.0143 156.836 17.542 156.34 17.2538C155.859 16.9655 155.131 16.7334 154.154 16.5572C153.337 16.4131 152.649 16.245 152.088 16.0528C151.528 15.8447 151.08 15.5965 150.743 15.3082C150.407 15.02 150.159 14.6757 149.999 14.2754C149.855 13.8751 149.783 13.4028 149.783 12.8583C149.783 12.2819 149.911 11.7775 150.167 11.3451C150.423 10.8968 150.751 10.5285 151.152 10.2403C151.568 9.93605 152.024 9.71188 152.521 9.56776C153.033 9.40764 153.537 9.32758 154.034 9.32758C154.594 9.32758 155.131 9.39163 155.643 9.51973C156.156 9.63181 156.612 9.83197 157.012 10.1202C157.429 10.4084 157.773 10.7847 158.045 11.2491C158.317 11.7134 158.493 12.2819 158.573 12.9544H156.652C156.508 12.2659 156.228 11.7695 155.811 11.4652C155.411 11.145 154.81 10.9849 154.01 10.9849C153.289 10.9849 152.713 11.153 152.281 11.4893C151.848 11.8095 151.632 12.2418 151.632 12.7863C151.632 13.0745 151.672 13.3227 151.752 13.5309C151.848 13.723 152.008 13.8991 152.232 14.0593C152.457 14.2034 152.753 14.3395 153.121 14.4676C153.505 14.5797 153.986 14.6838 154.562 14.7798C155.203 14.9079 155.795 15.052 156.34 15.2122C156.884 15.3563 157.348 15.5644 157.733 15.8367C158.133 16.0929 158.437 16.4371 158.645 16.8695C158.87 17.2858 158.982 17.8302 158.982 18.5027C158.982 19.6556 158.597 20.5844 157.829 21.2889C157.06 21.9774 155.907 22.3217 154.37 22.3217Z"
                fill="#05090B"
            />
            <path
                d="M74.9852 19.8528C74.6189 21.2072 73.1709 22.323 71.7654 22.323H62.3614C60.9559 22.323 60.1041 21.2157 60.4704 19.8528L63.9202 6.98196C64.2865 5.62759 65.7346 4.51172 67.14 4.51172H76.544C77.9495 4.51172 78.8013 5.61907 78.435 6.98196L74.9852 19.8528Z"
                fill="#16C7B0"
            />
            <path d="M0 5.00133H9.99885V6.6997H2.0811V12.7516H8.25264V14.45H2.0811V22.1046H0V5.00133Z" fill="#05090B" />
            <path
                d="M11.8133 9.5941H13.7987V22.1046H11.8133V9.5941ZM12.7701 6.98675C12.4033 6.98675 12.0923 6.85917 11.8372 6.60401C11.582 6.34886 11.4545 6.03789 11.4545 5.67111C11.4545 5.30432 11.582 4.99335 11.8372 4.7382C12.0923 4.48305 12.4033 4.35547 12.7701 4.35547C13.1369 4.35547 13.4479 4.48305 13.703 4.7382C13.9582 4.99335 14.0857 5.30432 14.0857 5.67111C14.0857 6.03789 13.9582 6.34886 13.703 6.60401C13.4479 6.85917 13.1369 6.98675 12.7701 6.98675Z"
                fill="#05090B"
            />
            <path
                d="M16.027 9.5941H18.0124V11.5795C18.2995 10.9895 18.7779 10.4792 19.4477 10.0486C20.1334 9.60207 20.9946 9.37881 22.0311 9.37881C22.669 9.37881 23.259 9.4745 23.8012 9.66586C24.3435 9.84128 24.8059 10.1363 25.1886 10.5509C25.5873 10.9496 25.8903 11.4679 26.0976 12.1058C26.3209 12.7437 26.4325 13.5171 26.4325 14.4261V22.1046H24.4471V14.2826C24.4471 13.1822 24.2079 12.3769 23.7295 11.8666C23.2511 11.3563 22.5095 11.1011 21.5049 11.1011C21.0424 11.1011 20.5959 11.1729 20.1653 11.3164C19.7507 11.4599 19.3839 11.6752 19.065 11.9622C18.746 12.2493 18.4909 12.6081 18.2995 13.0387C18.1081 13.4693 18.0124 13.9636 18.0124 14.5218V22.1046H16.027V9.5941Z"
                fill="#05090B"
            />
            <path
                d="M34.55 22.3199C33.657 22.3199 32.8437 22.1684 32.1101 21.8654C31.3766 21.5624 30.7387 21.1398 30.1965 20.5976C29.6543 20.0395 29.2317 19.3697 28.9287 18.5883C28.6416 17.8069 28.4981 16.9378 28.4981 15.9809V15.7896C28.4981 14.8487 28.6416 13.9875 28.9287 13.2061C29.2157 12.4088 29.6144 11.731 30.1247 11.1729C30.651 10.5988 31.2729 10.1602 31.9905 9.85723C32.7082 9.53829 33.4975 9.37881 34.3587 9.37881C35.0763 9.37881 35.77 9.49842 36.4398 9.73763C37.1255 9.96089 37.7315 10.3197 38.2578 10.8141C38.784 11.3084 39.1986 11.9543 39.5016 12.7516C39.8206 13.533 39.98 14.4819 39.98 15.5982V16.2441H30.5553C30.6031 17.6793 30.9619 18.7796 31.6317 19.5451C32.3015 20.2946 33.2822 20.6694 34.574 20.6694C36.5355 20.6694 37.6438 19.9438 37.8989 18.4926H39.8844C39.6611 19.7524 39.0711 20.7092 38.1142 21.3631C37.1574 22.001 35.9693 22.3199 34.55 22.3199ZM37.9468 14.6414C37.8511 13.3496 37.4843 12.4247 36.8464 11.8666C36.2245 11.3084 35.3952 11.0293 34.3587 11.0293C33.3381 11.0293 32.5008 11.3563 31.847 12.0101C31.2091 12.648 30.8025 13.5251 30.6271 14.6414H37.9468Z"
                fill="#05090B"
            />
            <path
                d="M42.1109 9.5941H44.0963V11.8426C44.2876 11.4918 44.5029 11.1729 44.7421 10.8858C44.9973 10.5828 45.2843 10.3277 45.6033 10.1204C45.9222 9.8971 46.297 9.72168 46.7276 9.5941C47.1581 9.46652 47.6525 9.39476 48.2106 9.37881V11.2446C47.5887 11.2765 47.0226 11.3563 46.5123 11.4838C46.002 11.6114 45.5634 11.8267 45.1966 12.1297C44.8458 12.4327 44.5747 12.8393 44.3833 13.3496C44.192 13.844 44.0963 14.4739 44.0963 15.2394V22.1046H42.1109V9.5941Z"
                fill="#05090B"
            />
            <path
                d="M53.798 20.8129L48.8942 9.5941H51.0232L54.8505 18.5644L58.319 9.5941H60.3523L53.4392 26.4582H51.4298L53.798 20.8129Z"
                fill="#05090B"
            />
        </svg>
    );
};

export const IconTriangle = () => {
    const {
        colors: { textFG },
    } = useTheme();
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M4 6L0.535897 0.750002L7.4641 0.750001L4 6Z" fill={textFG} />
        </svg>
    );
};

export const IconUpDown = () => {
    const {
        colors: { textFG },
    } = useTheme();
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M12 18L8.5359 12.75L15.4641 12.75L12 18Z" fill={textFG} />
            <path opacity="0.2" d="M12 4L15.4641 9.25H8.5359L12 4Z" fill={textFG} />
        </svg>
    );
};

export const IconDropdown = () => {
    return (
        <svg width="8" height="14" viewBox="0 2 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99999 14L0.535889 8.75L7.46409 8.75L3.99999 14Z" fill="#CCCCCC" />
        </svg>
    );
};

export const IconError = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#FE1833" />
            <path
                d="M11.9905 14.7754C12.6838 14.7754 13.0647 14.3457 13.0842 13.6328L13.2112 6.41602C13.2112 6.34766 13.2209 6.2793 13.2209 6.23047C13.2209 5.46875 12.7327 5 12.0002 5C11.2678 5 10.7698 5.46875 10.7698 6.23047C10.7698 6.2793 10.7795 6.34766 10.7795 6.41602L10.9065 13.6328C10.926 14.3457 11.2971 14.7754 11.9905 14.7754ZM12.0002 18.7637C12.801 18.7637 13.426 18.1484 13.426 17.377C13.426 16.6055 12.801 16 12.0002 16C11.2092 16 10.5842 16.6055 10.5842 17.377C10.5842 18.1484 11.2092 18.7637 12.0002 18.7637Z"
                fill="white"
            />
        </svg>
    );
};

export const HelpIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2165 5.34053C11.2165 3.84053 9.76455 3.14453 8.30055 3.14453C6.44055 3.14453 5.06055 4.26053 5.06055 6.02453H6.69255C6.71655 4.88453 7.40055 4.40453 8.27655 4.40453C8.92455 4.40453 9.54855 4.78853 9.54855 5.42453C9.54855 6.04853 9.02055 6.44453 8.27655 6.88853C7.43655 7.38053 6.99255 7.90853 6.99255 9.09653V9.50453H8.61255V9.13253C8.61255 8.25653 8.96055 7.98053 9.74055 7.54853C10.4245 7.15253 11.2165 6.44453 11.2165 5.34053ZM6.90855 12.0005H8.68455V10.2005H6.90855V12.0005Z"
                fill="#243034"
                fillOpacity="0.32"
            />
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#243034" strokeOpacity="0.2" />
        </svg>
    );
};

export const TickIcon = () => {
    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.70711 0.302437C10.0976 0.705687 10.0976 1.35948 9.70711 1.76273L3.66667 8L0.292893 4.51629C-0.0976311 4.11304 -0.0976311 3.45925 0.292893 3.056C0.683417 2.65275 1.31658 2.65275 1.70711 3.056L3.66667 5.07941L8.29289 0.302437C8.68342 -0.100812 9.31658 -0.100812 9.70711 0.302437Z"
                fill="#CCCCCC"
            />
        </svg>
    );
};
export const IndeterminateIcon = () => {
    return (
        <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="2" fill="#CCCCCC" />
        </svg>
    );
};

export const CrossIcon = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00011 5.80029L5.20004 4.00022L7.00011 2.20016C7.36012 1.84014 7.36012 1.36012 7.00011 1.00011C6.6401 0.640098 6.16008 0.640098 5.80007 1.00011L4 2.80018L2.19993 1.00011C1.83992 0.640098 1.3599 0.640098 0.999889 1.00011C0.639876 1.36012 0.639876 1.84014 0.999889 2.20016L2.79996 4.00022L0.999889 5.80029C0.639876 6.1603 0.639876 6.64032 0.999889 7.00033C1.3599 7.36035 1.83992 7.36035 2.19993 7.00033L4 5.20027L5.80007 7.00033C6.16008 7.36035 6.6401 7.36035 7.00011 7.00033C7.36012 6.64032 7.36012 6.1603 7.00011 5.80029Z"
                fill="white"
            />
        </svg>
    );
};

export const UpTrendIcon = () => {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.94203 0.800001L8 6L0 6L3.94203 0.800001Z" fill="#67D035" />
        </svg>
    );
};

export const DownTrendIcon = () => {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.05797 5.7L0 0.5H8L4.05797 5.7Z" fill="#F63C30" />
        </svg>
    );
};

export const CircleArrows = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6.075C2 6.375 2.075 6.675 2.15 6.9C2.225 7.275 2.075 7.725 1.7 7.875C1.25 7.95 0.875 7.725 0.725 7.35C0.575 6.975 0.5 6.6 0.5 6.15C0.5 5.325 0.725 4.5 1.1 3.825C1.475 3.15 2 2.55 2.75 2.1C3.425 1.725 4.175 1.5 5 1.5V0L8 2.25L5 4.5V3C4.475 3 3.95 3.15 3.5 3.45C3.05 3.675 2.6 4.05 2.375 4.575C2.15 5.025 2 5.55 2 6.075ZM8.3 4.12472C8.75 4.04972 9.125 4.27472 9.275 4.64972C9.425 5.02472 9.5 5.47472 9.5 5.84972C9.5 6.67472 9.275 7.42472 8.9 8.17472C8.525 8.92472 7.925 9.52472 7.25 9.89972C6.575 10.2747 5.825 10.4997 5 10.4997V11.9997L2 9.74972L5 7.49972V8.99972C5.525 8.99972 6.05 8.84972 6.5 8.54972C6.95 8.24972 7.4 7.87472 7.625 7.42472C7.85 6.97472 8 6.44972 8 5.92472C8 5.67619 7.94853 5.47914 7.88823 5.24827C7.87574 5.20046 7.86287 5.15119 7.85 5.09972C7.775 4.72472 7.925 4.27472 8.3 4.12472Z"
                fill="white"
            />
        </svg>
    );
};

export const ArrowUp = () => {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.75643 4.9L4.97387 0L0.286954 4.9C-0.0956513 5.3 -0.0956513 5.9 0.286954 6.3C0.669559 6.7 1.24347 6.7 1.62607 6.3L4.01735 3.8V11C4.01735 11.6 4.39996 12 4.97387 12C5.54778 12 5.93038 11.6 5.93038 11V3.8L8.32166 6.3C8.70427 6.7 9.27818 6.7 9.66078 6.3C10.0434 5.9 10.139 5.3 9.75643 4.9Z"
                fill="#243034"
                fillOpacity="0.32"
            />
        </svg>
    );
};

export const ArrowDown = () => {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.243566 7.1L5.02613 12L9.71305 7.1C10.0957 6.7 10.0957 6.1 9.71305 5.7C9.33044 5.3 8.75653 5.3 8.37393 5.7L5.98264 8.2L5.98265 1C5.98265 0.4 5.60004 5.69018e-07 5.02613 5.18845e-07C4.45222 4.68672e-07 4.06962 0.4 4.06962 1L4.06962 8.2L1.67834 5.7C1.29573 5.3 0.721822 5.3 0.339217 5.7C-0.0433888 6.1 -0.13904 6.7 0.243566 7.1Z"
                fill="#243034"
                fillOpacity="0.32"
            />
        </svg>
    );
};

export const EyeIcon = ({ checked = false }) => (
    <svg fill="none" height="10" viewBox="0 0 14 10" width="14" xmlns="http://www.w3.org/2000/svg">
        <g fill={checked ? "#16C7B0" : "#243034"} fillOpacity={checked ? "1" : ".12"}>
            <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="m.377508 4.33147c-.169834.25289-.170099.58378-.000264.83667l.622624-.41814c-.622624.41814-.622033.41902-.622033.41902l.00084.00124.002423.00359.007805.01144.027284.03939c.023311.03334.056823.08057.10003.1397.08636.11818.211783.28435.372261.48256.320032.39527.784222.92384 1.360662 1.45474 1.12608 1.03711 2.80496 2.19832 4.75073 2.19832 1.94576 0 3.62463-1.16121 4.75073-2.19832.5764-.5309 1.0406-1.05947 1.3607-1.45474.1604-.19821.2859-.36438.3722-.48256.0432-.05913.0767-.10636.1-.1397l.0273-.03939.0078-.01144.0025-.00359.0014-.00212c.1698-.25289.1698-.58339 0-.83628l-.6226.41814c.6226-.41814.622-.41902.622-.41902l-.0008-.00124-.0025-.00359-.0078-.01144-.0273-.03939c-.0233-.03334-.0568-.08057-.1-.1397-.0863-.11818-.2118-.28435-.3722-.48256-.3201-.39527-.7843-.92384-1.3607-1.45474-1.1261-1.03711-2.80497-2.19832-4.75073-2.19832-1.94577 0-3.62465 1.16121-4.75073 2.19832-.57644.5309-1.04063 1.05947-1.360662 1.45474-.160478.19821-.285901.36438-.372261.48256-.043207.05913-.076719.10636-.10003.1397l-.027284.03939-.007805.01144-.002423.00359zm11.567992.57159c.0437-.05401.0844-.10517.1218-.15306-.0374-.04789-.0781-.09905-.1218-.15306-.2872-.35473-.7017-.82616-1.2111-1.29526-1.04547-.96289-2.36659-1.80168-3.73453-1.80168s-2.68907.83879-3.73455 1.80168c-.50935.4691-.92384.94053-1.21104 1.29526-.04373.05401-.08439.10517-.12188.15306.03749.04788.07815.09905.12188.15306.2872.35473.70169.82616 1.21104 1.29526 1.04548.96289 2.36661 1.80168 3.73455 1.80168s2.68906-.83879 3.73453-1.80168c.5094-.4691.9239-.94053 1.2111-1.29526zm-10.945632-.15306c-.622624-.41814-.62236-.41853-.62236-.41853z"
            />
            <path d="m7 7.75c1.65685 0 3-1.34315 3-3s-1.34315-3-3-3c-.30747 0-.60413.04625-.88341.13218.5209.23518.88341.75917.88341 1.36782 0 .82843-.67157 1.5-1.5 1.5-.60865 0-1.13264-.36251-1.36782-.88341-.08593.27928-.13218.57594-.13218.88341 0 1.65685 1.34315 3 3 3z" />
        </g>
    </svg>
);
