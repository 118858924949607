import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type QueryParams = [instrument: string, amount: number, volume: number, period: string] | []

export const useQueryParams = () => {
    const {search} = useLocation();
    const [value, setValue] = useState<QueryParams>(
        getQueryParams(search)
    );
    const history = useHistory();

    useEffect(() => {
        const [selectedInstrument, selectedAmount, selectedVolume, selectedPeriod] = value;

        if (!selectedInstrument || !selectedAmount || !selectedVolume || !selectedPeriod) {
            return
        }

        const q = {i: selectedInstrument, a: selectedAmount, v: selectedVolume, p: selectedPeriod};

        history.push({
            pathname: '/spreads',
            search: "?" + new URLSearchParams(q as any).toString()
        })
    }, [value, setValue]);
    return [value, setValue] as const;
};

const getQueryParams = (search: string): QueryParams => {
    if (search) {
        const queryParams = new URLSearchParams(search.substring(1));
        const {instrument, amount, volume, period} = {
            instrument: queryParams.get("i"),
            amount: queryParams.get("a"),
            volume: queryParams.get("v"),
            period: queryParams.get("p")
        };

        if (instrument && amount && volume && period) {
            return [instrument, +amount, +volume, period]
        } else {
            return []
        }
    } else {
        return []
    }
}