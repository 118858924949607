export * from "./bitmask";
export * from "./const";
export * from "./cssUtils";
export * from "./csv";
export * from "./env";
export * from "./file";
export * from "./formatters";
export * from "./formHelpersContext";
export * from "./ga4";
export * from "./hooks";
export * from "./serverName";
export * from "./types";
export * from "./urlHelpers";

export const noop = () => {};
export function when<T>(value: T, jsx: React.ReactNode, jsxFallback: React.ReactNode | null = null) {
    return value ? jsx : jsxFallback;
}
export const isNullOrUndefined = (value: any) => {
    return value === null || value === undefined;
};

export const trimAddress = (address: string | undefined, beginLength: number = 10, endLength: number = 4) => {
    if (!address) {
        return "";
    }

    if (address.length < beginLength + endLength + 1) {
        return address;
    }

    return `${address.slice(0, beginLength)}...${address.slice(-endLength)}`;
};

export function typedObjectEntries<T extends {}>(obj: T): [key: keyof T, value: T[keyof T]][] {
    return Object.entries(obj) as [key: keyof T, value: T[keyof T]][];
}
