import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Mark } from "../common";
import { P as Title } from "../typography";
import { Size } from "./types";
import { useTabContext } from "./utils";

const activeTabCss = css`
    background-color: ${(p) => p.theme.colors.uiWhite100};
    color: ${(p) => p.theme.colors.brand100};
`;

const Container = styled.div<{ size: Size; disabled: boolean; isActive?: boolean }>`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    color: ${(p) => p.theme.colors.ui72};

    ${(p) => p.isActive && activeTabCss}

    ${Title} {
        margin: 0;
    }

    ${(p) =>
        p.size === "small" &&
        css`
            padding: 4px 8px;

            ${Title} {
                font-size: 12px;
                line-height: 16px;
            }
        `}

    ${(p) =>
        p.size === "small" &&
        p.isActive &&
        css`
            box-shadow: 0px 1px 2px 0px rgba(36, 48, 52, 0.04),
                0px 0px 0px 1px rgba(36, 48, 52, 0.12) inset,
                0px -1px 0px 0px rgba(36, 48, 52, 0.12) inset;

            ${Title}, ${Mark} {
                font-weight: 600;
            }
        `}

    ${(p) =>
        p.size === "medium" &&
        css`
            padding: 8px;
        `}

    ${(p) =>
        p.size === "large" &&
        css`
            padding: 8px;
        `}

    ${(p) =>
        p.disabled &&
        css`
            ${Title} {
                color: ${p.theme.colors.ui52};
            }
            cursor: not-allowed;
        `}
`;

type TabProps = {
    title: ReactNode;
    value: string;
    size?: Size;
    disabled?: boolean;
};

export const Tab: React.VFC<TabProps> = (props) => {
    const { title, value, size = "medium", disabled = false } = props;
    const context = useTabContext();

    return (
        <Container
            disabled={disabled}
            size={size}
            isActive={context?.value === value}
            onClick={() => {
                if (disabled) {
                    return;
                }

                context?.handleClick?.(value);
            }}
        >
            <Title>{title}</Title>
        </Container>
    );
};
