import { selectors } from "@fm-frontend/uikit";
import styled from "styled-components";
import { onTablet } from "~const";

export const FiltersWrapper = styled.div`
    position: sticky;
    top: 12px;
    min-width: 296px;
    width: 296px;
    margin-right: 8px;
    height: fit-content;
    background: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0 4px 6px #0000000a;
    border-radius: 12px;
    padding: 12px;

    ${onTablet} {
        margin: 0 0 20px;
        width: 100%;
        max-width: 100%;
        position: static;
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
    }
`;

export const FilterTitle = styled.div`
    ${selectors.mixinHeader2}

    color: #000;
    margin-bottom: 10px;
    display: flex;

    ${onTablet} {
        display: none;
    }
`;

export const FilterSelect = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

export const FilterLabel = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #999;
    margin-bottom: 8px;
    display: inline-block;

    ${onTablet} {
        display: none;
    }
`;

export const Title = styled.div`
    padding-right: 12px;
    min-width: fit-content;

    h2 {
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        margin-bottom: 4px;
        color: #243034;
        letter-spacing: -0.005em;
        font-weight: 600;
    }

    p {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        letter-spacing: -0.005em;
        color: rgba(36, 48, 52, 0.72);
    }
`;

export const Periods = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    flex: 1;

    overflow-x: auto;

    @media (max-width: 870px) {
        flex-wrap: wrap;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 1090px) {
        flex-direction: column;

        ${Periods} {
            margin-top: 12px;
            max-width: none;
        }
    }
`;
