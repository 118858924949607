import { FC } from "react";
import styled, { css } from "styled-components";
import { PlainButton } from "../buttons";
import { Icons } from "../icons";
import { PSmall } from "../typography";
import { Distance } from "./Distance";
import { getTimeLabel } from "./utils";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const Description = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
    white-space: nowrap;
`;

const Button = styled(PlainButton).attrs({ size: "small" })<{ $rotate: boolean }>`
    padding: 4px;

    ${(p) =>
        p.$rotate &&
        css`
            svg {
                animation: spin2 1s infinite linear;
            }
        `}
`;

type RefreshButtonProps = {
    refreshDate?: Date;
    onRefresh?: () => void;
    refreshing?: boolean;
};

export const RefreshButton: FC<RefreshButtonProps> = ({ refreshDate, onRefresh, refreshing = false }) => {
    if (refreshDate === undefined) {
        return null;
    }

    return (
        <Container>
            <Description>
                Updated <Distance date={refreshDate} />, {getTimeLabel(refreshDate)}
            </Description>
            <Button disabled={refreshing} $rotate={refreshing} onClick={onRefresh}>
                <Icons.Reload />
            </Button>
        </Container>
    );
};
