import { format } from "date-fns";
import { forwardRef } from "react";
import styled, { css } from "styled-components";
import { Icons } from "../../icons";

const Container = styled.div<{ $size?: string; fullWidth?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    padding: 2px 4px 2px 8px;
    background: ${(p) => p.theme.colors.ui8};
    width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};

    ${(p) => {
        switch (p.$size) {
            case "large":
                return css`
                    height: 56px;
                `;
            case "medium":
                return css`
                    height: 44px;
                `;
            case "small":
            default:
                return css`
                    height: 24px;
                `;
        }
    }};
`;

const Label = styled.span<{ $size?: string }>`
    color: ${(p) => p.theme.colors.ui52};

    ${(p) => {
        switch (p.$size) {
            case "large":
            case "medium":
                return p.theme.mixins.p;
            case "small":
            default:
                return p.theme.mixins.pSmall;
        }
    }};
`;

const Essence = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    white-space: nowrap;
`;

const Value = styled.span<{ $size?: string }>`
    ${(p) => {
        switch (p.$size) {
            case "large":
            case "medium":
                return p.theme.mixins.p;
            case "small":
            default:
                return p.theme.mixins.pSmall;
        }
    }};
`;

const getValue = (startDate: Date | null | undefined, endDate: Date | null | undefined) => {
    const from = startDate ? format(startDate, "dd MMM yyyy") : null;
    const to = endDate ? format(endDate, "dd MMM yyyy") : null;

    if (from !== null && to === null) {
        return from;
    }

    if (from !== null && from === to) {
        return from;
    }

    if (from !== null && to !== null) {
        return `${from} – ${to}`;
    }

    return "";
};

type DatePickerInputProps = {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    onClick: () => void;
    fullWidth?: boolean;
    simple?: boolean;
    size?: "small" | "medium" | "large";
    className?: string;
};

export const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
    ({ startDate, endDate, onClick, fullWidth, className, size, simple }, ref) => (
        <Container ref={ref} className={className} fullWidth={fullWidth} onClick={onClick} $size={size}>
            {!simple && <Label $size={size}>Dates</Label>}
            <Essence>
                <Value $size={size}>{getValue(startDate, endDate)}</Value>
                <Icons.Calendar />
            </Essence>
        </Container>
    ),
);
