import { FC } from "react";
import { Icons } from "../../icons";
import { HStack } from "../../layout";
import { H2 } from "../../typography";

type LightboxHeaderProps = {
    fileName: string;
    className?: string;
};

export const LightboxHeader: FC<LightboxHeaderProps> = ({ fileName, className }) => {
    return (
        <HStack className={className} spacing={4} alignItems="center" padding={12}>
            <Icons.File />
            <H2>{fileName}</H2>
        </HStack>
    );
};
