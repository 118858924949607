import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./appSlice";
import socketMiddleware from "./socketMiddleware";
import socketSlice from "./socketSlice";
import turnoverSlice from "./turnoverSlice";
import analyticsSocketMiddleware from "./analyticsSocketMiddleware";

export const store = configureStore({
    reducer: {
        app: appSlice,
        socket: socketSlice,
        turnover: turnoverSlice
    },
    middleware: [socketMiddleware, analyticsSocketMiddleware]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector