import { FC, SyntheticEvent, useMemo, useRef } from "react";
import ReactModal from "react-modal";
import { useTheme } from "styled-components";
import { ContentWithActions } from "./ContentWithActions";
import { ANIMATION_TIMEOUT } from "./styled";
import type { ModalActions } from "./types";

const safeDocument: Document = document;

export const useScrollBlock = () => {
    const scrollBlocked = useRef(false);
    const html = safeDocument.documentElement;
    const { body } = safeDocument;

    const blockScroll = (): void => {
        if (!body || !body.style || scrollBlocked.current) {
            return;
        }
        if (document == undefined) {
            return;
        }

        const scrollBarWidth = window.innerWidth - html.clientWidth;
        const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

        /**
         * 1. Fixes a bug in iOS and desktop Safari whereby setting
         *    `overflow: hidden` on the html/body does not prevent scrolling.
         * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
         *    scroll if an `overflow-x` style is also applied to the body.
         */
        html.style.position = "relative"; /* [1] */
        html.style.overflow = "hidden"; /* [2] */
        body.style.position = "relative"; /* [1] */
        body.style.overflow = "hidden"; /* [2] */
        body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

        scrollBlocked.current = true;
    };

    const allowScroll = (): void => {
        if (!body || !body.style || !scrollBlocked.current) {
            return;
        }

        html.style.position = "";
        html.style.overflow = "";
        body.style.position = "";
        body.style.overflow = "";
        body.style.paddingRight = "";

        scrollBlocked.current = false;
    };

    return { blockScroll, allowScroll };
};

const useModalStyles = (style?: ReactModal.Styles) => {
    const theme = useTheme();
    const defaultStyles = {
        overlay: {
            background: theme.colors.ui52,
            backdropFilter: "blur(6px)",
            WebkitBackdropFilter: "blur(6px)",
            zIndex: 1,
        },
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
            border: "none",
            backgroundColor: theme.colors.uiWhite100,
            boxShadow: `inset 0px -1px 0px ${theme.colors.ui8}`,
            borderRadius: "12px",
            overflow: "initial",
        },
    };

    return useMemo(() => {
        if (!style) return defaultStyles;

        return {
            overlay: { ...defaultStyles.overlay, ...(style.overlay ?? {}) },
            content: { ...defaultStyles.content, ...(style.content ?? {}) },
        };
    }, [style]);
};

export type ModalProps = Omit<ReactModal.Props, "onRequestClose"> & {
    additionalActions?: ModalActions;
    onClose?: () => void;
};

// hide app content for screen readers when a modal is opened
ReactModal.setAppElement("#root, #storybook-docs");

export const MODAL_TOP_OFFSET = 64;
export const MODAL_BOTTOM_OFFSET = 64;

export const Modal: FC<ModalProps> = ({ children, onClose, additionalActions, isOpen, style, ...restPopoverProps }) => {
    const styles = useModalStyles(style);
    const { allowScroll, blockScroll } = useScrollBlock();
    const contentWithActions = (
        <ContentWithActions additionalActions={additionalActions} onClose={onClose}>
            {children}
        </ContentWithActions>
    );
    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation();
        onClose?.();
    };

    return (
        <ReactModal
            closeTimeoutMS={ANIMATION_TIMEOUT}
            style={styles}
            isOpen={isOpen}
            onAfterClose={allowScroll}
            onAfterOpen={blockScroll}
            onRequestClose={handleClose}
            {...restPopoverProps}
        >
            {contentWithActions}
        </ReactModal>
    );
};
