import { selectors } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { TableEyeContainer } from "~components/table/TableEye";
import { Hint } from "~style";

export const TableSkeletonWrapper = styled.div`
    padding: 0 0.5rem;
`;

export const FlexTableWrapper = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    padding-right: 0;
`;

export const FlexTable = styled.div`
    display: table;
    width: 100%;
    min-width: 650px;
`;

export const FlexRow = styled.div<{ inactive?: boolean; positive?: boolean; header?: boolean }>`
    display: table-row;

    ${({ header }) =>
        !header &&
        css`
            cursor: pointer;

            :hover {
                background: #f7f7f7;
            }
        `}

    ${({ inactive }) =>
        inactive &&
        css`
            color: #999999;
        `}

  ${({ positive }) =>
        positive &&
        css`
            -webkit-animation-name: backgroundFadeOut;
            -webkit-animation-duration: 4s;
            animation-name: backgroundFadeOut;
            animation-duration: 4s;
        `}
`;

export const TableCellWrapper = styled.div<{
    $absolute?: boolean;
    $justify?: "left" | "center" | "right";
}>`
    display: flex;
    align-items: center;
    justify-content: ${(p) => p.$justify ?? "center"};

    ${(p) =>
        p.$absolute &&
        css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        `}
`;

export const FlexCell = styled.div<{
    header?: boolean;
    positive?: boolean;
}>`
    display: table-cell;
    border-bottom: 1px solid #f0eeee;
    padding: 12px 14px 12px 0;
    text-align: right;
    letter-spacing: -0.005em;
    font-size: 14px;
    line-height: 16px;
    vertical-align: middle;
    position: relative;

    ${({ positive }) =>
        positive &&
        css`
            color: #67d035;
        `}

    &:first-child {
        text-align: center;
        padding-right: 0;

        width: 44px;
        min-width: 44px;
        max-width: 44px;
    }

    &:nth-child(2) {
        ${TableCellWrapper} {
            justify-content: left;
        }

        padding-right: 0;
    }

    &:last-child {
        ${selectors.fontMedium}
    }

    ${({ header }) =>
        header &&
        css`
            text-align: right;
            border-bottom: 1px solid ${(p) => p.theme.colors.ui20};

            ${selectors.mixinInterface2}

            color: #999;

            &:first-child {
                text-align: center;
                padding-right: 0;
                width: 44px;
                min-width: 44px;
                max-width: 44px;
            }

            &:nth-child(2) {
                text-align: left;
                padding-right: 0;
            }

            &:nth-child(4) {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }

            &:nth-child(5) {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }

            &:nth-child(6) {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }

            &:last-child {
                padding-right: 8px;
                width: 105px;
                min-width: 105px;
                max-width: 105px;
            }
        `}
`;

export const Checkmark = styled.span<{ background?: string }>`
    width: 8px;
    height: 8px;
    border-radius: 1px;
    opacity: 0;
    background: ${({ background }) => (background ? background : "gray")};
`;

export const CheckboxLabel = styled.label`
    cursor: pointer;
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    justify-content: center;
    align-items: center;

    :hover {
        border: 1px solid rgba(36, 48, 52, 0.52);
    }

    input {
        &:checked {
            & ~ ${Checkmark} {
                opacity: 1;
            }
        }
    }
`;

export const TableItemIcon = styled.img<{ opacity: number }>`
    width: 20px;
    height: 20px;
    margin: 0 0 0 8px;
    opacity: ${({ opacity }) => opacity};
`;

export const ItemCount = styled.span`
    color: #999;
    padding-left: 5px;
`;

export const SpreadWrapper = styled.div`
    display: flex;
    justify-content: end;
    cursor: help;

    :hover {
        ${Hint} {
            opacity: 1;
            z-index: 5;
        }
    }
`;

export const HintSign = styled.span`
    color: ${selectors.brandColor};
`;

export const TurnoverRow = styled(FlexRow)<{ header?: boolean }>`
    position: relative;
    // HACK: fix position absolute in Safari
    clip-path: inset(0);

    ${(p) =>
        !p.header &&
        css`
            height: 48px;

            ${TableEyeContainer} {
                display: flex;
                width: 24px;
                height: 24px;
                align-items: center;
                justify-content: center;
                transition: none;
            }

            :hover {
                background: inherit;

                ${TableEyeContainer} {
                    background: ${(p) => p.theme.colors.ui8};
                    border-radius: 12px;
                }
            }
        `}}

    :first-child {
        background-color: rgba(255, 255, 255, 1);
        position: sticky;
        top: -1px;
        z-index: 1;
    }
`;

export const TurnoverCell = styled(FlexCell)<{
    textColor?: string | null;
    inactive?: boolean;
    position?: "relative" | "static" | "absolute";
    header?: boolean;
}>`
    white-space: nowrap;
    color: ${({ textColor, theme, header }) => {
        if (textColor) {
            return textColor;
        }
        if (header) {
            return theme.colors.ui72;
        }
        return theme.colors.ui100;
    }};

    ${({ position }) =>
        position &&
        css`
            position: ${position};
        `}
    ${({ inactive }) =>
        inactive &&
        css`
            color: #999999;
        `}

    &:nth-child(1) {
        text-align: left;
        width: auto;

        ${TableCellWrapper} {
            justify-content: left;
            padding-left: 12px;
            ${selectors.fontMedium};
        }
    }

    &:nth-child(2) {
        padding-right: 18px;
        text-align: right;
        width: 82px;

        ${TableCellWrapper} {
            ${selectors.fontRegular}

            justify-content: right;
        }
    }

    &:nth-child(3) {
        padding-right: 12px;
        width: 100px;
    }

    &:nth-child(4) {
        text-align: center;
        padding-right: 0;
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    &:last-child {
        text-align: center;
        padding-right: 0;
        width: 46px;
        min-width: 46px;
        max-width: 46px;
    }

    ${({ header }) =>
        header &&
        css`
            ${selectors.pSmall};

            padding: 0 14px 8px 0;

            :nth-child(1) {
                padding-left: 12px;
            }

            :nth-child(3) {
                padding-right: 25px;
            }
        `}
`;

export const TurnoverTable = styled(FlexTable)`
    min-width: initial;
`;

export const TurnoverTableName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TurnoverTableClientId = styled.div`
    padding: 0 8px;
    z-index: 10;
`;

export const AmountCellWrapper = styled.div<{ $withIcon?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        padding-right: ${(p) => (p.$withIcon ? "5px" : "13px")};
    }
`;

export const ProgressLine = styled.div<{ percent: number; color: "gray" | "green" }>`
    position: absolute;
    background-color: ${({ color }) =>
        color === "gray"
            ? "rgba(36, 48, 52, 0.32)"
            : color === "green"
            ? "rgba(22, 199, 176, 1)"
            : null};
    width: ${({ percent }) => (percent > 100 ? "100%" : `${percent}%`)};
    height: 4px;
    border-radius: 2px;
`;

export const Progress = styled.div<{ directionReverse?: boolean }>`
    display: flex;

    ${({ directionReverse }) =>
        directionReverse &&
        css`
            flex-direction: row-reverse;
        `}

    position: relative;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(36, 48, 52, 0.08);
    width: 100%;
`;

export const ProgressContainer = styled.div`
    width: 48px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    align-items: center;
`;

export const ProgressValue = styled.div<{ pale: boolean }>`
    color: ${(p) => (p.pale ? p.theme.colors.ui52 : p.theme.colors.ui96)};
    font-size: 10px;
`;
