import React, { useEffect, useRef } from "react";
import { TableToggleRowsSelectedProps } from "react-table";
import { IndeterminateIcon, TickIcon } from "~components/icons";
import { CheckboxLabel, Checkmark } from "./style";

interface TableCheckboxProps extends TableToggleRowsSelectedProps {
    indeterminate?: boolean;
    background?: string;
    header?: boolean;
}

export const TableCheckbox: React.VFC<TableCheckboxProps> = ({ indeterminate, background, header, ...rest }) => {
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = Boolean(indeterminate);
        }
    }, [checkboxRef, indeterminate]);

    return header ? (
        <CheckboxLabel>
            <input type="checkbox" ref={checkboxRef} {...rest} />
            {checkboxRef.current?.indeterminate && <IndeterminateIcon />}
            {checkboxRef.current?.checked && <TickIcon />}
        </CheckboxLabel>
    ) : (
        <CheckboxLabel>
            <input type="checkbox" ref={checkboxRef} {...rest} />
            <Checkmark background={background} />
        </CheckboxLabel>
    );
};
