import React, { useState } from "react";
import { Button, Error, FormError, FormRow, HeadTitle } from "./style";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { ValidationRules } from "./utils";
import { Loading } from "../../components/Loading";
import { forceRestartConnecting } from "../../app/store/socketSlice";
import { useAppDispatch } from "../../app/store";

export const ConfirmMfaCode: React.FC<{
    userState: any
}> = ({
          userState
      }) => {
    const {
        register,
        handleSubmit,
        formState: {isValid, errors},
    } = useForm<any>({
        mode: "onChange"
    });

    const history = useHistory();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onSubmit = (data: any) => {
        if(!isValid) {
            return;
        }

        setLoading(true);

        Auth.confirmSignIn(userState, data.code, 'SOFTWARE_TOKEN_MFA')
            .then(() => {
                setError(undefined);
                setLoading(false);
                dispatch(forceRestartConnecting());
                history.push('/spreads');
            })
            .catch((error) => {
                setLoading(false)
                setError(error?.message);
            })
    }

    return (
        <>
            <HeadTitle>Confirm 2FA Code</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    <label>Code</label>
                    <input {...register('code',
                        {
                            ...ValidationRules.code
                        })}
                           placeholder='000000'/>
                    {
                        errors.code && (
                            <Error>{errors.code.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    {
                        error && (
                            <FormError>{error}</FormError>
                        )
                    }
                    <Loading loading={loading}>
                        <Button type={'submit'}>Confirm</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    )
}
