import { ClientId, PBold } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { toPercent } from "~components/barChart/utils";
import { ChartData } from "~components/linerChart/LinerChart";
import { fmt } from "~utils/format";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 360px;
    padding: 12px 0;
`;
const Header = styled.div`
    display: flex;
    gap: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 12px;
`;
const Title = styled.div``;
const Sum = styled.div`
    color: ${(p) => p.theme.colors.brand100};
`;
const Period = styled.div`
    font-size: 12px;
    line-height: 16px;
    opacity: 0.72;
    margin: 2px 0 16px;
    padding: 0 12px;
`;
const Content = styled.div`
    max-height: 394px;
    overflow: auto;
    position: relative;
`;
const Table = styled.table`
    border-collapse: collapse;
    width: 100%;

    td:first-child {
        padding: 0 4px 0 12px;
    }
    td:last-child {
        padding: 0 12px 0 4px;
    }
`;
const TableHead = styled.thead`
    position: sticky;
    top: 0;
    background: white;
    color: rgba(0, 0, 0, 0.72);
    font-size: 12px;
    line-height: 16px;
`;
const TableBody = styled.tbody`
    font-size: 14px;
    line-height: 18px;

    tr {
        border-top: 1px solid rgb(24 30 34 / 0.08);
        height: 44px;
    }
`;
const TableCell = styled.td<{ $align?: "left" | "right" }>`
    text-align: ${(p) => p.$align ?? "left"};
`;
const Client = styled.div`
    display: flex;
    gap: 8px;
`;

const formatValue = (value: number) =>
    fmt(value, { significantDigits: 2, type: "currency" }).formattedValue;

const formatPercentValue = (absoluteValue: number) => {
    const percent = toPercent(absoluteValue);

    return `${percent}%`;
};

type BatChartItemModalContentType = {
    period: string;
    items: ChartData[];
};
export const BatChartItemModalContent: FC<BatChartItemModalContentType> = ({ period, items }) => {
    const entireSum = items.reduce((acc, item) => acc + item.y, 0);

    return (
        <Container>
            <Header>
                <Title>Turnover per hour</Title>
                <Sum>${formatValue(entireSum)}</Sum>
            </Header>
            <Period>{period}</Period>
            <Content>
                <Table>
                    <TableHead>
                        <tr>
                            <TableCell>Name</TableCell>
                            <TableCell $align="right">Amount</TableCell>
                            <TableCell $align="right">Turnover</TableCell>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {[...items]
                            .sort((a, b) => b.y - a.y)
                            .map((client) => (
                                <tr key={client.clientId}>
                                    <TableCell>
                                        <Client>
                                            <PBold>{client.clientName}</PBold>
                                            <ClientId id={client.clientId} />
                                        </Client>
                                    </TableCell>
                                    <TableCell $align="right">${formatValue(client.y)}</TableCell>
                                    <TableCell $align="right">
                                        {formatPercentValue(client.percent ?? 0)}
                                    </TableCell>
                                </tr>
                            ))}
                    </TableBody>
                </Table>
            </Content>
        </Container>
    );
};
