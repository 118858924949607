import { useGA4Context } from "@fm-frontend/utils";
import { ReactNode, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAppSelector } from "~app/store";
import { selectAuthenticated } from "~app/store/appSlice";
import { Analytics } from "~pages/analytics/Analytics";
import { SignIn } from "~pages/auth/SignIn";
import { Spreads } from "~pages/spreads/Spreads";

type Paths = "spreads" | "analytics" | "login";

export const routerPaths: Record<Paths, { path: string; component: ReactNode }> = {
    spreads: {
        path: "/spreads",
        component: <Spreads />,
    },
    analytics: {
        path: "/analytics",
        component: <Analytics />,
    },
    login: {
        path: "/login",
        component: <SignIn />,
    },
};

export const Router = () => {
    const isAuthenticated = useAppSelector<boolean>(selectAuthenticated);
    const { logPageView } = useGA4Context();

    useEffect(() => logPageView(), [logPageView]);

    return (
        <Switch>
            <Route path={routerPaths.spreads.path}>{routerPaths.spreads.component}</Route>
            {!isAuthenticated && (
                <Route exact path={routerPaths.login.path}>
                    {routerPaths.login.component}
                </Route>
            )}
            {isAuthenticated && (
                <Route exact path={routerPaths.analytics.path}>
                    {routerPaths.analytics.component}
                </Route>
            )}
            <Route exact path="/healthz/live" render={() => <>{(window.location.href = "/healthz/live.html")}</>} />
            <Redirect to={routerPaths.spreads.path} />
        </Switch>
    );
};
