import styled, { css } from "styled-components";
import { P, PXSmall } from "../../../typography";

export const NormalValue = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const NewValue = styled(P)<{ hasError: boolean }>`
    ${(p) =>
        p.hasError &&
        css`
            color: ${p.theme.colors.negative100};
        `}
`;
export const OldValue = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui52};
    text-decoration-line: line-through;
`;

export const Container = styled.div`
    padding-right: 12px;

    ::before {
        pointer-events: none;
        content: "";
        display: block;
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const DiffContainer = styled(Container)<{ hasError?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ::after {
        pointer-events: none;
        content: "";
        display: block;
        background-color: ${(p) =>
            p.hasError ? p.theme.colors.negative8 : p.theme.colors.positive8};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const ErrorHindContainer = styled.div`
    position: absolute;
    top: -2px;
    right: 1px;
`;
