import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import * as IconComponents from "./styled";

const CoinOverrides = {
    dai: "dai.png",
    shib: "shib.png",
    maticp: "matic.svg",
    "matic.polygon": "matic.svg",
    "bnb.bsc": "bnb.svg",
    bnbbsc: "bnb.svg",
    avaxc: "avax.png",
    avax: "avax.png",
    busdbs: "busd.svg",
    usdcp: "usdc.svg",
    usdttr: "usdt.svg",
    usdtsol: "usdt.svg",
} as const;

const generateSrc = (name: string, path: string) => {
    const lowerCasedName = name.split(" ").join("").toLowerCase();

    if (Object.hasOwn(CoinOverrides, lowerCasedName)) {
        const overriddenName = CoinOverrides[lowerCasedName as keyof typeof CoinOverrides];
        return `/${path}/${overriddenName}`;
    }

    return `/${path}/${lowerCasedName}.svg`;
};

const failedIconCache: Record<string, boolean> = {};

type IconViewProps = {
    name: string | undefined;
    fallbackName?: string;
    path: string;
    size?: number;
};

export const IconView: FC<IconViewProps> = memo(
    ({
        name,
        fallbackName = "generic",
        path,
        size = 24,
    }) => {
        const defaultIconUrl = `/icons/${fallbackName}.svg`;
        const generatedIconUrl = useMemo(
            () => (name ? generateSrc(name, path) : defaultIconUrl),
            [name, path, defaultIconUrl],
        );
        const isFailedIcon = failedIconCache[generatedIconUrl];

        const [iconUrl, setIconUrl] = useState(isFailedIcon? defaultIconUrl : generatedIconUrl);

        const handleLoad = useCallback(() => {
            failedIconCache[generatedIconUrl] = false;
            setIconUrl(generatedIconUrl);
        }, [generatedIconUrl]);

        const handleError = useCallback(() => {
            failedIconCache[generatedIconUrl] = true;
            setIconUrl(defaultIconUrl);
        }, [generatedIconUrl]);

        useEffect(() => {
            if (isFailedIcon) {
                const checkImage = new Image();
                checkImage.onload = handleLoad;
                checkImage.src = generatedIconUrl;

                return () => {
                    checkImage.onload = null;
                };
            }
        }, [isFailedIcon, generatedIconUrl, handleLoad]);

        return <img alt={name} src={iconUrl} width={size} height={size} onError={handleError} />;
    },
);

export interface IconProps {
    name: string | undefined;
    size?: number;
}

export const CurrencyIcon = (props: IconProps) => <IconView {...props} path="icons/currencies" />;
export const CounterpartyIcon = (props: IconProps) => (
    <IconView {...props} fallbackName="genericCp" path="icons/counterparties" />
);
export const Icon = (props: IconProps) => <IconView {...props} path="icons/common" />;
export const Icons = IconComponents;
