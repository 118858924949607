import moment from "moment/moment";
import { AnalyticsPeriods } from "~app/store/turnoverSlice.types";
import { ChartData } from "~components/linerChart/LinerChart";

export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = moment().daysInMonth();

export const LEAD_CHART_COLORS = ["#18BD5A", "#EE325F", "#F29305", "#EB91F2", "#3662FF"];
export const REST_CHART_COLOR = "#86E2D6";

export const mapXAxisLabel = (
    timeStamp: number,
    position: number,
    selectedPeriod?: string,
): { label: string; longLabel: string; isLabelVisible: boolean } => {
    let label = "";
    let longLabel = "";
    let isLabelVisible = false;

    const dateTime = moment(timeStamp);

    if (selectedPeriod === AnalyticsPeriods["24h"] || selectedPeriod === AnalyticsPeriods.Today) {
        label = dateTime.format("HH:mm");
        longLabel = `${dateTime.format("DD.MM.y HH:mm")}-${dateTime.add(1, "hours").format("HH:mm")}`;

        isLabelVisible = position === 0 || position % 6 === 0;
    }
    if (
        selectedPeriod === AnalyticsPeriods["7d"] ||
        selectedPeriod === AnalyticsPeriods["30d"] ||
        selectedPeriod === AnalyticsPeriods.Month
    ) {
        const monthDay = dateTime.format("D");
        label = monthDay;
        longLabel = dateTime.format("DD.MM.y 00:00-23:59");

        if (monthDay === "1" || position === 0) {
            label += ` ${dateTime.format("MMM")}`;
        }

        isLabelVisible = true;
    }

    return {
        label,
        longLabel,
        isLabelVisible,
    };
};

export const toPercent = (absoluteValue: number) => {
    return Number((absoluteValue * 100).toFixed(2));
};

export const mapBarChartItems = (
    initialData: ChartData[],
    initPeriodDate: moment.Moment,
    itemsCount: number,
    periodShift: "hours" | "days",
): ChartData[] => {
    let barChartItems: ChartData[] = [];
    let periodDate = initPeriodDate.clone();

    for (let i = 0; i < itemsCount; i++) {
        const currentItems = initialData.filter((item) => moment(item.x).isSame(periodDate, periodShift));

        if (currentItems.length > 0) {
            currentItems.forEach((currentItem) => {
                barChartItems.push({
                    ...currentItem,
                    x: moment(currentItem.x).valueOf(),
                });
            });
        } else {
            barChartItems.push({
                percent: 0,
                clientId: 0,
                x: periodDate.valueOf(),
                y: -1,
            });
        }

        periodDate = periodDate.subtract(1, periodShift);
    }

    return barChartItems.reverse();
};
