import { getEnv } from "@fm-frontend/utils";
import { Middleware } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { SnapshotData, SnapshotDataUpdate, TableDataUpdate, UptimeData } from "../../types";
import {
    resetAllData,
    resetChartData,
    resetConfig,
    resetSnapshot,
    setAuthenticated,
    setChartUpdate,
    setConfig,
    setDefaultActiveMembers,
    setEnabledMembers,
    setError,
    setSnapshotData,
    setTableUpdate,
    setToken,
    setUptimeUpdate,
    submitFilter,
} from "./appSlice";
import { forceRestartConnecting, restartConnecting, setConnected, startConnecting } from "./socketSlice";
import { ATTEMPTS_COUNT, AUTHENTICATION_FAILED_CODE, createTokenParams, DELAY_TIME } from "./utils";

const socketMiddleware: Middleware = (store) => {
    let socket: WebSocket;
    let reconnectAttempt = 0;
    let authAttempt = 0;

    const reconnectSocket = () => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            restartConnection();
        }
    };
    const delayedReconnection = () => {
        setTimeout(() => {
            restartConnection();
            reconnectAttempt++;
        }, DELAY_TIME);
    };
    const delayedRefreshToken = () => {
        setTimeout(() => {
            restartConnection(true);
        }, DELAY_TIME);
    };
    const onOpen = (e: Event) => {
        reconnectAttempt = 0;
        store.dispatch(setConnected(true));
    };
    const onError = (e: Event) => {
        if (reconnectAttempt > ATTEMPTS_COUNT) {
            store.dispatch(setError(true));
            return;
        }

        delayedReconnection();
    };
    const onAuthFail = () => {
        if (++authAttempt > ATTEMPTS_COUNT) {
            store.dispatch(setError(true));
            return;
        }

        delayedRefreshToken();
    };
    const onConfigMessage = (message: MessageEvent<any>) => {
        const data = JSON.parse(message.data);

        if (!Array.isArray(data)) {
            store.dispatch(setConfig(data));
            store.dispatch(setDefaultActiveMembers());
        }
        if (Array.isArray(data) && data[0] === "DS") {
            store.dispatch(resetAllData(false));
            store.dispatch(resetChartData(false));
            store.dispatch(setDefaultActiveMembers());
        }
        if (Array.isArray(data) && data[0] === "D") {
            const update = data as TableDataUpdate;
            store.dispatch(setTableUpdate(update));
        }
        if (Array.isArray(data) && data[0] === "C") {
            const update = data as SnapshotDataUpdate;
            store.dispatch(setChartUpdate(update));
        }
        if (Array.isArray(data) && data[0] === "S") {
            const snapshotData: SnapshotData[] = data[1];
            store.dispatch(setSnapshotData(snapshotData));
            store.dispatch(setEnabledMembers());
        }
        if (Array.isArray(data) && data[0] === "U") {
            const uptimeData: UptimeData = data as UptimeData;
            store.dispatch(setUptimeUpdate(uptimeData));
        }
        if (Array.isArray(data) && data[0] === "Z" && data[1] === AUTHENTICATION_FAILED_CODE) {
            onAuthFail();
        } else {
            authAttempt = 0;
        }
    };
    const restartConnection = (forceRefreshToken = false) => {
        socket?.close();
        store.dispatch(setConnected(false));
        store.dispatch(resetConfig());
        store.dispatch(resetSnapshot());
        initSocket(forceRefreshToken);
    };

    const initSocket = (forceRefreshToken = false) => {
        const init = (token?: string) => {
            socket = new WebSocket(getEnv().REACT_APP_WS_URL + "/ws" + createTokenParams(token));
            socket.addEventListener("open", onOpen);
            socket.addEventListener("message", onConfigMessage);
            socket.addEventListener("error", onError);
        };

        const { app } = store.getState();

        if (app?.token && !forceRefreshToken) {
            init(app.token);
        } else {
            Auth.currentAuthenticatedUser({
                bypassCache: true,
            })
                .then((user) => {
                    const token: string = user.signInUserSession.idToken.jwtToken;
                    init(token);
                    store.dispatch(setAuthenticated(true));
                    store.dispatch(setToken(token));
                })
                .catch(() => {
                    init();
                    store.dispatch(setAuthenticated(false));
                    store.dispatch(setToken());
                });
        }
    };

    return (next) => (action) => {
        if (forceRestartConnecting.match(action)) {
            restartConnection();
        }
        if (restartConnecting.match(action)) {
            reconnectSocket();
        }
        if (startConnecting.match(action)) {
            initSocket();
        }
        if (submitFilter.match(action) && socket?.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ request: action.payload }));
        }

        next(action);
    };
};

export default socketMiddleware;
