import { CACHED_MEMBERS_STATE, ONE_BILLION, ONE_MILLION, ONE_THOUSAND } from "../const";
import { MembersState } from "../app/store/appSlice.types";

const MIN_FRACTION_DIGITS = 2;
const MAX_FRACTION_DIGITS = 8;
const MIN_NON_ZERO_DIGITS = 5;

export const formatNumber = (num: number): string => {
    let integerCounter = 0;
    const str = Number(num).toFixed(8).toString();
    if(str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
        integerCounter = str.split('.')[0].length - 1;
    } else if(str.indexOf('.') !== -1 && str.split('.')[0] !== '0') {
        integerCounter = str.split('.')[0].length;
    }

    let maximumFractionDigits = MAX_FRACTION_DIGITS;
    let minimumFractionDigits = MIN_FRACTION_DIGITS;
    if(integerCounter >= MIN_NON_ZERO_DIGITS) {
        maximumFractionDigits = MIN_FRACTION_DIGITS;
    } else {
        maximumFractionDigits = MIN_NON_ZERO_DIGITS - integerCounter;
        maximumFractionDigits = (maximumFractionDigits >= MIN_FRACTION_DIGITS) ? maximumFractionDigits : MIN_FRACTION_DIGITS;
        let additionalFractionDigitsCounter = 0;
        if(str.indexOf('.0') !== -1) {
            for (let char of str.split('.0')[1]) {
                additionalFractionDigitsCounter++;
                if(char !== '0') break;
            }

            maximumFractionDigits = maximumFractionDigits + additionalFractionDigitsCounter;
            if(maximumFractionDigits > MAX_FRACTION_DIGITS) {
                maximumFractionDigits = MAX_FRACTION_DIGITS;
            } else if(maximumFractionDigits < MIN_FRACTION_DIGITS) {
                maximumFractionDigits = MIN_FRACTION_DIGITS;
            }
        }
    }

    const formatter = new Intl.NumberFormat('us-us', {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits,
        maximumFractionDigits
    });

    let formattedNum = (num: any) => formatter.format(num).replace(',', '.'); // fix for Safari for IOS
    return formattedNum(num);
}

export const getCachedActiveMembers = (): MembersState => {
    const sessionStorageValue = sessionStorage.getItem(CACHED_MEMBERS_STATE);
    const result = tryParse<MembersState>(sessionStorageValue);

    return result ?? {};
}

export const tryParse = <TType>(jsonString: string | null): TType | null => {
    if(!jsonString) {
        return null;
    }

    try {
        return <TType>JSON.parse(jsonString)
    } catch (e: unknown) {
        if(e instanceof SyntaxError) {
            console.error(e.name);
        } else if(e instanceof Error) {
            console.error(e.message);
        } else {
            console.error(e);
        }

        return null;
    }
}

export const turnoverPeriodNumberFormat = (number: number) => {
    const formattedNumber = Number(number.toFixed(2));

    if(formattedNumber < ONE_THOUSAND) {
        return `${formattedNumber}`;
    }
    if(formattedNumber >= ONE_THOUSAND && formattedNumber < ONE_MILLION) {
        return `${(formattedNumber / ONE_THOUSAND).toFixed(2)}k`;
    }
    if(formattedNumber >= ONE_MILLION && formattedNumber < ONE_BILLION) {
        return `${(formattedNumber / ONE_MILLION).toFixed(2)}M`;
    }
    if(formattedNumber >= ONE_BILLION) {
        return `${(formattedNumber / ONE_BILLION).toFixed(2)}B`;
    }

    return '';
}

export const noop = () => {};
