import {
    CategoryScale,
    Chart as ChartJS,
    ChartType,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
    TooltipItem,
    TooltipModel,
    type ChartOptions,
} from "chart.js";
import { Color } from "chart.js/types/color";
import moment from "moment";
import { useState } from "react";

export const useChartOptions = () => {
    const [options, setOptions] = useState<any>(initOptions);

    return [options, setOptions];
};

const labelTypes: Record<string, { unit: string; format: string }> = {
    "1D": { unit: "hour", format: "HH:mm" },
    "1W": { unit: "day", format: "ddd" },
    "1M": { unit: "day", format: "MM/DD" },
};

const tooltipWidth = 257;

const getOrCreateTooltip = (chart: ChartJS) => {
    let tooltipEl = chart?.canvas?.parentNode?.querySelector("div");

    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.classList.add("chart_tooltip");
        tooltipEl.style.pointerEvents = "none";

        chart?.canvas?.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const createTitleRow = (parent: HTMLDivElement, data: string = "") => {
    const rowEl = document.createElement("div");
    rowEl.classList.add("row");
    rowEl.style.paddingBottom = "8px";
    rowEl.style.display = "flex";
    rowEl.style.justifyContent = "center";

    const spanEl = document.createElement("span");
    spanEl.innerText = data;

    rowEl.appendChild(spanEl);
    parent.appendChild(rowEl);
};

const createRow = (
    parent: HTMLDivElement,
    title: string,
    color: Color = "grey",
    data: string = "-",
    isBold: boolean,
) => {
    const rowEl = document.createElement("div");
    rowEl.classList.add("row");

    if (isBold) {
        rowEl.style.fontWeight = "500";
    }

    const nameEl = document.createElement("div");
    nameEl.classList.add("name");
    nameEl.innerText = title;

    const spanEl = document.createElement("span");
    spanEl.style.background = color as string;

    const dataEl = document.createElement("div");
    dataEl.classList.add("data");
    dataEl.innerText = data;

    nameEl.appendChild(spanEl);
    rowEl.appendChild(nameEl);
    rowEl.appendChild(dataEl);
    parent.appendChild(rowEl);
};

const externalTooltipHandler = (context: { chart: ChartJS; tooltip: TooltipModel<ChartType> }) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = "0";
        return;
    }

    if (tooltip.body) {
        // Remove old children
        while (tooltipEl.firstChild) {
            tooltipEl.firstChild.remove();
        }

        createTitleRow(tooltipEl, tooltip?.title?.[0]);

        const bodyLines: string[][] = tooltip.body.map((b) => b.lines);

        bodyLines
            .map((body: string[], index: number) => {
                return {
                    body,
                    index,
                } as {
                    body: string[];
                    index: number;
                };
            })
            .sort((left, right) => toolTipSort(left.body, right.body))
            .forEach(({ body, index }, i) => {
                const color = tooltip.labelColors[index];
                const afterBody = tooltip.afterBody[index] ?? "";
                const [title, value] = body?.[0].split(":");

                createRow(
                    tooltipEl,
                    `${title} `,
                    color?.backgroundColor,
                    `${value}${afterBody}`,
                    i === bodyLines.length - 1,
                );
            });
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = "1";
    tooltipEl.style.left = calculateOffsetX(chart, tooltip, positionX);
    tooltipEl.style.top = calculateOffsetY(chart, tooltip, positionY);
};

const toolTipSort = ([left]: string[], [right]: string[]) => {
    const [, leftValue] = left.split(":");
    const [, rightValue] = right.split(":");

    const parsedLeft = parseFloat(leftValue);
    const parsedRight = parseFloat(rightValue);

    return parsedLeft < parsedRight ? 1 : -1;
};

const calculateOffsetY = (chart: ChartJS, tooltip: TooltipModel<ChartType>, positionY: number): string => {
    if (parseFloat(chart.canvas.style.width) < tooltipWidth * 2.5) {
        return positionY + tooltip.caretY + "px";
    }

    return positionY + 5 + "px";
};

const calculateOffsetX = (chart: ChartJS, tooltip: TooltipModel<ChartType>, positionX: number): string => {
    let offset: number;
    const canvasWidth = parseFloat(chart.canvas.style.width);
    const caretX = tooltip.caretX;

    if (canvasWidth < tooltipWidth * 2.5) {
        const tooltipWidth = 257 + 30;
        const caretX = tooltip.caretX;
        offset = positionX + caretX + 10;

        if (caretX > canvasWidth / 2) {
            offset -= tooltipWidth;
        }
    } else {
        const leftSizeOffset = positionX + 47;
        const rightSizeOffset = positionX + canvasWidth - tooltipWidth - 9;

        offset = leftSizeOffset;

        if (caretX < canvasWidth / 2) {
            offset = rightSizeOffset;
        }
    }

    return offset + "px";
};

const initOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            callbacks: {
                afterBody(tooltipItems: TooltipItem<ChartType>[]): string | string[] {
                    return tooltipItems.map(() => "%");
                },
            },
            external: externalTooltipHandler,
        },
    },
    scales: {
        y: {
            type: "linear" as const,
            display: true,
            grid: {
                display: false,
            },
            position: "left" as const,
            ticks: {
                autoSkip: true,
                autoSkipPadding: 50,
                callback: function (value) {
                    if (typeof value === "number") {
                        return this.getLabelForValue(value) + "%";
                    }

                    return value;
                },
            },
        },
        x: {
            type: "time" as const,
            adapters: {
                date: moment(),
            },
            time: {
                isoWeekday: true,
                tooltipFormat: "MM/DD/yyyy HH:mm",
            },
            ticks: {
                autoSkip: true,
                autoSkipPadding: 10,
                maxRotation: 0,
                minRotation: 0,
                callback: (tick: number | string) => tick.toString().toUpperCase(),
            },
        },
    },
    interaction: {
        intersect: false,
        axis: "x" as const,
        mode: "nearest" as const,
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    {
        id: "arbitraryLine",
        beforeDraw: function (chart: ChartJS) {
            if (chart?.tooltip) {
                const activePoint = chart.tooltip.getActiveElements()[0];
                if (activePoint) {
                    const ctx = chart.ctx;
                    drawLine(
                        ctx,
                        activePoint.element.x,
                        chart.chartArea.top,
                        activePoint.element.x,
                        chart.chartArea.bottom,
                        "#A9A9A9",
                    );
                }
            }
        },
    },
    {
        id: "borderLine",
        afterDraw: function (chart: any) {
            if (chart && chart.config.type === "line") {
                if (chart.chartArea) {
                    const ctx = chart.ctx;
                    drawLine(
                        ctx,
                        chart.chartArea.left,
                        chart.chartArea.top,
                        chart.chartArea.left,
                        chart.chartArea.bottom,
                    );
                    drawLine(
                        ctx,
                        chart.chartArea.right,
                        chart.chartArea.top,
                        chart.chartArea.right,
                        chart.chartArea.bottom,
                    );
                }
            }
        },
    },
    {
        id: "blur",
        beforeDraw: function (chart) {
            if (!chart.data.datasets.length) {
                if (chart.chartArea) {
                    const ctx = chart.ctx;
                    ctx.save();
                    ctx.filter = "blur(5px)";
                }
            }
        },
    },
    {
        id: "loadingLine",
        afterDraw: function (chart) {
            if (!chart.data.datasets.length) {
                if (chart.chartArea) {
                    const ctx = chart.ctx;
                    ctx.save();
                    ctx.textAlign = "center";
                    ctx.font = "16px sans-serif";
                    ctx.filter = "none";
                    ctx.fillText("No data so far...", chart.chartArea.width / 2, chart.chartArea.height / 2);
                }
            }
        },
    },
);

export const getTimeConfig = (
    period: string,
): {
    unit?: string;
    displayFormats?: Record<string, string>;
} => {
    if (period) {
        const config: { unit: string; format: string } = labelTypes[period];

        const formats: Record<string, string> = {};
        formats[config.unit] = config.format;

        return {
            unit: config.unit,
            displayFormats: formats,
        };
    }

    return {};
};

const drawLine = (
    ctx: CanvasRenderingContext2D,
    moveToX: number,
    moveToY: number,
    lineToX: number,
    lineToY: number,
    strokeStyle: string = "#E6E6E6",
) => {
    if (!ctx) {
        return;
    }

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(moveToX, moveToY);
    ctx.lineTo(lineToX, lineToY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = strokeStyle;
    ctx.stroke();
    ctx.restore();
};

export const generateColor = (colors: string[]) => {
    let color: string = "#808080";

    while (colors.some((item) => item.toUpperCase() === color.toUpperCase())) {
        color = getRandomColor();
    }

    return color;
};

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
};
