import { DelayMode, Tooltip } from "@fm-frontend/uikit";
import React from "react";
import { ChartData } from "~components/linerChart/LinerChart";
import { BatChartItemTooltip } from "./BatChartItemTooltip";
import * as Styled from "./styled";
import { REST_CHART_COLOR, toPercent } from "./utils";

interface Props {
    items: ChartData[];
    maxValue: number;
    tooltipLabel?: string;
    xAxisLabel?: string;
    isLabelVisible?: boolean;
    onClick: () => void;
}

export const BarChartItem: React.VFC<Props> = ({
    items,
    maxValue,
    tooltipLabel,
    xAxisLabel,
    isLabelVisible,
    onClick,
}) => {
    const mostValuableItems = items.filter((item) => item.color !== REST_CHART_COLOR && item.y > 0);
    const otherItems = items.filter((item) => item.color === REST_CHART_COLOR && item.y > 0);
    const sum = items.reduce((acc, item) => acc + item.y, 0);
    const otherSum = otherItems.reduce((acc, item) => acc + (item.y < 0 ? 0 : item.y), 0);
    const inactive = sum < 0;
    const tooltipContent = inactive ? null : <BatChartItemTooltip period={tooltipLabel} items={items} />;

    return (
        <Styled.BarChartContainer>
            <Tooltip content={tooltipContent} positions={["bottom"]} align="center" delayMode={DelayMode.All}>
                <Styled.BarContainer inactive={inactive} onClick={inactive ? undefined : onClick}>
                    {mostValuableItems.map((item) => (
                        <Styled.BarLine
                            key={`${item.x}-${item.clientId}`}
                            $color={item.color}
                            heightPart={toPercent(item.y / maxValue)}
                        />
                    ))}
                    {otherItems.length > 0 && (
                        <Styled.BarLine
                            key={`${otherItems[0].x}-other`}
                            $color={otherItems[0].color}
                            heightPart={toPercent(otherSum / maxValue)}
                        />
                    )}
                </Styled.BarContainer>
            </Tooltip>
            <Styled.BarChartXAxis isVisible={isLabelVisible}>
                <span>{xAxisLabel}</span>
            </Styled.BarChartXAxis>
        </Styled.BarChartContainer>
    );
};
