import {
    backgroundFadeOutKeyframesCss,
    chartResetCss,
    checkBoxResetCss,
    modalAnimationCss,
    movingGradientKeyframesCss,
    resetCss,
    scrollCss,
    selectors,
    spinKeyframesCss,
} from "@fm-frontend/uikit";
import styled, { createGlobalStyle, css } from "styled-components";
import { onTablet } from "../const";

const commonCss = css`
    body {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${resetCss};
  ${spinKeyframesCss};
  ${movingGradientKeyframesCss};
  ${backgroundFadeOutKeyframesCss};
  ${scrollCss};
  ${modalAnimationCss};
  ${chartResetCss};
  ${checkBoxResetCss};
  ${commonCss}
`;

export const Spacer = styled.div`
    flex: 1 1 0;
`;

export const SkeletonLine = styled.div`
    border-radius: 6px;
    min-width: 1rem;
    width: 100%;
    height: 1rem;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
`;

export const Cards = styled.div`
    width: calc(100% - 313px);

    & p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.005em;
        color: #999;
        text-align: center;
    }

    ${onTablet} {
        width: 100%;
        overflow-x: auto;
    }
`;

export const Hint = styled.span`
    opacity: 0;
    display: inline-block;
    position: absolute;
    background: #000;
    border-radius: 6px;
    color: #fff;
    padding: 8px 10px;

    ${selectors.mixinBody2}

    font-size: 12px;
    min-width: 250px;
    text-align: left;
    bottom: 0;
    right: 0;
    margin-bottom: -57px;
    z-index: -5;
    transition: all 1s;
`;

export const LinkButton = styled.a<{ big?: boolean; light?: boolean }>`
    cursor: pointer;
    background: #16c7b0;
    display: inline-flex;
    align-items: center;
    padding: 9px 16px 9px;
    justify-content: center;
    color: #fff;
    border-radius: 8px;

    ${selectors.mixinInterface1}

    text-align: center;
    width: 100%;

    :hover {
        opacity: 1;
        text-decoration: none;
        background: #4fd6c5;
    }

    ${({ big }) =>
        big &&
        css`
            padding: 13px 30px;
        `}

    ${({ light }) =>
        light &&
        css`
            color: #33b5a4;
            background: rgba(255, 255, 255, 0.001);
            border: solid 1px rgba(22, 199, 176, 0.32);

            :hover {
                color: #33b5a4;
                background: #e6f5f3a8;
            }
        `}
`;
