import React from "react";
import styled from "styled-components";

const Icon = styled.img`
  width: 104px;
  height: 52px;
`

export const ChartLoader = () => {
    return (
        <Icon src="/icons/chartLoader.svg" alt="" />
    )
}