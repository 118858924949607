import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IconLogo } from "~components/icons";
import { ConfirmMfaCode } from "./ConfirmMfaCode";
import { Login } from "./Login";
import { PasswordChange } from "./PasswordChange";
import { PasswordReset } from "./PasswordReset";
import { PasswordResetSubmit } from "./PasswordResetSubmit";
import { SetupMfaCode } from "./SetupMfaCode";
import { BottomLogin, Logo, Slim, StyledForm } from "./style";

export const enum SignInSteps {
    ConfirmMfaCode,
    Login,
    ChangePassword,
    SetupMfaCode,
    ResetPassword,
    ResetPasswordSubmit,
}

export const LinkSupport = () => <a href="mailto:help@finerymarkets.com">Support</a>;

export const SignIn: React.FC = () => {
    const [step, setStep] = useState<SignInSteps>(SignInSteps.Login);
    const [email, setEmail] = useState<string>();
    const [userState, setUserState] = useState<any>();
    const history = useHistory();

    const getContent = () => {
        if (step === SignInSteps.Login) {
            return <Login setUser={setUserState} setStep={(step) => setStep(step)} />;
        }
        if (step === SignInSteps.ConfirmMfaCode) {
            return <ConfirmMfaCode userState={userState} />;
        }
        if (step === SignInSteps.ChangePassword) {
            return <PasswordChange userState={userState} setStep={setStep} />;
        }
        if (step === SignInSteps.ResetPassword) {
            return <PasswordReset setEmail={setEmail} setStep={setStep} />;
        }
        if (step === SignInSteps.ResetPasswordSubmit) {
            return <PasswordResetSubmit email={email} setStep={setStep} />;
        }
        if (step === SignInSteps.SetupMfaCode) {
            return <SetupMfaCode userState={userState} setStep={setStep} />;
        }
        return null;
    };

    return (
        <Slim>
            <Logo>
                <a href="/">
                    <IconLogo />
                </a>
            </Logo>
            <StyledForm>{getContent()}</StyledForm>
            <BottomLogin>
                {step === SignInSteps.Login && (
                    <>
                        <a onClick={() => history.push("/spreads")}>Back to Spreads</a>
                        {LinkSupport()}
                        <a onClick={() => setStep(SignInSteps.ResetPassword)}>Forgot Password?</a>
                    </>
                )}
                {step !== SignInSteps.Login && (
                    <>
                        <a onClick={() => setStep(SignInSteps.Login)}>Back to Login</a>
                        {LinkSupport()}
                    </>
                )}
            </BottomLogin>
        </Slim>
    );
};
