import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spinner = styled.div`
  display: block;
  z-index: 9999;
  height: 44px;
  width: 44px;
  border: 4px #E5F4F2 solid;
  border-top: 4px #16C7B0 solid;
  border-radius: 50%;
  -webkit-animation: spin2 1s infinite linear;
  animation: spin2 1s infinite linear;
`

export const Loading: React.FC<{
    children: React.ReactNode,
    loading?: boolean
}> = ({
          children,
          loading
      }) => {
    return (
        <>
            {
                loading ? (
                    <Loader>
                        <Spinner/>
                    </Loader>
                ) : (
                    children
                )
            }
        </>
    )
}