import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
    resetAllData,
    resetChartData,
    selectConfig,
    selectSelectedPeriod,
    setAmount,
    setPeriods,
    setVolume,
    submitFilter,
    updateInstrumentLabel,
    updatePeriod,
} from "../../app/store/appSlice";
import { Config } from "../../app/store/appSlice.types";
import { selectIsConnected } from "../../app/store/socketSlice";
import { QueryParams, useQueryParams } from "../../hooks/useQueryParams";
import { SkeletonLine } from "../../style";
import { SelectItem } from "../../types";
import { CustomSelect } from "../select/CustomSelect";
import { FilterLabel, FilterSelect, FiltersWrapper, FilterTitle } from "./style";
import { mapAmounts, mapBaseCurrency, mapInstruments, mapPeriods, mapVolumes, validateQueryItem } from "./utils";

const FilterLabels = {
    instrument: "Instrument",
    volume: "Your monthly trading volume",
    amount: "Size",
};

const DEFAULT_INSTRUMENT = "BTC/EUR";
const DEFAULT_VOLUME = 1;
const DEFAULT_AMOUNT = 1;

export const SpreadsFilter: React.FC = () => {
    const dispatch = useAppDispatch();

    const [instruments, setInstruments] = useState<SelectItem[]>([]);
    const [amounts, setAmounts] = useState<SelectItem[]>([]);
    const [volumes, setVolumes] = useState<SelectItem[]>([]);

    const [selectedInstrument, setSelectedInstrument] = useState<string>("");
    const [selectedVolume, setSelectedVolume] = useState<number>(0);
    const [selectedAmount, setSelectedAmount] = useState<number>(0);
    const [selectedPeriod, setSelectedPeriod] = useState<string>("");
    const [queryParams, setQueryParams] = useQueryParams();

    const period = useAppSelector<string>(selectSelectedPeriod);
    const config = useAppSelector<Config>(selectConfig);
    const isConnected = useAppSelector<boolean>(selectIsConnected);

    useEffect(() => {
        setSelectedPeriod((selectedPeriod) => {
            return selectedPeriod === period ? selectedPeriod : period;
        });
    }, [period]);

    useEffect(() => {
        const [
            queryInstrument = DEFAULT_INSTRUMENT,
            queryAmount = DEFAULT_AMOUNT,
            queryVolume = DEFAULT_VOLUME,
            queryPeriod,
        ] = queryParams;

        const instruments = mapInstruments(config);
        const selectedInstrument = validateQueryItem(instruments, queryInstrument) ?? instruments?.[0]?.value;
        const baseCurrency = mapBaseCurrency(selectedInstrument);
        const periods = mapPeriods(config);
        const volumes = mapVolumes(config);
        const amounts = mapAmounts(config, selectedInstrument, baseCurrency);

        setInstruments(instruments);
        setVolumes(volumes);
        setAmounts(amounts);
        dispatch(setPeriods(periods));

        const selectedVolume: number = validateQueryItem(volumes, queryVolume) ?? volumes?.[0]?.value;
        const selectedAmount = validateQueryItem(amounts, queryAmount) ?? amounts?.[0]?.value;
        const selectedPeriod = validateQueryItem(periods, queryPeriod) ?? periods?.[0]?.value;

        setSelectedInstrument(selectedInstrument);
        setSelectedVolume(selectedVolume);
        setSelectedAmount(selectedAmount);
        dispatch(updatePeriod(selectedPeriod));
        dispatch(updateInstrumentLabel(selectedInstrument));
        dispatch(setVolume(selectedVolume));
        dispatch(setAmount(selectedAmount));
    }, [config]);

    useEffect(() => {
        sendMessage(() => dispatch(resetAllData(true)));
    }, [selectedInstrument, selectedVolume, selectedAmount, isConnected]);

    useEffect(() => {
        sendMessage(() => dispatch(resetChartData(true)));
    }, [selectedPeriod]);

    const updateAmounts = (selectedInstrument: string) => {
        const baseCurrency = mapBaseCurrency(selectedInstrument);
        const amounts = mapAmounts(config, selectedInstrument, baseCurrency);
        setAmounts(amounts);

        if (!amounts.some((amount) => amount.value === selectedAmount)) {
            setSelectedAmount(amounts?.[0].value);
            dispatch(setAmount(amounts?.[0].value));
        }
    };

    const sendMessage = (callback: () => void) => {
        if (selectedInstrument && selectedVolume && selectedAmount && selectedPeriod && isConnected) {
            const params: QueryParams = [selectedInstrument, selectedAmount, selectedVolume, selectedPeriod];
            setQueryParams(params);
            dispatch(submitFilter(params));

            callback && callback();
        }
    };

    const onInstrumentChange = (value: string | number) => {
        const selectedInstrument = value.toString();
        setSelectedInstrument(selectedInstrument);
        dispatch(updateInstrumentLabel(selectedInstrument));
        updateAmounts(selectedInstrument);
    };
    const onVolumeChange = (value: string | number) => {
        setSelectedVolume(+value);
        dispatch(setVolume(+value));
    };
    const onAmountChange = (value: string | number) => {
        setSelectedAmount(+value);
        dispatch(setAmount(+value));
    };

    return (
        <FiltersWrapper>
            <FilterTitle>Market options</FilterTitle>
            <FilterLabel>{FilterLabels.instrument}</FilterLabel>
            <FilterSelect>
                {instruments.length ? (
                    <CustomSelect
                        options={instruments}
                        selectedValue={selectedInstrument}
                        label={FilterLabels.instrument}
                        onChange={onInstrumentChange}
                    />
                ) : (
                    <SkeletonLine />
                )}
            </FilterSelect>
            <FilterLabel>{FilterLabels.amount}</FilterLabel>
            <FilterSelect>
                {amounts.length ? (
                    <CustomSelect
                        options={amounts}
                        selectedValue={selectedAmount}
                        label={FilterLabels.amount}
                        onChange={onAmountChange}
                    />
                ) : (
                    <SkeletonLine />
                )}
            </FilterSelect>
            <FilterLabel>{FilterLabels.volume}</FilterLabel>
            <FilterSelect>
                {volumes.length ? (
                    <CustomSelect
                        options={volumes}
                        selectedValue={selectedVolume}
                        label={FilterLabels.volume}
                        onChange={onVolumeChange}
                    />
                ) : (
                    <SkeletonLine />
                )}
            </FilterSelect>
        </FiltersWrapper>
    );
};
