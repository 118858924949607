import styled from "styled-components";

const CommonContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;

    & > svg {
        :first-child {
            z-index: 1;
            position: absolute;
        }
    }

    button {
        background-color: transparent;
        margin: 0 10px 0 2px;

        :hover {
            background-color: unset;
        }
    }
`;

export const SearchInputContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 32px;
`;

export const SmallContainer = styled(CommonContainer)`
    height: 24px;

    & > svg {
        :first-child {
            left: 8px;
        }
    }

    ${SearchInputContainer} {
        padding-left: 28px;
    }
`;
export const MediumContainer = styled(SmallContainer)`
    min-height: 32px;
`;

export const SearchInput = styled.input`
    min-width: 50px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    background-color: transparent;
`;
