import { FC, useMemo } from "react";
import { useAppSelector } from "~app/store";
import { getPeriodLabel } from "~components/filters";
import { CircleArrows } from "~components/icons";
import { TurnoverTableData } from "~components/table/utils";
import * as Styled from "~pages/analytics/styled";
import { ActionButton, TurnoverFilterButton } from "~pages/analytics/styled";
import { getSelectedTableItemsLabel } from "~pages/analytics/utils";

type AnalyticsFilterProps = {
    clients: TurnoverTableData[];
    instruments: TurnoverTableData[];
    selectedClients: Record<string, boolean>;
    selectedInstruments: Record<string, boolean>;
    onSelectAll?: () => void;
};

export const AnalyticsFilter: FC<AnalyticsFilterProps> = ({
    clients,
    instruments,
    selectedClients,
    selectedInstruments,
    onSelectAll,
}) => {
    const selectedPeriodKey = useAppSelector((state) => state.turnover.selectedPeriod);
    const periods = useAppSelector((state) => state.turnover.periods);

    const selectedPeriod = selectedPeriodKey && periods?.filtered[selectedPeriodKey];

    const periodLabel = useMemo(() => {
        return selectedPeriodKey ? getPeriodLabel(selectedPeriodKey) : "";
    }, [selectedPeriodKey]);

    const isFilterClientsShortcutActive = useMemo(() => {
        const selectedClientsCount = Object.values(selectedClients).filter((entity) => Boolean(entity)).length;

        return clients.length !== selectedClientsCount;
    }, [clients.length, selectedClients]);

    const isFilterInstrumentsShortcutActive = useMemo(() => {
        const selectedInstrumentsCount = Object.values(selectedInstruments).filter((entity) => Boolean(entity)).length;

        return instruments.length !== selectedInstrumentsCount;
    }, [instruments.length, selectedInstruments]);

    const isShowFilterShortCutContainer = isFilterClientsShortcutActive || isFilterInstrumentsShortcutActive;

    if (!isShowFilterShortCutContainer) {
        return null;
    }

    return (
        <Styled.FilterShortCutContainer>
            <Styled.FilterShortCut>
                <ActionButton onClick={onSelectAll}>
                    <CircleArrows />
                    Select All
                </ActionButton>
                {selectedPeriod && (
                    <TurnoverFilterButton>
                        {periodLabel.split(" ")[0]}
                        <strong>{periodLabel.split(" ")[1]}</strong>
                    </TurnoverFilterButton>
                )}
                {isFilterClientsShortcutActive && (
                    <TurnoverFilterButton>
                        Сounterparties
                        <strong>{getSelectedTableItemsLabel(clients, selectedClients)}</strong>
                    </TurnoverFilterButton>
                )}
                {isFilterInstrumentsShortcutActive && (
                    <TurnoverFilterButton>
                        Instruments
                        <strong>{getSelectedTableItemsLabel(instruments, selectedInstruments)}</strong>
                    </TurnoverFilterButton>
                )}
            </Styled.FilterShortCut>
        </Styled.FilterShortCutContainer>
    );
};
