import { forwardRef } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import styled, { css, useTheme } from "styled-components";
import { useId } from "../../hooks/useId";
import { HStack } from "../layout";
import { Switch } from "../Switch";
import { P, TextSmall } from "../typography";

export type SwitchFieldVariant = "basic" | "minimum" | "simple";

interface Props extends UseControllerProps<any> {
    label?: string;
    text: string;
    fullWidth?: boolean;
    variant: SwitchFieldVariant;
}

const SwitchContainer = styled.label`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    gap: 4px;
    background: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;

    ${P} {
        width: 70%;
    }
`;

const Container = styled.div<{ isDisabled?: boolean; fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};
    gap: 4px;

    ${(p) =>
        p.isDisabled &&
        css`
            ${TextSmall}, input, input::placeholder {
                color: ${p.theme.colors.ui20} !important;
            }
        `}
`;

export const SwitchField = forwardRef<HTMLDivElement, Props>(({ text, label, fullWidth, variant, ...restProps }, _) => {
    const { colors } = useTheme();
    const generatedId = useId("switch-field");

    const { field } = useController({
        ...restProps,
    });
    const { value, onChange } = field;

    return (
        <Container fullWidth={fullWidth}>
            {variant === "basic" && <TextSmall color={colors.ui72}>{label}</TextSmall>}
            <SwitchContainer data-field-group htmlFor={generatedId}>
                {variant === "basic" ? (
                    <P color={colors.ui72}>{text}</P>
                ) : (
                    <TextSmall color={colors.ui72}>{text}</TextSmall>
                )}
                <HStack spacing={12} alignItems="center">
                    {Boolean(variant !== "basic" && label) && <P>{label}</P>}
                    <Switch checked={value} onChange={onChange} id={generatedId} />
                </HStack>
            </SwitchContainer>
        </Container>
    );
});
