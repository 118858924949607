import { forwardRef, memo, useEffect, useImperativeHandle } from "react";
import { Row, useRowSelect, useSortBy, useTable } from "react-table";
import { useAppSelector } from "~app/store";
import { MembersState } from "~app/store/appSlice.types";
import * as Styled from "~components/table/style";
import { SkeletonLine } from "~style";
import { noop } from "~utils";
import { TableEye } from "./TableEye";
import { TableColumn, TurnoverTableData } from "./utils";

interface UITableProps {
    columns: TableColumn<TurnoverTableData>[];
    data: TurnoverTableData[];
    selectedRows: Record<string, boolean>;
    onCheckboxSelect: (selected: Record<string, boolean>) => void;
    onRowClick?: (row: Row<TurnoverTableData>, rows: Row<TurnoverTableData>[]) => void;
}

export interface UITableRef {
    resetSelectedRows?: () => void;
}

const SELECT_ROWS_ACTION = "SELECT_ROWS_ACTION";

const getSelectedRowIds = (data: TurnoverTableData[], activeMembers: Record<string, boolean>) =>
    data.reduce<Record<string, boolean>>((result, tableRow, index) => {
        result[index] = Boolean(activeMembers[tableRow.key]);
        return result;
    }, {});

const isLoadingColumn = (columnId: string) => {
    const exceptionColumn = ["Selection", "Name"].includes(columnId);

    return !exceptionColumn;
};

export const TurnoverTable = memo(
    forwardRef<UITableRef, UITableProps>(
        ({ columns, data, selectedRows, onCheckboxSelect = noop, onRowClick = noop }, ref) => {
            const isLoading = useAppSelector((state) => state.turnover.isLoading);

            const {
                dispatch: tableDispatch,
                rows,
                headerGroups,
                flatRows,
                selectedFlatRows,
                isAllRowsSelected,
                prepareRow,
                toggleRowSelected,
                toggleAllRowsSelected,
                getTableProps,
                state: { selectedRowIds },
            } = useTable(
                {
                    columns,
                    data,
                    autoResetSelectedRows: false,
                    disableResizing: true,
                    initialState: {
                        selectedRowIds: getSelectedRowIds(data, selectedRows),
                        sortBy: [{ id: "Amount", desc: true }, { id: "Name" }],
                    },
                    stateReducer: (newState, action, previousState) => {
                        if (action.type === SELECT_ROWS_ACTION) {
                            newState.selectedRowIds = getSelectedRowIds(data, action.payload);
                        }

                        return newState;
                    },
                    sortTypes: {
                        alphanumeric: (row1, row2, columnName, desc) => {
                            let rowOneColumn = row1.values?.[columnName];
                            let rowTwoColumn = row2.values?.[columnName];

                            if (desc) {
                                [rowOneColumn, rowTwoColumn] = [rowTwoColumn, rowOneColumn];
                            }

                            if (isNaN(rowOneColumn)) {
                                return rowOneColumn?.toUpperCase().localeCompare(rowTwoColumn?.toUpperCase());
                            }

                            return Number(rowTwoColumn) - Number(rowOneColumn);
                        },
                    },
                },
                useSortBy,
                useRowSelect,
                (hooks) => {
                    hooks.visibleColumns.push(
                        (columns) =>
                            [
                                ...columns,
                                {
                                    id: "Selection",
                                    Cell: ({ row, isAllRowsSelected }) => {
                                        const { checked, ...restProps } = row.getToggleRowSelectedProps();

                                        return (
                                            <Styled.TableCellWrapper>
                                                <TableEye
                                                    checked={isAllRowsSelected ? false : checked}
                                                    {...restProps}
                                                />
                                            </Styled.TableCellWrapper>
                                        );
                                    },
                                },
                            ] as typeof columns,
                    );
                },
            );

            const handleRowClick = (e: React.MouseEvent, row: Row<TurnoverTableData>) => {
                e.preventDefault();
                e.stopPropagation();

                onRowClick(row, rows);

                if (isAllRowsSelected) {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id);
                    return;
                }

                if (selectedFlatRows.length === 1 && selectedFlatRows[0].id === row.id) {
                    toggleAllRowsSelected(true);
                    return;
                }

                toggleRowSelected(row.id);
            };

            useImperativeHandle(
                ref,
                () => ({
                    resetSelectedRows() {
                        toggleAllRowsSelected(true);
                    },
                }),
                [toggleAllRowsSelected],
            );

            // Internal(table hook) selected rows have been changed:
            useEffect(() => {
                const hasNewSelection = flatRows.some((flatRow) => {
                    return flatRow.isSelected !== Boolean(selectedRows[flatRow.original.key]);
                });

                if (hasNewSelection) {
                    const activeMembers = flatRows.reduce<MembersState>((acc, selectedFlatRow) => {
                        acc[selectedFlatRow.original.key] = selectedFlatRow.isSelected;
                        return acc;
                    }, {});

                    onCheckboxSelect(activeMembers);
                }
            }, [selectedRowIds]);

            // External selected rows have been changed:
            useEffect(() => {
                const hasNewSelection = flatRows.some((flatRow) => {
                    return flatRow.isSelected !== Boolean(selectedRows[flatRow.original.key]);
                });

                if (hasNewSelection) {
                    tableDispatch({
                        type: SELECT_ROWS_ACTION,
                        payload: selectedRows,
                    });
                }
            }, [selectedRows]);

            return (
                <Styled.TurnoverTable {...getTableProps()}>
                    {headerGroups.map((headerGroup) => (
                        <Styled.TurnoverRow header {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Styled.TurnoverCell header {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </Styled.TurnoverCell>
                            ))}
                        </Styled.TurnoverRow>
                    ))}
                    {rows.map((row) => {
                        prepareRow(row);

                        return (
                            <Styled.TurnoverRow
                                onClick={(e) => handleRowClick(e, row)}
                                inactive={!row.isSelected}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <Styled.TurnoverCell
                                            inactive={!row.isSelected}
                                            position={cell.column.id === "Turnover" ? "static" : "relative"}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.row.isSelected && isLoading && isLoadingColumn(cell.column.id) ? (
                                                <SkeletonLine />
                                            ) : cell.row.isSelected || !isLoadingColumn(cell.column.id) ? (
                                                cell.render("Cell")
                                            ) : null}
                                        </Styled.TurnoverCell>
                                    );
                                })}
                            </Styled.TurnoverRow>
                        );
                    })}
                </Styled.TurnoverTable>
            );
        },
    ),
);
