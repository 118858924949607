import { selectors } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { onTablet } from "../const";

const Wrapper = styled.div`
    padding: 16px 20px;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;

    ${onTablet} {
        flex-direction: column-reverse;
    }
`;

const LinksWrapper = styled.div`
    min-width: 251px;
    display: flex;
    flex-direction: column;
    margin-left: 60px;

    ${onTablet} {
        max-width: 251px;
        margin: 0 auto;
        padding-top: 12px;
    }
`;

const Links = styled.div`
    display: flex;
    justify-content: space-between;

    a {
        ${selectors.mixinInterface2}

        color: #999;

        :hover {
            text-decoration: none;
            color: #000;
        }
    }
`;

const CopyR = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: rgba(36, 48, 52, 0.52);
    margin: 0;
    color: #999;
    text-align: right;

    ${onTablet} {
        text-align: center;
        padding-top: 16px;
    }
`;

export const Footer: React.FC = () => {
    return (
        <Wrapper>
            <CopyR>© 2019–{new Date().getFullYear()}, Finery Markets</CopyR>
            <LinksWrapper>
                <Links>
                    <a href="https://finerymarkets.com/">Product</a>
                    <a href="https://finerymarkets.com/about.html">About</a>
                    <a href="https://faq.finerymarkets.com/">FAQ</a>
                    <a href="https://finerymarkets.com/legal.html">Legal</a>
                    <a href="https://status.finerymarkets.com/">Status</a>
                </Links>
            </LinksWrapper>
        </Wrapper>
    );
};
