import { selectors } from "@fm-frontend/uikit";
import styled from "styled-components";
import { onHover } from "../../const";
import { SkeletonLine } from "../../style";

export const Container = styled.div<{ selected?: boolean }>`
    display: flex;
    flex-direction: column;

    min-width: 155px;
    min-height: 70px;
    padding: 8px;

    background: ${({ selected }) => (selected ? "rgba(22, 199, 176, 0.08)" : "#FFFFFF")};
    border-radius: 8px;

    cursor: pointer;

    ${onHover} {
        background-color: rgba(36, 48, 52, 0.04);
    }
`;

export const Top = styled.div<{ selected?: boolean; isUpTrend?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;

        :first-child {
            color: ${({ selected }) => (selected ? selectors.brandColor : "#243034")};
        }

        :last-child {
            color: ${({ isUpTrend }) =>
                isUpTrend ? "rgba(103, 208, 53, 1)" : "rgba(246, 60, 48, 1)"};
        }
    }

    ${SkeletonLine} {
        width: 50px;
    }
`;

export const Bottom = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 6px;

    span {
        font-weight: 400;
        font-size: 28px;
        line-height: 29px;
        letter-spacing: -0.005em;
        color: ${({ selected }) => (selected ? selectors.brandColor : "#243034")};
    }

    ${SkeletonLine} {
        height: 30px;
    }
`;
