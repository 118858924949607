import {
    Amounts,
    Exchange,
    ExchangeNames,
    SelectItem,
    SnapshotData,
    SnapshotDataUpdate,
    TableDataUpdate,
    UptimeData,
} from "../../types";

export enum ItemType {
    FM = "fm",
    LP = "lp",
    Exchange = "ex",
}

export interface Config {
    access_type: UserRoles;
    instruments: string[];
    sizes: Amounts;
    volumes: number[];
    exchanges: Exchange[];
    periods: string[];
    exchange_names: ExchangeNames;
}

export interface AppSlice {
    config: Config;
    resetAllData: boolean;
    resetChartData: boolean;
    membersState: {
        enabledMembers: MembersState;
        activeMembers: MembersState;
    };
    selectedInstrument?: string;
    selectedPeriod: string;
    selectedAmount?: number;
    selectedVolume?: number;
    periods: SelectItem[];
    chartUpdate?: SnapshotDataUpdate;
    tableUpdate?: TableDataUpdate;
    uptimeUpdate?: UptimeData;
    snapshot: SnapshotData[];
    authenticated: boolean;
    token?: string;
    colors: Record<string, string>;
    error: boolean;
}

export enum UserRoles {
    Public = "public",
    Admin = "admin",
    Maker = "lp",
    Taker = "taker",
}

export enum CompanyTypes {
    Maker = "maker",
    Taker = "taker",
    MasterCounterparties = "primeBrokerExternal",
    MasterSubaccounts = "primeBroker",
}

export type MembersState = Record<string, boolean>;
