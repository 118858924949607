import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../app/store";
import { selectAuthenticated } from "../app/store/appSlice";
import { onTablet } from "../const";
import { LinkButton } from "../style";
import { CrossIcon } from "./icons";

const Text = styled.div`
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.05em;

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        color: rgba(36, 48, 52, 0.72);
        margin: 0 0 10px 0;

        ${onTablet} {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    gap: 5px;

    ${onTablet} {
        max-width: 600px;
        margin: 0 auto;
        justify-content: space-between;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    right: -28px;
    top: 0;
    width: 24px;
    height: 24px;
    background: #cccccc;
    border-radius: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    ${onTablet} {
        display: none;
    }
`;

const Container = styled.section`
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    width: 303px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: none;
    position: fixed;
    left: 24px;
    bottom: 44px;

    :hover {
        ${CloseButton} {
            opacity: 1;
        }
    }

    ${onTablet} {
        width: 100vw;

        margin: 1rem -1rem 0 0;
        position: static;
        border-radius: 0;
        border: 0;

        ${Text} {
            text-align: center;
            padding-bottom: 0;
            padding-top: 10px;

            br {
                display: none;
            }
        }
    }
`;

const Title = styled.h2`
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: #243034;

    margin: 0 0 4px 0;

    ${onTablet} {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        color: #000000;
    }
`;

const ButtonWrapper = styled.div`
    width: 137px;

    ${LinkButton} {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.005em;
    }

    ${onTablet} {
        width: calc(50% - 4px);
    }
`;

export const Sidebar: React.FC = () => {
    const history = useHistory();
    const isAuthenticated = useAppSelector<boolean>(selectAuthenticated);
    const [isActive, setIsActive] = useState(true);

    const loginClick = () => {
        history.push("/login");
    };

    return (
        <>
            {!isAuthenticated && isActive && (
                <Container>
                    <Text>
                        <Title>Get access to Finest liquidity</Title>
                        <p>Analyse your transaction costs</p>
                        <LinkWrapper>
                            <ButtonWrapper>
                                <LinkButton onClick={loginClick}>Login</LinkButton>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <LinkButton
                                    light
                                    href="https://finerymarkets.com/contact.html"
                                    target="_blank"
                                >
                                    Contact Us
                                </LinkButton>
                            </ButtonWrapper>
                        </LinkWrapper>
                    </Text>
                    <CloseButton onClick={() => setIsActive(false)}>
                        <CrossIcon />
                    </CloseButton>
                </Container>
            )}
        </>
    );
};
