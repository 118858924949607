import { themes } from "@fm-frontend/uikit";
import "@fm-frontend/uikit/src/styled.d";
import { GA4Provider } from "@fm-frontend/utils";
import { Amplify } from "aws-amplify";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { Router } from "./app/router/Router";
import { ErrorModal } from "./components/ErrorModal";
import { GA_MEASUREMENT_ID, onTablet } from "./const";
import { awsExports } from "./const/aws-exports";
import { useIsActiveTracker } from "./hooks/useIsActiveTracker";
import { GlobalStyle } from "./style";

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
`;

export const Content = styled.div`
    width: 95%;
    min-height: calc(100vh - 120px);
    max-width: 1180px;
    display: flex;
    position: relative;
    margin: 0 auto;
    min-width: 320px;
    padding-bottom: 0;

    ${onTablet} {
        flex-direction: column;
    }
`;

Amplify.configure(awsExports);

const App = () => {
    const [theme] = useState(themes.light);
    useIsActiveTracker();

    return (
        <GA4Provider measurementId={GA_MEASUREMENT_ID}>
            <ThemeProvider theme={theme}>
                <AppContainer>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                    <ErrorModal />
                </AppContainer>
                <GlobalStyle />
            </ThemeProvider>
        </GA4Provider>
    );
};

export default App;
