import { H1 } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { onHover } from "~const";

const HEADER_BREAKPOINT = "@media (max-width:767px)";

export const Container = styled.section`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    align-items: flex-start;
    margin-bottom: 12px;

    ${HEADER_BREAKPOINT} {
        height: 94px;
    }
`;

export const LogoWrapper = styled.a`
    margin-top: -0.8px;
`;

export const navLinkLine = css`
    :before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${(p) => p.theme.colors.brand100};
        position: absolute;
        left: 0;
        bottom: -18px;
        z-index: 1;
    }
`;

export const TabTitle = styled(H1)<{ isActive?: boolean }>`
    position: relative;
    line-height: 24px;
    color: ${(p) => p.theme.colors.ui52};
    font-weight: 400 !important;
    cursor: pointer;
    margin: 0;

    ${onHover} {
        color: ${(p) => p.theme.colors.brand100};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            ${navLinkLine};
            color: ${(p) => p.theme.colors.ui100};
        `}

    ${HEADER_BREAKPOINT} {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.005em;
    }
`;

export const SettingsWrapper = styled.div`
    display: flex;
    width: fit-content;
    gap: 5px;
`;

export const TabContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: space-between;
    gap: 12px;

    ${HEADER_BREAKPOINT} {
        justify-content: center;
        gap: 111px;
        top: 52px;
        width: 100%;
    }
`;
