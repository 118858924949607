import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Content } from "~App";
import { useAppDispatch, useAppSelector } from "~app/store";
import { setIsLoading } from "~app/store/turnoverSlice";
import { BarChart } from "~components/barChart";
import { TurnoverFilter } from "~components/filters";
import {
    getChartColors,
    getChartData,
    getTotalTurnover,
    mapClientTableColumns,
    mapInstrumentTableColumns,
} from "~components/table/utils";
import { ChartColorsContext } from "~components/turnoverChart/Context";
import { UICard } from "~components/uiCard/UICard";
import { AnalyticsFilter } from "~pages/analytics/AnalyticsFilter";
import { AnalyticsTable } from "~pages/analytics/AnalyticsTable";
import * as Styled from "~pages/analytics/styled";
import { useTurnoverSelectedSync } from "~pages/analytics/useTurnoverSelectedSync";
import {
    fmtConfig,
    mapTurnoverInitTableData,
    mapTurnoverSelectedTableData,
    mapTurnoverTableData,
} from "~pages/analytics/utils";
import { noop } from "~utils";
import { fmt } from "~utils/format";

export const AnalyticsContent: FC = () => {
    const dispatch = useAppDispatch();
    const clientsTableRef = useRef({ resetSelectedRows: noop });
    const instrumentsTableRef = useRef({ resetSelectedRows: noop });

    const [userSelectedInstruments, setSelectedInstruments] = useState<Record<string, boolean>>();
    const [userSelectedClients, setSelectedClients] = useState<Record<string, boolean>>();

    const isLoading = useAppSelector((state) => state.turnover.isLoading);
    const config = useAppSelector((state) => state.turnover.config);
    const selectedPeriodKey = useAppSelector((state) => state.turnover.selectedPeriod);
    const periods = useAppSelector((state) => state.turnover.periods);
    const userRole = useAppSelector((state) => state.app.config.access_type);

    const selectedPeriod = selectedPeriodKey && periods?.filtered[selectedPeriodKey];

    const totalTurnover = useMemo(
        () => getTotalTurnover(selectedPeriod?.turnover_by.client),
        [selectedPeriod?.turnover_by.client],
    );
    const turnoverClientTableColumns = useMemo(
        () => mapClientTableColumns(userRole, config?.company_type),
        [userRole, config?.company_type],
    );
    const turnoverInstrumentTableColumns = useMemo(
        () => mapInstrumentTableColumns(userRole, config?.company_type),
        [userRole, config?.company_type],
    );

    const chartData = useMemo(
        () => getChartData(selectedPeriod?.turnover_by.time_clients, config?.client_names),
        [config?.client_names, selectedPeriod?.turnover_by.time_clients],
    );
    const chartColors = useMemo(
        () => getChartColors(selectedPeriod?.turnover_by.client),
        [selectedPeriod?.turnover_by.client],
    );

    const initSelectedClients = useMemo(() => config && mapTurnoverSelectedTableData(config.client), [config]);
    const selectedClients = useMemo(
        () => userSelectedClients ?? initSelectedClients ?? {},
        [initSelectedClients, userSelectedClients],
    );
    const initSelectedInstruments = useMemo(() => config && mapTurnoverSelectedTableData(config.instrument), [config]);
    const selectedInstruments = useMemo(
        () => userSelectedInstruments ?? initSelectedInstruments ?? {},
        [initSelectedInstruments, userSelectedInstruments],
    );

    const initClients = useMemo(() => {
        if (config === undefined) {
            return [];
        }

        return mapTurnoverInitTableData(config.client, config.company_type, config.client_names);
    }, [config]);
    const clientsData = useMemo(() => {
        if (selectedPeriodKey === undefined || periods === undefined || selectedPeriod === undefined) {
            return initClients;
        }

        return mapTurnoverTableData(
            initClients,
            periods.unfilteredByClients[selectedPeriodKey].turnover_by.client,
            totalTurnover,
            selectedPeriod.pocket_share,
        );
    }, [initClients, selectedPeriod, periods, selectedPeriodKey, totalTurnover]);

    const initInstruments = useMemo(() => {
        if (config === undefined) {
            return [];
        }

        return mapTurnoverInitTableData(config.instrument, config.company_type);
    }, [config]);
    const instrumentsData = useMemo(() => {
        if (selectedPeriodKey === undefined || periods === undefined || selectedPeriod === undefined) {
            return initInstruments;
        }

        return mapTurnoverTableData(
            initInstruments,
            periods.unfilteredByInstruments[selectedPeriodKey].turnover_by.instrument,
            totalTurnover,
            selectedPeriod.instrument_pocket_share,
        );
    }, [initInstruments, selectedPeriod, periods, selectedPeriodKey, totalTurnover]);

    const clientsTableHeader = Object.values(selectedClients).some((isSelected) => !isSelected)
        ? "Selected counterparties"
        : "Counterparties";

    const instrumentsTableHeader = Object.values(selectedInstruments).some((isSelected) => !isSelected)
        ? "Selected instruments"
        : "Instruments";

    const resetSelectedClients = useCallback(() => {
        dispatch(setIsLoading(true));
        clientsTableRef.current.resetSelectedRows();
    }, [dispatch]);
    const resetSelectedInstruments = useCallback(() => {
        dispatch(setIsLoading(true));
        instrumentsTableRef.current.resetSelectedRows();
    }, [dispatch]);
    const resetFilterSelection = useCallback(() => {
        resetSelectedClients();
        resetSelectedInstruments();
    }, [resetSelectedClients, resetSelectedInstruments]);

    useTurnoverSelectedSync(selectedClients, selectedInstruments);

    return (
        <>
            <AnalyticsFilter
                clients={clientsData}
                instruments={instrumentsData}
                selectedClients={selectedClients}
                selectedInstruments={selectedInstruments}
                onSelectAll={resetFilterSelection}
            />
            <Content>
                <Styled.TurnoverContainer>
                    <UICard>
                        <TurnoverFilter />
                    </UICard>
                    <UICard
                        headerText={{
                            main: "Total",
                            middle: `$${fmt(isLoading ? 0 : totalTurnover, fmtConfig).formattedValue}`,
                        }}
                    >
                        <BarChart data={chartData} colors={chartColors} />
                    </UICard>
                    <Styled.TablesContainer>
                        <ChartColorsContext.Provider value={chartColors}>
                            <AnalyticsTable
                                ref={clientsTableRef}
                                title={clientsTableHeader}
                                data={clientsData}
                                columns={turnoverClientTableColumns}
                                selected={selectedClients}
                                onSelected={setSelectedClients}
                                onClearClick={resetSelectedClients}
                            />
                        </ChartColorsContext.Provider>
                        <AnalyticsTable
                            ref={instrumentsTableRef}
                            title={instrumentsTableHeader}
                            data={instrumentsData}
                            columns={turnoverInstrumentTableColumns}
                            selected={selectedInstruments}
                            onSelected={setSelectedInstruments}
                            onClearClick={resetSelectedInstruments}
                        />
                    </Styled.TablesContainer>
                </Styled.TurnoverContainer>
            </Content>
        </>
    );
};
