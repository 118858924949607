import { selectors } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    padding: 12px;

    background: #ffffff;
    box-shadow: 0 1px 2px rgba(36, 48, 52, 0.04), inset 0 0 0 1px rgba(36, 48, 52, 0.12);
    border-radius: 12px;
`;

export const ColoredText = styled.span`
    color: ${selectors.brandColor};
`;

export const Header = styled.span`
    padding-bottom: 16px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #243034;

    :last-of-type {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(36, 48, 52, 0.72);
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;

    gap: 8px;
`;
