import { selectors } from "@fm-frontend/uikit";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../app/store";
import { selectPeriods, selectSelectedPeriod, updatePeriod } from "../app/store/appSlice";
import { SkeletonLine } from "../style";

const Wrapper = styled.div`
    display: flex;
`;

const SwitchButton = styled.button<{ isActive?: boolean }>`
    ${selectors.mixinInterface2}

    padding: 3px;
    color: ${(data) => (data.isActive ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)")};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 30px;
    background: rgba(0, 0, 0, 0.05);

    :first-child {
        border-radius: 8px 0 0 8px;
        margin-right: 1px;
    }

    :last-child {
        border-radius: 0 8px 8px 0;
        margin-left: 1px;
    }

    :hover {
        background: #ebebeb;
        cursor: pointer;
    }
`;

const SkeletonWrapper = styled.div`
    padding-left: 1rem;
    display: flex;
    align-items: flex-start;
    width: 100px;
`;

export const SwitchButtonGroup: React.FC = () => {
    const dispatch = useAppDispatch();
    const periods = useAppSelector(selectPeriods);
    const selectedPeriod = useAppSelector(selectSelectedPeriod);

    const onPeriodSwitch = useCallback((value: string) => {
        value && dispatch(updatePeriod(value));
    }, []);

    return (
        <Wrapper>
            {periods?.length ? (
                periods.map((period, index) => (
                    <SwitchButton
                        onClick={() => onPeriodSwitch(period.value)}
                        key={index}
                        isActive={selectedPeriod === period.value}
                    >
                        <span>{period.label}</span>
                    </SwitchButton>
                ))
            ) : (
                <SkeletonWrapper>
                    <SkeletonLine />
                </SkeletonWrapper>
            )}
        </Wrapper>
    );
};
