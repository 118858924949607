import React, { useEffect } from "react";
import styled from "styled-components";
import { Content } from "../../App";
import { useAppDispatch } from "../../app/store";
import { restartConnecting } from "../../app/store/socketSlice";
import { Card, CardHeader, CardTitle, ChartCard, TableCard } from "../../components/Card";
import { ChartTitle } from "../../components/ChartTitle";
import { SpreadsFilter } from "../../components/filters";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/header/Header";
import { LinerChart } from "../../components/linerChart/LinerChart";
import { Sidebar } from "../../components/Sidebar";
import { SwitchButtonGroup } from "../../components/SwitchButtonGroup";
import { Table } from "../../components/table/Table";
import { TableSubTitle, TableTitle } from "../../components/TableTitle";
import { DISCLAIMER } from "../../const";
import { Cards } from "../../style";

const Disclaimer = styled.div`
    width: 50%;

    p {
        text-align: left;
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.005em;
        color: rgba(36, 48, 52, 0.52);
    }
`;

const DisclaimerCard = styled(Card)`
    display: flex;
    flex-direction: column;
    background: rgba(36, 48, 52, 0.04);

    padding-bottom: 12px;
    box-shadow: none;
`;

const DisclaimerWrapper = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;
`;

const DisclaimerCardTitle = styled.span`
    padding: 0;
    margin: 0 0 4px 0;

    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(36, 48, 52, 0.72);
`;

export const Spreads: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(restartConnecting());
    }, []);

    return (
        <>
            <Header />
            <Content>
                <SpreadsFilter />
                <Cards>
                    <TableCard>
                        <CardHeader>
                            <CardTitle>
                                <TableTitle />
                            </CardTitle>
                            <TableSubTitle />
                        </CardHeader>
                        <Table />
                    </TableCard>
                    <ChartCard>
                        <CardHeader>
                            <CardTitle>
                                <ChartTitle />
                            </CardTitle>
                            <SwitchButtonGroup />
                        </CardHeader>
                        <LinerChart />
                    </ChartCard>
                    <DisclaimerCard>
                        <DisclaimerCardTitle>Disclaimer</DisclaimerCardTitle>
                        <DisclaimerWrapper>
                            <Disclaimer>
                                <p>{DISCLAIMER[0]}</p>
                            </Disclaimer>
                            <Disclaimer>
                                <p>{DISCLAIMER[1]}</p>
                            </Disclaimer>
                        </DisclaimerWrapper>
                    </DisclaimerCard>
                </Cards>
            </Content>
            <Sidebar />
            <Footer />
        </>
    );
};
