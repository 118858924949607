import React, { ReactNode } from "react";
import { Spacer } from "~style";
import { ColoredText, Container, Header, HeaderContainer } from "./style";

interface UICardProps {
    children: ReactNode;
    headerText?: {
        main?: string;
        middle?: string | number;
        sub?: string;
        right?: ReactNode;
    };
}

export const UICard: React.FC<UICardProps> = ({ children, headerText }) => (
    <Container>
        {headerText && (
            <HeaderContainer>
                <Header>
                    <span>
                        {headerText.main} <ColoredText>{headerText.middle}</ColoredText>
                    </span>
                </Header>
                <Header>
                    <span>{headerText.sub}</span>
                </Header>
                <Spacer />
                {headerText.right}
            </HeaderContainer>
        )}
        {children}
    </Container>
);
