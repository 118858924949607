import { CompanyTypes } from "./appSlice.types";

export type PocketShare = [number, number, number | null];
export type TurnoverBy = [number, number, number];
export type TurnoverByTime = [timestamp: number, turnover: number];
export type TurnoverByTimeClients = [[timestamp: number, clientId: number], [turnover: number, percent: number]];
type TurnoverType = [number, number];

export enum AnalyticsPeriods {
    "24h" = "24h",
    Today = "today",
    "7d" = "7d",
    "30d" = "30d",
    Month = "month",
}

export interface TurnoverPeriod {
    pocket_share: PocketShare[];
    instrument_pocket_share: PocketShare[];
    turnover: TurnoverType;
    turnover_by: {
        client: TurnoverBy[];
        instrument: TurnoverBy[];
        time: TurnoverByTime[];
        time_clients: TurnoverByTimeClients[];
    };
}

export type MasterConfig = {
    counterpartiesConfig: TurnoverConfig;
    subaccountsConfig: TurnoverConfig;
};
export type ClientConfig = TurnoverConfig | MasterConfig;

export type Periods = Record<AnalyticsPeriods, TurnoverPeriod>;

export type TurnoverSlice = Partial<{
    config: TurnoverConfig;
    adminConfig: ClientConfig[];
    masterConfig: MasterConfig;
    periods: {
        filtered: Periods;
        unfilteredByClients: Periods;
        unfilteredByInstruments: Periods;
    };
    selectedPeriod: AnalyticsPeriods;
    isLoading: boolean;
}>;

export interface TurnoverConfig {
    company_id: number;
    company_name: string;
    company_type: CompanyTypes;
    client: number[];
    client_names: Record<number, string>;
    instrument: string[];
}

export interface TurnoverFilter {
    client: number[];
    instrument: string[];
    company_id: number;
    company_type?: CompanyTypes;
}
