import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "~app/store";
import { setSelectedPeriod } from "~app/store/turnoverSlice";
import { AnalyticsPeriods } from "~app/store/turnoverSlice.types";
import { Container, Periods, Title } from "~components/filters/style";
import { MasterSwitcher } from "~components/filters/TurnoverFilter/MasterSwitcher";
import { getPeriodLabel, isUpTrend } from "~components/filters/utils";
import { formatAsPercent, getNumberList } from "~components/table/utils";
import { TurnoverPeriod, TurnoverPeriodSkeleton } from "~components/turnoverPeriod/TurnoverPeriod";
import { getUserName } from "~pages/analytics/utils";
import { SkeletonLine } from "~style";
import { turnoverPeriodNumberFormat } from "~utils";

interface TurnoverSelect {
    id: AnalyticsPeriods;
    period: string;
    percent: string;
    isUpTrend: boolean;
    number: string;
}

const StyledSkeletonLine = styled(SkeletonLine)`
    height: 24px;
    margin-bottom: 4px;
`;

export const TurnoverFilter: React.VFC = () => {
    const dispatch = useAppDispatch();

    const periods = useAppSelector((state) => state.turnover.periods);
    const selectedPeriod = useAppSelector((state) => state.turnover.selectedPeriod);
    const companyName = useAppSelector((state) => state.turnover.config?.company_name);
    const isLoading = useAppSelector((state) => state.turnover.isLoading);

    const isSelected = (select: TurnoverSelect) => {
        if (!selectedPeriod) {
            return false;
        }

        return select.id === selectedPeriod;
    };

    const setPeriod = (period: AnalyticsPeriods) => {
        dispatch(setSelectedPeriod(period));
    };

    const selects = useMemo(() => {
        if (!periods) {
            return [];
        }

        const periodKeys = Object.keys(periods.filtered) as AnalyticsPeriods[];

        return periodKeys.reduce<TurnoverSelect[]>((acc, key) => {
            const period = periods.filtered[key];
            const [amount, percent] = period.turnover;
            acc.push({
                id: key,
                period: getPeriodLabel(key),
                percent: formatAsPercent(percent),
                isUpTrend: isUpTrend(percent),
                number: turnoverPeriodNumberFormat(amount),
            });
            return acc;
        }, []);
    }, [periods]);

    useEffect(() => {
        !selectedPeriod && dispatch(setSelectedPeriod(selects?.[0]?.id));
    }, [selectedPeriod, selects]);

    const getPeriodsItems = () => {
        return selects.map((select) => (
            <TurnoverPeriod
                onClick={() => setPeriod(select.id)}
                key={select.id}
                isSelected={isSelected(select)}
                percent={select.percent}
                number={select.number}
                isUpTrend={select.isUpTrend}
                period={select.period}
            />
        ));
    };

    const getPeriodSkeletonItems = () => {
        return getNumberList(5).map((value) => <TurnoverPeriodSkeleton key={value} />);
    };

    return (
        <Container>
            <Title>
                {companyName ? (
                    <h2>{getUserName(companyName, `${companyName}'s`)} turnover</h2>
                ) : (
                    <StyledSkeletonLine />
                )}
                <MasterSwitcher />
            </Title>
            <Periods>{Boolean(isLoading) ? getPeriodSkeletonItems() : getPeriodsItems()}</Periods>
        </Container>
    );
};
