import { getEnv } from "@fm-frontend/utils";

export const GA_MEASUREMENT_ID = getEnv().REACT_APP_GA_MEASUREMENT_ID;
export const mobileQuery = "max-width: 640px";
export const tabletQuery = "max-width: 990px";
export const onMobile = `@media (${mobileQuery})`;
export const onTablet = `@media (${tabletQuery})`;
export const onHover = `&:hover, &:active, &:focus, &:focus-within, &:focus-visible, &[aria-expanded=true]`;
export const DISCLAIMER = [
    'Price data and applicable trading fees may be delayed by price sources, software errors, or internet disruptions. Data is provided "as is" for informational purposes only, and is not intended as financial, investment, tax, legal, or accounting advice.',
    "Finery Markets is not an investment adviser, financial adviser or securities broker. Copying, disseminating and any other use of this website or data is not permitted unless expressly authorized.",
];
export const HIDE_SCROLLBAR = `
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const COLORS: Record<string, string> = {
    b2c2: "#808080",
    binance: "#FB9542",
    bitstamp: "#00C543",
    coinbase: "#3662FF",
    efx: "#33CEBA",
    ftx: "#E324E7",
    kraken: "#8E35FF",

    efx_lp_1: "#FEE14B",
    efx_lp_5: "#8AD1FF",
    efx_lp_10: "#AB003E",
    efx_lp_34: "#F35943",
    efx_lp_35: "#000575",
    efx_lp_66: "#008476",

    white: "#FFFFFF",
};

export const LpKey = "_lp_";

export const CACHED_MEMBERS_STATE = "cachedMembersState";

export const ONE_THOUSAND = 1e3;
export const ONE_MILLION = 1e6;
export const ONE_BILLION = 1e9;

export const NO_DATA_AVAILABLE = "Sorry, no data is available for your selection";
