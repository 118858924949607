import { when } from "@fm-frontend/utils";
import React from "react";
import { useHistory } from "react-router-dom";
import { routerPaths } from "~app/router/Router";
import { useAppSelector } from "~app/store";
import { selectAuthenticated } from "~app/store/appSlice";
import { AdminClientSearch } from "~components/AdminClientSearch";
import { IconLogo } from "~components/icons";
import { Logout } from "~components/Logout";
import { useIsActivePath } from "~hooks/useIsActivePath";
import * as Styled from "./styled";

export const Header: React.FC = () => {
    const history = useHistory();
    const isAuthenticated = useAppSelector<boolean>(selectAuthenticated);
    const { isActivePath } = useIsActivePath();

    return (
        <Styled.Container>
            <Styled.LogoWrapper href="/" className="logo">
                <IconLogo />
            </Styled.LogoWrapper>
            <Styled.TabContainer>
                {isAuthenticated && (
                    <Styled.TabTitle
                        isActive={isActivePath(routerPaths.analytics.path)}
                        onClick={() => history.push(routerPaths.analytics.path)}
                    >
                        Analytics
                    </Styled.TabTitle>
                )}
                <Styled.TabTitle
                    isActive={isActivePath(routerPaths.spreads.path)}
                    onClick={() => history.push(routerPaths.spreads.path)}
                >
                    Spreads
                </Styled.TabTitle>
            </Styled.TabContainer>
            <Styled.SettingsWrapper>
                {when(
                    isAuthenticated,
                    <>
                        <AdminClientSearch />
                        <Logout />
                    </>,
                )}
            </Styled.SettingsWrapper>
        </Styled.Container>
    );
};
