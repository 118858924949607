import React from "react";
import { SkeletonLine } from "~style";
import { TableSkeletonWrapper, TurnoverCell, TurnoverRow, TurnoverTable } from "./style";
import { getNumberList } from "./utils";

export const TurnoverSkeletonTable: React.VFC<{
    columnCount: number;
    rowCount: number;
}> = ({ columnCount, rowCount }) => (
    <TurnoverTable>
        {getNumberList(rowCount).map((value) => (
            <TurnoverRow key={value}>
                {getNumberList(columnCount).map((value) => (
                    <TurnoverCell key={value}>
                        <TableSkeletonWrapper>
                            <SkeletonLine />
                        </TableSkeletonWrapper>
                    </TurnoverCell>
                ))}
            </TurnoverRow>
        ))}
    </TurnoverTable>
);
