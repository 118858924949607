import { selectors } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Card = styled.div`
    background: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px 12px 0 12px;
    margin-bottom: 12px;
    min-width: 100%;
    overflow: hidden;
`;

export const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const CardTitle = styled.h2`
  ${selectors.mixinHeader2}

  margin: 0;
  color: #000;
  padding-bottom: 8px;

  br {
    display: none;
  }

  span {
    ${selectors.mixinInterface1}
    color: #999;
    margin-left: 8px;

  i {
    ${selectors.mixinInterface1}
    color: #999;
    margin-left: 8px;
  }
`;

export const SubTitle = styled.span`
    ${selectors.mixinBody3}

    margin-left: 8px;

    b {
        font-weight: 600;
    }
`;

export const SkeletonTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`;

export const ChartCard = styled(Card)`
    padding-bottom: 12px;
`;

export const TableCard = styled(Card)`
    padding-bottom: 0;
`;
