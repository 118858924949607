import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "~app/store";
import { CompanyTypes } from "~app/store/appSlice.types";
import { submitTurnoverFilter } from "~app/store/turnoverSlice";
import { TurnoverFilter as TurnoverFilterType } from "~app/store/turnoverSlice.types";

const COMBINE_SELECTED_REQUEST_DELAY = 600;

export const useTurnoverSelectedSync = (
    selectedClients: Record<string, boolean>,
    selectedInstruments: Record<string, boolean>,
) => {
    const dispatch = useAppDispatch();
    const config = useAppSelector((state) => state.turnover.config);

    const sendTurnoverFilter = () => {
        if (!config) {
            return;
        }

        const instruments = Object.entries(selectedInstruments)
            .filter(([, isSelected]) => isSelected)
            .map(([key]) => key);
        const clients = Object.entries(selectedClients)
            .filter(([, isSelected]) => isSelected)
            .map(([key]) => Number(key));

        const turnoverFilter: TurnoverFilterType = {
            instrument: instruments,
            client: clients,
            company_id: config.company_id,
        };
        if (
            config.company_type === CompanyTypes.MasterCounterparties ||
            config.company_type === CompanyTypes.MasterSubaccounts
        ) {
            turnoverFilter.company_type = config.company_type;
        }
        dispatch(submitTurnoverFilter(turnoverFilter));
    };

    useEffect(() => {
        const timeoutId = setTimeout(sendTurnoverFilter, COMBINE_SELECTED_REQUEST_DELAY);
        return () => clearTimeout(timeoutId);
    }, [selectedClients, selectedInstruments]);
};
