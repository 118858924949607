import { ClientId, ELLIPSIS_CSS } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { CompanyTypes, ItemType, MembersState, UserRoles } from "~app/store/appSlice.types";
import { TurnoverBy, TurnoverByTimeClients } from "~app/store/turnoverSlice.types";
import { LEAD_CHART_COLORS, REST_CHART_COLOR } from "~components/barChart/utils";
import { DownTrendIcon, UpTrendIcon } from "~components/icons";
import { TurnoverChart } from "~components/turnoverChart/TurnoverChart";
import { mapBarChartData } from "~pages/analytics/utils";
import { Hint } from "~style";
import { Exchange, Fees } from "~types";
import { formatNumber } from "~utils";
import {
    AmountCellWrapper,
    HintSign,
    ItemCount,
    Progress,
    ProgressContainer,
    ProgressLine,
    ProgressValue,
    SpreadWrapper,
    TableCellWrapper,
    TableItemIcon,
    TurnoverTableClientId,
    TurnoverTableName,
} from "./style";
import { TableData } from "./Table";

const SpreadCell = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: right;
    width: 160px;
`;

const SpreadPercent = styled.div`
    ${(p) => p.theme.mixins.p};
    flex: 0.6;
    padding-left: 8px;
`;
const SpreadValue = styled.div<{ $active: boolean }>`
    ${(p) => p.theme.mixins.pSmall};
    flex: 1;
    opacity: 0.52;

    ${ELLIPSIS_CSS}
`;

const NAME_COLUMN_ID = "Name";

export type TableColumn<Data> = {
    id: string;
    Header: string | React.FC<any>;
    accessor: (data: Data) => string | number | undefined;
    Cell?: React.FC<any>;
};

export interface TurnoverTableData {
    key: string;
    name?: string;
    amount: number;
    amountFormatted?: string;
    amountTrend?: boolean;
    pocketShare?: number;
    pocketShareFormatted?: string;
    turnover?: number;
    turnoverFormatted?: string;
    companyType?: CompanyTypes;
}

const uptimeColumn: TableColumn<TableData> = {
    id: "Uptime",
    Header: "Uptime",
    accessor: (data: TableData) => data.uptime,
};

const defaultTableColumns: TableColumn<TableData>[] = [
    {
        id: NAME_COLUMN_ID,
        Header: "Name",
        accessor: (data: TableData) => data.name,
        Cell: ({ row, value }) => {
            return (
                <TableCellWrapper>
                    {value}
                    {row.original.imgUrl && (
                        <TableItemIcon
                            opacity={row.isSelected ? 1 : 0.35}
                            src={`img/${row.original.key}.png`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "img/default.png";
                            }}
                        />
                    )}
                </TableCellWrapper>
            );
        },
    },
    {
        id: "Fee",
        Header: "Taker fee",
        accessor: (data: TableData) => data.fee,
    },
    {
        id: "Bid",
        Header: "Bid",
        accessor: (data: TableData) => data.bid,
    },
    {
        id: "Ask",
        Header: "Ask",
        accessor: (data: TableData) => data.ask,
    },
    {
        id: "Spread",
        Header: () => {
            return (
                <SpreadWrapper>
                    <span>Eff. spread</span>
                    <HintSign>*</HintSign>
                    <Hint>Trading fee is included in the calculation of the effective spread</Hint>
                </SpreadWrapper>
            );
        },
        accessor: (data: TableData) => data.spreadInPercents,
        Cell: ({ row }) => {
            const { isSelected } = row;
            const { spreadInPercents, spreadInCurrency } = row.original;

            return (
                <SpreadCell>
                    <SpreadValue $active={!isSelected}>{spreadInCurrency}</SpreadValue>
                    <SpreadPercent>{spreadInPercents}</SpreadPercent>
                </SpreadCell>
            );
        },
    },
];

export const formatAsPercent = (value?: number | string | null, fixed: number = 2): string => {
    return value === undefined
        ? ""
        : value === null
        ? "—"
        : value === 0
        ? `${value}%`
        : `${(Number(formatNumber(Number(value))) * 100).toFixed(fixed)}%`;
};

export const formatAsNumber = (value?: number | string | null): string => {
    return value === undefined ? "" : value === null ? "—" : value === 0 ? `${value}` : formatNumber(Number(value));
};

export const getAppliedFee = (item: Exchange, volume?: number, instrument?: string): number | null => {
    if (!instrument) {
        return null;
    }

    const instrumentValue = item?.special_instruments?.[instrument];

    if (instrumentValue) {
        const fees = item?.special_fees?.[instrumentValue];
        return getFormattedFee(fees, volume);
    }

    return getFormattedFee(item?.fees, volume);
};

export const getFormattedFee = (fees: Fees, volume?: number): number | null => {
    if (!fees || !volume) {
        return null;
    }

    return fees[volume];
};

export const getAvailableColumns = (isAuthenticated: boolean): TableColumn<TableData>[] => {
    const columns: TableColumn<TableData>[] = defaultTableColumns;
    const isUptimeColumnExist = columns.find((column) => column.id === "Uptime");

    if (isAuthenticated && !isUptimeColumnExist) {
        columns.splice(1, 0, uptimeColumn);
    }
    if (!isAuthenticated && isUptimeColumnExist) {
        columns.splice(1, 1);
    }

    return columns;
};

export const mapHeaderColumns = (type: ItemType, columns: TableColumn<TableData>[]): TableColumn<TableData>[] => {
    if (type === ItemType.FM) {
        return columns.map((column) => {
            if (column.id === NAME_COLUMN_ID) {
                return {
                    ...column,
                    Header: ({ rows }: any) => {
                        return (
                            <TableCellWrapper>
                                Marketplace
                                {getRowsCountElement(rows?.length)}
                            </TableCellWrapper>
                        );
                    },
                };
            }

            return column;
        });
    }
    if (type === ItemType.LP) {
        return columns.map((column) => {
            if (column.id === NAME_COLUMN_ID) {
                return {
                    ...column,
                    Header: ({ rows }: any) => {
                        return (
                            <TableCellWrapper>
                                Liquidity providers
                                {getRowsCountElement(rows?.length)}
                            </TableCellWrapper>
                        );
                    },
                };
            }

            return {
                ...column,
                Header: "",
            };
        });
    }
    if (type === ItemType.Exchange) {
        return columns.map((column) => {
            if (column.id === NAME_COLUMN_ID) {
                return {
                    ...column,
                    Header: ({ rows }: any) => {
                        return (
                            <TableCellWrapper>
                                Exchanges
                                {getRowsCountElement(rows?.length)}
                            </TableCellWrapper>
                        );
                    },
                };
            }

            return {
                ...column,
                Header: "",
            };
        });
    }

    return [];
};

const getRowsCountElement = (count: number) => {
    return count > 1 && <ItemCount>{count}</ItemCount>;
};

export const mapSelectedRows = (memoData: TableData[], activeMembers: MembersState) => {
    const selectedRows: Record<string, boolean> = memoData.reduce((result, tableRow, index) => {
        const isActive = Boolean(activeMembers[tableRow.key]);

        if (isActive) {
            result[index] = isActive;
        }

        return result;
    }, {} as Record<string, boolean>);

    return selectedRows;
};

const amountColumn: TableColumn<TurnoverTableData> = {
    id: "Amount",
    Header: "Amount",
    accessor: (data: TurnoverTableData) => data.amount,
    Cell: ({ row }) =>
        Boolean(row.original.amountFormatted) ? (
            <AmountCellWrapper $withIcon={row.original.amountTrend !== undefined}>
                <span>${row.original.amountFormatted}</span>
                {row.original.amountTrend === undefined ? null : Boolean(row.original.amountTrend) ? (
                    <UpTrendIcon />
                ) : (
                    <DownTrendIcon />
                )}
            </AmountCellWrapper>
        ) : null,
};

const PocketShareColumn: TableColumn<TurnoverTableData> = {
    id: "Pocket share",
    Header: "Pocket share",
    accessor: (data: TurnoverTableData) => data.pocketShareFormatted,
    Cell: ({ row }) => (
        <TableCellWrapper $justify="center">
            <ProgressContainer>
                <ProgressValue pale={row.original.pocketShare === 100}>
                    {row.original.pocketShareFormatted}
                </ProgressValue>
                <Progress>
                    <ProgressLine percent={Number(row.original.pocketShare)} color="gray" />
                </Progress>
            </ProgressContainer>
        </TableCellWrapper>
    ),
};

const MarketShareColumn: TableColumn<TurnoverTableData> = {
    id: "Market share",
    Header: "Market share",
    accessor: (data: TurnoverTableData) => data.pocketShareFormatted,
    Cell: ({ row }) => (
        <TableCellWrapper $justify="center">
            <ProgressContainer>
                <ProgressValue pale={row.original.pocketShare === 100}>
                    {row.original.pocketShareFormatted}
                </ProgressValue>
                <Progress>
                    <ProgressLine percent={Number(row.original.pocketShare)} color="gray" />
                </Progress>
            </ProgressContainer>
        </TableCellWrapper>
    ),
};

export const defaultTurnoverClientTableColumns: TableColumn<TurnoverTableData>[] = [
    {
        id: NAME_COLUMN_ID,
        Header: "Name",
        accessor: (data: TurnoverTableData) => data.name,
        Cell: ({ row, value }) => {
            return (
                <TableCellWrapper $absolute>
                    <TurnoverTableName>{value}</TurnoverTableName>
                    <TurnoverTableClientId>
                        <ClientId id={row.original.key} />
                    </TurnoverTableClientId>
                    {row.original.imgUrl && (
                        <TableItemIcon
                            opacity={row.isSelected ? 1 : 0.35}
                            src={`img/${row.original.key}.png`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "img/default.png";
                            }}
                        />
                    )}
                </TableCellWrapper>
            );
        },
    },
    {
        id: "Turnover",
        Header: "Turnover",
        accessor: (data: TurnoverTableData) => data.turnoverFormatted,
        Cell: ({ row, value }) => {
            const { key, turnover } = row.original as TurnoverTableData;

            return (
                <TableCellWrapper $justify="right">
                    <TurnoverChart clientId={Number(key)} percent={turnover ?? 0} />
                    {value}
                </TableCellWrapper>
            );
        },
    },
    amountColumn,
];

export const mapClientTableColumns = (userRole: UserRoles, companyType?: CompanyTypes) => {
    if (
        userRole === UserRoles.Maker ||
        companyType === CompanyTypes.Maker ||
        companyType === CompanyTypes.MasterCounterparties
    ) {
        return [...defaultTurnoverClientTableColumns, PocketShareColumn];
    }

    return defaultTurnoverClientTableColumns;
};

export const mapInstrumentTableColumns = (userRole: UserRoles, companyType?: CompanyTypes) => {
    if (
        userRole === UserRoles.Maker ||
        companyType === CompanyTypes.Maker ||
        companyType === CompanyTypes.MasterCounterparties
    ) {
        return [...defaultTurnoverInstrumentsTableColumns, MarketShareColumn];
    }

    return defaultTurnoverInstrumentsTableColumns;
};

export const defaultTurnoverInstrumentsTableColumns: TableColumn<TurnoverTableData>[] = [
    {
        id: NAME_COLUMN_ID,
        Header: "Name",
        accessor: (data: TurnoverTableData) => data.name,
        Cell: ({ row, value }) => {
            return (
                <TableCellWrapper>
                    {value}
                    {row.original.imgUrl && (
                        <TableItemIcon
                            opacity={row.isSelected ? 1 : 0.35}
                            src={`img/${row.original.key}.png`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "img/default.png";
                            }}
                        />
                    )}
                </TableCellWrapper>
            );
        },
    },
    {
        id: "Turnover",
        Header: "Turnover",
        accessor: (data: TurnoverTableData) => data.turnoverFormatted,
    },
    amountColumn,
];

export const getNumberList = (count: number) => {
    const list: number[] = [];

    for (let i = 0; i < count; i++) {
        list.push(i);
    }

    return list;
};

export const getTotalTurnover = (turnoverByClients: TurnoverBy[] | undefined) => {
    if (turnoverByClients === undefined) {
        return 0;
    }

    return turnoverByClients.reduce((sum: number, client) => sum + client[1], 0);
};

export const getChartData = (
    turnoverByTimeClient: TurnoverByTimeClients[] | undefined,
    clientNames: Record<number, string> | undefined,
) => {
    if (turnoverByTimeClient === undefined) {
        return [];
    }

    return mapBarChartData(turnoverByTimeClient, clientNames);
};

export const getChartColors = (turnoverByClients: TurnoverBy[] | undefined) => {
    if (turnoverByClients === undefined) {
        return [];
    }

    return [...turnoverByClients]
        .sort((a, b) => b[1] - a[1])
        .map(([clientId], index) => ({
            clientId,
            color: LEAD_CHART_COLORS[index] ?? REST_CHART_COLOR,
        }));
};
