import { getEnv } from "@fm-frontend/utils";
import { Middleware } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { resetConfig, resetSnapshot, setAuthenticated, setError, setToken } from "./appSlice";
import { forceRestartConnecting, restartConnecting, setConnected, startConnecting } from "./socketSlice";
import {
    setAdminConfig,
    setIsLoading,
    setMasterConfig,
    setTurnoverConfig,
    setTurnoverResponse,
    submitTurnoverFilter,
} from "./turnoverSlice";
import { MasterConfig, TurnoverConfig } from "./turnoverSlice.types";
import { ATTEMPTS_COUNT, AUTHENTICATION_FAILED_CODE, buildAdminConfig, createTokenParams, DELAY_TIME } from "./utils";

type UserType = "admin" | "primeBroker" | "maker" | "taker";

const analyticsSocketMiddleware: Middleware = (store) => {
    let socket: WebSocket;
    let reconnectAttempt = 0;
    let authAttempt = 0;

    const reconnectSocket = () => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            restartConnection();
        }
    };
    const delayedReconnection = () => {
        setTimeout(() => {
            restartConnection();
            reconnectAttempt++;
        }, DELAY_TIME);
    };
    const delayedRefreshToken = () => {
        setTimeout(() => {
            restartConnection(true);
        }, DELAY_TIME);
    };
    const onOpen = (e: Event) => {
        reconnectAttempt = 0;
        store.dispatch(setConnected(true));
    };
    const onError = (e: Event) => {
        if (reconnectAttempt > ATTEMPTS_COUNT) {
            store.dispatch(setError(true));
            return;
        }

        delayedReconnection();
    };
    const onAuthFail = () => {
        if (++authAttempt > ATTEMPTS_COUNT) {
            store.dispatch(setError(true));
            return;
        }

        delayedRefreshToken();
    };
    const onConfigMessage = (message: MessageEvent<any>) => {
        const data = JSON.parse(message.data);

        if (Array.isArray(data) && data.length === 3 && data[0] === "TSF") {
            // for maker and taker user types is the only config in array
            // for primeBroker type - 2 [for subaccounts and counterparties]
            // for admin - configs of all users
            const configs: TurnoverConfig[] = data[1];
            const userType: UserType = data[2];
            let turnoverConfig: TurnoverConfig = configs[0];

            if (userType === "admin") {
                store.dispatch(setAdminConfig(buildAdminConfig(configs)));
            } else if (userType === "primeBroker") {
                const masterConfig: MasterConfig = {
                    counterpartiesConfig: configs[1],
                    subaccountsConfig: configs[0],
                };
                store.dispatch(setMasterConfig(masterConfig));
                turnoverConfig = masterConfig.counterpartiesConfig;
            }

            store.dispatch(setTurnoverConfig(turnoverConfig));
        }
        if (Array.isArray(data) && data[0] === "TSR") {
            store.dispatch(
                setTurnoverResponse({
                    filtered: data[1],
                    unfilteredByClients: data[2],
                    unfilteredByInstruments: data[3],
                }),
            );
            store.dispatch(setIsLoading(false));
        }
        if (Array.isArray(data) && data[0] === "Z" && data[1] === AUTHENTICATION_FAILED_CODE) {
            onAuthFail();
        } else {
            authAttempt = 0;
        }
    };
    const restartConnection = (forceRefreshToken = false) => {
        socket?.close();
        store.dispatch(setConnected(false));
        store.dispatch(resetConfig());
        store.dispatch(resetSnapshot());
        initSocket(forceRefreshToken);
    };

    const initSocket = (forceRefreshToken = false) => {
        const init = (token?: string) => {
            socket = new WebSocket(getEnv().REACT_APP_WS_URL + "/statistics/ws" + createTokenParams(token));
            socket.addEventListener("open", onOpen);
            socket.addEventListener("message", onConfigMessage);
            socket.addEventListener("error", onError);
        };

        const { app } = store.getState();

        if (app?.token && !forceRefreshToken) {
            init(app.token);
        } else {
            Auth.currentAuthenticatedUser({
                bypassCache: true,
            })
                .then((user) => {
                    const token: string = user.signInUserSession.idToken.jwtToken;
                    init(token);
                    store.dispatch(setAuthenticated(true));
                    store.dispatch(setToken(token));
                })
                .catch(() => {
                    init();
                    store.dispatch(setAuthenticated(false));
                    store.dispatch(setToken());
                });
        }
    };

    return (next) => (action) => {
        if (forceRestartConnecting.match(action)) {
            restartConnection();
        }
        if (restartConnecting.match(action)) {
            reconnectSocket();
        }
        if (startConnecting.match(action)) {
            initSocket();
        }
        if (submitTurnoverFilter.match(action) && socket?.readyState === WebSocket.OPEN) {
            store.dispatch(setIsLoading(true));
            const { client, instrument, company_id, company_type } = action.payload;
            socket.send(
                JSON.stringify({
                    request: {
                        filters: {
                            client,
                            instrument,
                        },
                        company_id,
                        ...(company_type ? { company_type } : {}),
                    },
                    request_type: "trading_statistics",
                }),
            );
        }

        next(action);
    };
};

export default analyticsSocketMiddleware;
