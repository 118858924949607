export const markSignificantPart = (input: string, length = 5) => {
    let idx = 0;
    let significantSymbols = 0;
    let endIndex = 0;
    for (const char of input) {
        const symbol = Number(char);
        if (!Number.isNaN(symbol)) {
            if (symbol !== 0 || significantSymbols !== 0) {
                significantSymbols += 1;
            }
        }
        if (significantSymbols === length + 1) {
            endIndex = idx;
            break;
        }
        idx += 1;
    }
    return (
        <>
            <mark>{input.slice(0, endIndex ? endIndex : input.length)}</mark>
            {endIndex ? input.substring(endIndex) : ""}
        </>
    );
};

export const markIntegerPart = (input: string) => {
    const [integer, fraction] = input.split(".");
    if (fraction)
        return (
            <>
                <mark>{integer}.</mark>
                <span>{fraction}</span>
            </>
        );
    return (
        <>
            <mark>{integer}</mark>
            <span></span>
        </>
    );
};

export const getAbsoluteValue = (input: number | bigint | string) => {
    if (input > 0) return input;
    if (typeof input === "number") return Math.abs(input);
    if (typeof input === "bigint") return input * BigInt(-1);
    return input;
};

export type FormatConfig = {
    significantDigits?: number;
    unit?: string;
    unitPosition?: "pre" | "post";
    type?: "price" | "size" | "size64" | "limit" | "u32" | "u64" | "timems" | "deltarate" | "deltaratePercent" | "currency";
    showZero?: boolean;
    zeroSubstitution?: string;
};
export const fmt = (
    input: number | string | bigint,
    {
        type,
        significantDigits,
        unit,
        unitPosition = "pre",
        showZero = false,
        zeroSubstitution = "—",
    }: FormatConfig = {},
) => {
    const inputIsNumber = ["number", "bigint"].includes(typeof input);
    if (showZero ? !inputIsNumber : !input) {
        return {
            formattedValue: zeroSubstitution,
            defaultColor: "textFG",
            copyableValue: "",
            parsedValue: "",
        };
    }
    const absolute = getAbsoluteValue(input);
    const isNegative = input < 0;
    let value: string | number | bigint | undefined;
    let copyableValue: string | number | undefined = undefined;
    switch (type) {
        case "size":
        case "size64":
        case "limit":
        case "price": {
            if (typeof absolute === "number") {
                value = absolute * 1e-8;
            }
            if (typeof absolute === "bigint") {
                const rem = Number(absolute % BigInt(1e8)) * 1e-8;
                value = Number(absolute / BigInt(1e8)) + rem;
            }
            break;
        }
        case "u32": {
            if (typeof absolute === "string") {
                value = parseInt(absolute);
            }
            if (typeof absolute === "number") {
                value = absolute;
            }
            break;
        }
        case "u64": {
            if (typeof absolute === "string") {
                value = BigInt(absolute);
            }
            if (typeof absolute === "number") {
                value = absolute;
            }
            if (typeof absolute === "bigint") {
                value = absolute;
            }
            break;
        }
        case "deltarate": {
            if (typeof absolute === "number") {
                value = absolute * 1e-6;
            }
            break;
        }
        case "currency": {
            value = absolute;
            break;
        }
    }
    const parsedValue = String(value);
    if (typeof value === "number" && significantDigits !== undefined) {
        const formatter = new Intl.NumberFormat("en-GB", {
            maximumFractionDigits: significantDigits,
            minimumFractionDigits: significantDigits,
        });
        copyableValue = value;
        value = formatter.format(value);
    }
    if (typeof input === "string") {
        value = input;
        copyableValue = value;
    }
    copyableValue = typeof copyableValue === "number" ? copyableValue.toFixed(significantDigits) : copyableValue || "";
    return {
        isNegative,
        formattedValue: `${isNegative ? "-" : ""}${(unitPosition === "pre" && unit) || ""}${value}${
            (unitPosition === "post" && unit) || ""
        }`,
        value: input,
        copyableValue: `${isNegative ? "-" : ""}${copyableValue}`,
        parsedValue,
    };
};

/**
 * Divides @param value by 10 ^  @param exp and formats it to fixed representation
 */
export const formatShiftFixed = (value: number, exp: number) => {
    if (typeof value !== "number") return "0";
    const isNegative = value < 0;

    const parsed = Math.abs(value) * +`1e-${exp}`;
    let fixed = exp;
    while (value && value % 10 === 0) {
        fixed -= 1;
        value /= 10;
    }

    return (isNegative ? "-" : "") + parsed.toFixed(fixed > 0 ? fixed : 0);
};

export const formatPrecision = (value: number) => {
    return value.toFixed(2);
};

export const floor1000 = (value: number) => {
    return Number((Math.trunc(value * 100) / 100).toFixed(2));
};
