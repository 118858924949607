import { BasicButton, Icons, VStack } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { forwardRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useAppSelector } from "~app/store";
import { setIsLoading } from "~app/store/turnoverSlice";
import { TurnoverSkeletonTable } from "~components/table/TurnoverSkeletonTable";
import { TurnoverTable, UITableRef } from "~components/table/TurnoverTable";
import { TableColumn, TurnoverTableData } from "~components/table/utils";
import { UICard } from "~components/uiCard/UICard";
import { NO_DATA_AVAILABLE } from "~const";
import { AnalyticsTableWrapper } from "~pages/analytics/styled";
import { getSelectedTableItemsLabel } from "~pages/analytics/utils";

const ClearButton = styled(BasicButton).attrs({ size: "small" })`
    padding-left: 6px;
    gap: 6px;
`;

const isCleanAvailable = (data: Record<number, boolean>) => Object.values(data).includes(false);

type AnalyticsTableProps = {
    title: string;
    data: TurnoverTableData[];
    columns: TableColumn<TurnoverTableData>[];
    selected: Record<string, boolean>;
    onSelected: (selected: Record<string, boolean>) => void;
    onClearClick: () => void;
};

export const AnalyticsTable = forwardRef<UITableRef, AnalyticsTableProps>(
    ({ title, data, columns, selected, onSelected, onClearClick }, ref) => {
        const dispatch = useDispatch();
        const config = useAppSelector((state) => state.turnover.config);

        const handleRowClick = useCallback(
            (row, rows) => {
                if (rows.length > 1) {
                    dispatch(setIsLoading(true));
                }
            },
            [dispatch],
        );

        return (
            <UICard
                headerText={{
                    main: title,
                    middle: getSelectedTableItemsLabel(data, selected),
                    right: isCleanAvailable(selected) && (
                        <ClearButton onClick={onClearClick}>
                            <Icons.Clear />
                            Clear selection
                        </ClearButton>
                    ),
                }}
            >
                <AnalyticsTableWrapper>
                    {config && data.length ? (
                        <TurnoverTable
                            ref={ref}
                            data={data}
                            columns={columns}
                            selectedRows={selected}
                            onCheckboxSelect={onSelected}
                            onRowClick={handleRowClick}
                        />
                    ) : config && !data.length ? (
                        <VStack paddingBottom={24}>
                            <EmptySearch description={NO_DATA_AVAILABLE} />
                        </VStack>
                    ) : (
                        <TurnoverSkeletonTable columnCount={5} rowCount={10} />
                    )}
                </AnalyticsTableWrapper>
            </UICard>
        );
    },
);
