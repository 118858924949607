import { BasicButton } from "@fm-frontend/uikit";
import { Auth } from "aws-amplify";
import React from "react";
import { useAppDispatch } from "../app/store";
import { setToken } from "../app/store/appSlice";
import { forceRestartConnecting } from "../app/store/socketSlice";

export const Logout: React.FC = () => {
    const dispatch = useAppDispatch();

    const logoutClick = () => {
        dispatch(setToken());

        Auth.signOut().then(() => {
            dispatch && dispatch(forceRestartConnecting());
        });
    };

    return (
        <BasicButton size="medium" onClick={logoutClick}>
            Log Out
        </BasicButton>
    );
};
