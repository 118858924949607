import React from "react";
import styled, { css } from "styled-components";
import { H2 } from "../typography";
import { useTabContext } from "./utils";

const Label = styled(H2)``;
const Title = styled(H2)``;

const activeTabCss = css`
    ${Label} {
        color: ${(p) => p.theme.colors.brand100};
    }

    ${Title} {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

const hoverTabCss = css`
    background-color: ${(p) => p.theme.colors.ui8};

    ${Title}, ${Label} {
        color: ${(p) => p.theme.colors.ui32};
    }
`;

const Container = styled.div<{ isActive?: boolean }>`
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0 4px;
    border-radius: 8px;
    gap: 4px;

    ${Title}, ${Label} {
        color: ${(p) => p.theme.colors.ui32};
    }

    ${(p) => p.isActive && activeTabCss}
    :hover {
        ${(p) => !p.isActive && hoverTabCss}
    }
`;

export const FormTab: React.VFC<{ title: string; label?: string; value: string }> = (props) => {
    const { title, label, value } = props;
    const context = useTabContext();

    return (
        <Container
            isActive={context?.value === value}
            onClick={() => {
                context?.handleClick?.(value);
            }}
        >
            <Title>{title}</Title>
            <Label>{label}</Label>
        </Container>
    );
};
