import React, { useEffect, useState } from "react";
import { SelectItem } from "../../types";
import { DropdownItem, DropdownList, FilterDropdown, SelectLabel, SelectValue } from "./style";
import { IconDropdown } from "../icons";
import { useMouseDown } from "../../hooks/useMouseDown";

export const CustomSelect: React.FC<{
    options: SelectItem[],
    selectedValue?: string | number,
    label: string,
    onChange: (value: string | number) => void
}> = ({options, selectedValue, label, onChange}) => {

    const [active, setActive] = useState<boolean>(false);
    const [value, setValue] = useState<string | number>();
    useEffect(() => {
        setValue(options?.find(item => item.value === selectedValue)?.label)
    }, [selectedValue, options])

    const dropDown = useMouseDown(() => setActive(false));

    return (
        <FilterDropdown ref={dropDown} onClick={() => setActive(!active)}>
            <SelectLabel>{label}</SelectLabel>
            <SelectValue>{value}</SelectValue>
            <IconDropdown/>
            <DropdownList active={active}>
                {
                    options.map((item, index) => (
                        <DropdownItem
                            selected={item.value === selectedValue}
                            key={index}
                            onClick={() => onChange(item.value)}>
                            {item.label}
                        </DropdownItem>
                    ))
                }
            </DropdownList>
        </FilterDropdown>
    )
}