import { selectors } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Slim = styled.div`
    padding: 40px 15px 15px 15px;
    margin: 0 auto;
    width: 100%;
    min-width: 260px;
    max-width: 390px;

    ${selectors.mixinInterface2}

    color: #999;
    display: block;
`;

export const Logo = styled.div`
    margin: 0 0 24px 12px;
`;

export const StyledForm = styled.div`
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px;
    overflow: hidden;
`;

export const BottomLogin = styled.div`
    ${selectors.mixinInterface3}

    padding: 24px 12px 0 12px;
    cursor: pointer;

    a {
        color: #33b8a7;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    span {
        ${selectors.mixinBody2}

        color: #999;
        display: block;
    }
`;

export const HeadTitle = styled.div`
    ${selectors.mixinHeader2}

    color: #000;
`;

export const FormRow = styled.div`
    margin-top: 16px;

    &.half {
        display: flex;
        justify-content: space-between;

        & > div {
            width: 48.5%;
        }
    }

    label {
        ${selectors.mixinInterface3}

        color: #999;
        display: block;
        padding-bottom: 8px;

        & > span {
            color: #ccc;
        }
    }

    input {
        ${selectors.mixinBody1}

        height: 44px;
        width: 100%;
        padding: 0 12px 0 12px;
        border-radius: 8px;
        background: #f2f2f2;

        &:disabled {
            color: #ccc;
        }
    }

    textarea {
        ${selectors.mixinBody1}

        width: 100%;
        padding: 12px;
        border-radius: 8px;
        background: #f2f2f2;
    }

    p {
        ${selectors.mixinBody1}

        margin-top: 0;
    }
`;

export const FormError = styled.div`
    ${selectors.mixinBody2}
    position: relative;
    cursor: default;
    color: #fe1833;
    text-align: center;
`;

export const FormLink = styled.div`
    ${selectors.mixinBody2};
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin: 0;

    a {
        cursor: pointer;
    }
`;

export const Error = styled.div`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.005em;

    color: #fe1833;
    padding: 3px 12px;
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin: 16px 0;
`;

export const Button = styled.button`
    ${selectors.mixinInterface4}

    border: 2px solid #DAF6F2;
    border-radius: 8px;
    text-align: center;
    color: #16c7b0;
    padding: 0 12px;
    height: 44px;
    width: 100%;
    background: #fff;
    cursor: pointer;

    &:hover {
        border-color: rgba(22, 199, 176, 0.4);
    }

    &:active {
        border-color: #33b8a7;
    }
`;
