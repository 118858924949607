import "chartjs-adapter-moment";
import { Chart } from "chart.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "~app/store";
import {
    selectChartUpdate,
    selectColors,
    selectExchanges,
    selectResetAllData,
    selectResetChartData,
    selectSelectedPeriod,
    selectSnapshot,
    setSnapshotData,
} from "~app/store/appSlice";
import { ChartWrapper } from "./style";
import { getTimeConfig, useChartOptions } from "./utils";

export interface ChartData {
    x: number;
    y: number;
    color?: string;
    clientId?: number;
    clientName?: string;
    percent?: number;
}

export interface ChartColor {
    clientId: number;
    color: string;
}

export interface Snapshot {
    [key: string]: {
        alias: string;
        data: ChartData[];
    };
}

export const LinerChart: React.FC = () => {
    const chart = useRef<Chart<"line", ChartData[]>>(null);
    const dispatch = useAppDispatch();

    const [options, setOptions] = useChartOptions();

    const exchanges = useAppSelector(selectExchanges);
    const resetAllData = useAppSelector(selectResetAllData);
    const resetChartData = useAppSelector(selectResetChartData);
    const snapshotData = useAppSelector(selectSnapshot);
    const chartUpdate = useAppSelector(selectChartUpdate);
    const period = useAppSelector<string>(selectSelectedPeriod);
    const activeMembers = useAppSelector((state) => state.app.membersState.activeMembers);

    const [snapshot, setSnapshot] = useState<Snapshot>({});
    const colors = useAppSelector(selectColors);

    useEffect(() => {
        setOptions((options: any) => ({
            ...options,
            scales: {
                ...options.scales,
                x: {
                    ...options.scales.x,
                    time: {
                        ...options.scales.x.time,
                        ...getTimeConfig(period),
                    },
                },
            },
        }));
    }, [period]);

    useEffect(() => {
        if (chart.current && !!chartUpdate) {
            const [, key, timestamp, value] = chartUpdate;
            const element = chart.current.data.datasets.find((x: any) => x.metaExtensions.key === key);
            if (element) {
                element.data.shift();
                element.data.push({
                    x: timestamp * 1e3,
                    y: value === null ? null : value * 100,
                } as ChartData);
                chart.current.update();
            }
        }
    }, [chartUpdate]);

    useEffect(() => {
        (resetAllData || resetChartData) && dispatch(setSnapshotData([]));
    }, [resetAllData, resetChartData]);

    useEffect(() => {
        if (!exchanges.length || !snapshotData.length) {
            setSnapshot({});
            return;
        }

        const snapshot: Snapshot = {};
        exchanges
            .filter((exchange) => Boolean(activeMembers[exchange.name]))
            .forEach((exchange) => {
                snapshot[exchange.name] = {
                    alias: exchange.alias,
                    data: snapshotData
                        .filter((item) => item[0] === exchange.name)
                        .map((item) => {
                            return {
                                x: item[1] * 1e3,
                                y: item[2],
                            };
                        }),
                };
            });

        setSnapshot(snapshot);
    }, [exchanges, snapshotData, activeMembers]);

    const data = useMemo(() => {
        return {
            datasets: Object.keys(snapshot).map((key) => {
                const current = snapshot[key];
                const color = colors[key];

                return {
                    data: current.data.map((value) => ({
                        ...value,
                        y: value.y === null ? null : value.y * 100,
                    })),
                    label: current.alias,
                    borderColor: color,
                    backgroundColor: color,
                    pointBackgroundColor: color,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: "#ffffff",
                    pointRadius: 0,
                    borderWidth: 1.3,
                    tension: 0.1,
                    metaExtensions: {
                        key: key,
                    },
                };
            }),
        };
    }, [snapshot]);

    return (
        <ChartWrapper>
            <Line ref={chart} options={options} data={data} />
        </ChartWrapper>
    );
};
