import { ActionCell } from "./cells/DiffActionCell";
import { DataCell } from "./cells/DiffDataCell";
import { RowNumberCell } from "./cells/DiffRowNumberCell";
import { Table } from "./Table";

export const DiffTable = Object.assign(Table, {
    RowNumberCell: RowNumberCell,
    DataCell: DataCell,
    ActionCell: ActionCell,
});
