import { VStack } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import moment from "moment/moment";
import React, { useMemo } from "react";
import { useAppSelector } from "~app/store";
import { AnalyticsPeriods } from "~app/store/turnoverSlice.types";
import { BarChartItems } from "~components/barChart/BarChartItems";
import { ChartLoader } from "~components/ChartLoader";
import { ChartColor, ChartData } from "~components/linerChart/LinerChart";
import { NO_DATA_AVAILABLE } from "~const";
import { ChartWrapper, Container, MiddleCenteredContainer } from "./styled";
import { DAYS_IN_MONTH, DAYS_IN_WEEK, HOURS_IN_DAY, mapBarChartItems } from "./utils";

const getBarChartItemsByPeriod = (data: ChartData[] | undefined, period: AnalyticsPeriods | undefined) => {
    if (data === undefined || data.length === 0) {
        return [];
    }

    switch (period) {
        case AnalyticsPeriods["24h"]: {
            const lastPeriod = moment().subtract(1, "hour");
            return mapBarChartItems(data, lastPeriod, HOURS_IN_DAY, "hours");
        }
        case AnalyticsPeriods.Today: {
            const lastPeriod = moment().startOf("hour");
            const itemsCount = lastPeriod.hours() + 1;
            return mapBarChartItems(data, lastPeriod, itemsCount, "hours");
        }
        case AnalyticsPeriods["7d"]:
            return mapBarChartItems(data, moment(), DAYS_IN_WEEK, "days");
        case AnalyticsPeriods["30d"]:
            return mapBarChartItems(data, moment(), DAYS_IN_MONTH, "days");
        case AnalyticsPeriods.Month: {
            const lastPeriod = moment();
            const itemsCount = lastPeriod.date();
            return mapBarChartItems(data, lastPeriod, itemsCount, "days");
        }
        default:
            return [];
    }
};

export const BarChart: React.VFC<{ data: ChartData[]; colors: ChartColor[] }> = ({ data, colors }) => {
    const isLoading = useAppSelector((state) => state.turnover.isLoading);
    const selectedPeriod = useAppSelector((state) => state.turnover.selectedPeriod);

    const items = useMemo(() => getBarChartItemsByPeriod(data, selectedPeriod), [data, selectedPeriod]);
    const isEmpty = items.length === 0;

    return (
        <Container>
            <ChartWrapper>
                {isLoading && (
                    <MiddleCenteredContainer>
                        <ChartLoader />
                    </MiddleCenteredContainer>
                )}
                {!isLoading && isEmpty && (
                    <VStack width="100%" alignItems="center">
                        <EmptySearch description={NO_DATA_AVAILABLE} />
                    </VStack>
                )}
                {!isLoading && !isEmpty && <BarChartItems items={items} period={selectedPeriod} colors={colors} />}
            </ChartWrapper>
        </Container>
    );
};
