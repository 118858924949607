import { Modal } from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import React, { useMemo, useState } from "react";
import { AnalyticsPeriods } from "~app/store/turnoverSlice.types";
import { BarChartItem } from "~components/barChart/BarChartItem";
import { BatChartItemModalContent } from "~components/barChart/BatChartItemModalContent";
import { ChartColor, ChartData } from "~components/linerChart/LinerChart";
import { mapXAxisLabel, REST_CHART_COLOR } from "./utils";

const getBarChartMaxValue = (data: ChartData[] | undefined) => {
    if (data === undefined) {
        return 0;
    }

    const combinedValues = data.reduce((acc, item) => {
        acc[item.x] ??= 0;
        acc[item.x] += item.y;
        return acc;
    }, {} as Record<number, number>);
    const yValues = Object.values(combinedValues);

    return Math.max(...yValues);
};

interface Props {
    items: ChartData[];
    period: AnalyticsPeriods | undefined;
    colors: ChartColor[];
}

export const BarChartItems: React.VFC<Props> = ({ items, period, colors }) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    const [selectedChartPeriod, setSelectedChartPeriod] = useState<string>();
    const [selectedChartItems, setSelectedChartItems] = useState<ChartData[]>();

    const maxValue = useMemo(() => getBarChartMaxValue(items), [items]);

    const groupedList = items.reduce((acc, item) => {
        acc[item.x] ??= [];
        acc[item.x].push({
            color: colors.find((color) => color.clientId === item.clientId)?.color,
            ...item,
        });
        return acc;
    }, {} as Record<number, ChartData[]>);

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <BatChartItemModalContent period={selectedChartPeriod ?? ""} items={selectedChartItems ?? []} />
            </Modal>
            {Object.values(groupedList).map((chartItems, index) => {
                const sortedItems = [...chartItems].sort((a, b) => {
                    if (a.color === REST_CHART_COLOR && b.color !== REST_CHART_COLOR) {
                        return 1;
                    }
                    if (b.color === REST_CHART_COLOR && a.color !== REST_CHART_COLOR) {
                        return -1;
                    }
                    return b.y - a.y;
                });
                const xLabel = mapXAxisLabel(chartItems[0].x, index, period);

                return (
                    <BarChartItem
                        key={index}
                        items={sortedItems}
                        maxValue={maxValue}
                        tooltipLabel={xLabel.longLabel}
                        xAxisLabel={xLabel.label}
                        isLabelVisible={xLabel.isLabelVisible}
                        onClick={() => {
                            setSelectedChartPeriod(xLabel.longLabel);
                            setSelectedChartItems(sortedItems);
                            openModal();
                        }}
                    />
                );
            })}
        </>
    );
};
