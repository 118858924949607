import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "~app/store";
import { restartConnecting } from "~app/store/socketSlice";
import { Footer } from "~components/Footer";
import { Header } from "~components/header";
import { Sidebar } from "~components/Sidebar";
import { AnalyticsContent } from "~pages/analytics/AnalyticsContent";

export const Analytics: React.FC = () => {
    const dispatch = useAppDispatch();
    const config = useAppSelector((state) => state.turnover.config);

    useEffect(() => {
        dispatch(restartConnecting());
    }, []);

    return (
        <>
            <Header />
            <AnalyticsContent key={`${config?.company_id}-${config?.company_type}`} />
            <Sidebar />
            <Footer />
        </>
    );
};
