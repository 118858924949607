import { selectors } from "@fm-frontend/uikit";
import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { IconError } from "../../components/icons";
import { Loading } from "../../components/Loading";
import { SignInSteps } from "./SignIn";
import { Button, Error, FormError, FormRow, HeadTitle } from "./style";
import { ValidationRules } from "./utils";

const QRCodeWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const GetAppCodeInput = styled.input`
    text-align: center;
`;

export const SlimWidgetMessage = styled.div`
    background: ${selectors.activeBG};
    border: 1px solid #e6e6e6;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 14px 32px 14px 48px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    position: relative;
    margin: 8px 0 8px;

    img {
        position: absolute;
        top: 17px;
        left: 12px;
    }

    svg {
        position: absolute;
        top: 17px;
        left: 12px;
    }
`;

export const SetupMfaCode: React.FC<{
    userState: any;
    setStep: (step: SignInSteps) => void;
}> = ({ userState, setStep }) => {
    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<any>({
        mode: "onChange",
    });

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [mfaToken, setMfaToken] = useState<string>();

    useEffect(() => {
        Auth.setupTOTP(userState)
            .then((token: string) => {
                setMfaToken(token);
            })
            .catch((error) => {
                setError(error.message);
            });
    }, []);

    const onSubmit = (data: any) => {
        if (!isValid) {
            return;
        }

        setLoading(true);

        Auth.verifyTotpToken(userState, data.code)
            .then((data) => {
                setError(undefined);
                setLoading(false);
                setStep(SignInSteps.Login);
            })
            .catch((error) => {
                setLoading(false);
                setError(error?.message);
            });
    };

    const getMfaToken = (token: string): string => {
        return `otpauth://totp/FM Pulse?secret=${token}`;
    };

    return (
        <>
            <HeadTitle>Setup 2FA</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    {mfaToken && (
                        <QRCodeWrapper>
                            <QRCode value={getMfaToken(mfaToken)} />
                        </QRCodeWrapper>
                    )}
                </FormRow>
                <FormRow>
                    {mfaToken && (
                        <>
                            <label>Alternatively, input the secret key manually</label>
                            <GetAppCodeInput required readOnly autoComplete="off" value={mfaToken} />
                        </>
                    )}
                </FormRow>
                <SlimWidgetMessage>
                    <IconError />
                    We strongly recommend you to keep this key in a safe place to restore the access in case of a device
                    loss.
                </SlimWidgetMessage>
                <FormRow>
                    <label>Code</label>
                    <input
                        {...register("code", {
                            ...ValidationRules.code,
                        })}
                        placeholder="000000"
                    />
                    {errors.code && <Error>{errors.code.message}</Error>}
                </FormRow>
                <FormRow>
                    {error && <FormError>{error}</FormError>}
                    <Loading loading={loading}>
                        <Button type={"submit"}>Confirm</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    );
};
