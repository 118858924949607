import { transition } from "@fm-frontend/utils";
import React, { useCallback, useState } from "react";
import { PopoverAlign, PopoverPosition } from "react-tiny-popover";
import styled from "styled-components";
import { DelayMode, Popover } from "../Popover";
import { PSmall } from "../typography";

const TooltipContent = styled.div`
    padding: 6px 8px;

    background-color: ${(p) => p.theme.colors.ui96};
    color: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 8px;
    max-width: 280px;
    z-index: 10;
    white-space: break-spaces;
    overflow-wrap: break-word;
    hyphens: none;
    display: block;
    width: max-content;
    opacity: 1;
    ${(p) => transition(p.theme.transitions.default)}
`;

export const TooltipContainer = styled.div`
    position: relative;
    pointer-events: all;
    display: inline-flex;

    cursor: pointer;
`;

const useHover = (isEnabled: boolean) => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseOver = useCallback(() => {
        if (isEnabled) {
            setIsHovered(true);
        }
    }, [isEnabled, setIsHovered]);
    const onMouseLeave = useCallback(() => {
        if (isEnabled) {
            setIsHovered(false);
        }
    }, [isEnabled, setIsHovered]);

    return {
        isHovered,
        onMouseOver,
        onMouseLeave,
    };
};

type TooltipProps = {
    content?: string | number | bigint | React.ReactNode;
    positions?: PopoverPosition[];
    align?: PopoverAlign;
    delayMode?: DelayMode;
    isHoveredContent?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
    content,
    positions,
    align,
    delayMode,
    isHoveredContent,
    children,
}) => {
    const { isHovered: isOpen, onMouseOver, onMouseLeave } = useHover(Boolean(content));

    const tooltipContentElement = React.isValidElement(content) ? content : <PSmall>{content}</PSmall>;
    const tooltipContentProps = isHoveredContent ? { onMouseOver, onMouseLeave } : {};

    return (
        <Popover
            isOpen={isOpen}
            padding={6}
            positions={positions ?? ["top"]}
            align={align ?? "end"}
            delayMode={delayMode}
            content={<TooltipContent {...tooltipContentProps}>{tooltipContentElement}</TooltipContent>}
        >
            <TooltipContainer onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                {children}
            </TooltipContainer>
        </Popover>
    );
};
