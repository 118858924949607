import styled, { css } from "styled-components";
import { errorCss } from "../../common";
import { ELLIPSIS_CSS, TextSmall } from "../../typography";
import { InputSize } from "./Input.types";

export const BasicInput = styled.input<{ isError?: boolean }>`
    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;

    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }

    ::placeholder {
        color: ${(p) => p.theme.colors.ui32};
    }

    :enabled:active,
    :enabled:focus {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12},
            inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
    }

    :disabled {
        cursor: not-allowed;
        background-color: ${(p) => p.theme.colors.ui4};
        color: ${(p) => p.theme.colors.ui20};
    }

    ${({ isError }) => isError && errorCss}

    ${ELLIPSIS_CSS}
`;

export const SimpleInput = styled(BasicInput)`
    text-align: right;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    gap: 4px;
`;

export const Container = styled.div<{ size: InputSize; isDisabled?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    ${(p) =>
        p.size === "large" &&
        css`
            ${BasicInput} {
                height: 44px;
                padding: 0 12px;
                font-size: 14px;
                line-height: 18px;
            }
        `}
    ${(p) =>
        p.size === "medium" &&
        css`
            ${BasicInput} {
                height: 32px;
                padding: 0 8px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.06px;
            }
        `}
    ${(p) =>
        p.size === "small" &&
        css`
            ${BasicInput} {
                height: 24px;
                padding: 0 8px;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.06px;
            }
        `}

    ${(p) =>
        p.isDisabled &&
        css`
            ${TextSmall}, input, input::placeholder {
                color: ${p.theme.colors.ui20} !important;
            }
        `}
`;

export const BasicLabel = styled.label<{ offset: number }>`
    color: ${(p) => p.theme.colors.ui72};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

export const SimpleLabel = styled(BasicLabel)`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    display: flex;
    gap: 5px;
`;
