import { useEffect, useRef } from "react";

export const useMouseDown = (callback?: () => void) => {
    const ref = useRef<any>(null);

    useEffect(() => {
        if(!ref.current) {
            return;
        }

        const handler = (event: MouseEvent) => {
            if(!ref.current?.contains(event.target)) {
                callback && callback();
            }
        }

        document.addEventListener("mousedown", (e) => handler(e));

        return (
            document.removeEventListener("mousedown", (e) => handler(e))
        )
    }, [ref])

    return ref;
}
