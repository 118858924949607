import { FC } from "react";
import styled from "styled-components";
import { P } from "../../../typography";

export const Container = styled.div`
    display: flex;
    justify-content: center;

    padding-right: 12px;

    ::before {
        pointer-events: none;
        content: "";
        display: block;
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const Value = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

type RowNumberCellProps = {
    value: number;
};
export const RowNumberCell: FC<RowNumberCellProps> = ({ value }) => {
    return (
        <Container>
            <Value>{value}</Value>
        </Container>
    );
};
