import { LocalFile, parse, ParseResult, unparse } from "papaparse";
import { CSV_SEPARATOR } from "../const";

type DataKey = string;
type DataValue = number | bigint | string | boolean | undefined | null;
export type ExportData = { [key: DataKey]: DataValue };

type ParseToCsvOptions = {
    header?: boolean;
};
export const parseToCsv = (data: ExportData[], options?: ParseToCsvOptions): string => {
    return unparse(data, {
        header: options?.header,
        delimiter: CSV_SEPARATOR,
        newline: "\n",
    });
};

export const parseToJson = async <T extends ExportData>(
    source: LocalFile | string,
): Promise<ParseResult<T>> => {
    return new Promise((resolve, reject) => {
        parse(source, {
            complete: (results: ParseResult<T>) => {
                resolve(results);
            },
            error: (error: Error) => {
                reject(error);
            },
            header: true,
        });
    });
};

export type CompareReturnType<T> = Record<
    keyof T,
    {
        diff: boolean;
        oldValue: DataValue | undefined;
        newValue: DataValue | undefined;
    }
>;

export const diffExportToImport = <T>(
    exportData: ExportData[],
    importData: ExportData[],
): CompareReturnType<T>[] => {
    const [sourceA, sourceB] =
        exportData.length > importData.length ? [exportData, importData] : [importData, exportData];

    return sourceA.map((sourceAObj, index) => {
        const sourceBObj = sourceB[index] ?? {};
        const comparisonResult: Partial<CompareReturnType<T>> = {};

        Object.keys(sourceAObj).forEach((key) => {
            const castedKey = key as keyof T;

            const diff = sourceAObj[key] !== sourceBObj[key];

            comparisonResult[castedKey] = {
                diff: diff,
                oldValue: exportData[index]?.[castedKey],
                newValue: diff ? importData[index]?.[castedKey] : undefined,
            };
        });

        return comparisonResult as CompareReturnType<T>;
    });
};

export const hasSameDataSchema = (exportItem: ExportData, importItem: ExportData) => {
    const exportItemKeys = Object.keys(exportItem);
    const importItemKeys = Object.keys(importItem);

    if (exportItemKeys.length !== importItemKeys.length) {
        return false;
    }

    return exportItemKeys.every((key) => importItemKeys.includes(key));
};
