import { Tooltip } from "../../../common";
import { Icons } from "../../../icons";
import {
    Container,
    DiffContainer,
    ErrorHindContainer,
    NewValue,
    NormalValue,
    OldValue,
} from "./styled";

type DataCellProps<T> = {
    diff: boolean;
    oldValue: T | undefined;
    newValue: T | undefined;
    error?: string;
    formatValue?: (value: T | undefined) => string;
};

export const DataCell = <T,>({
    diff,
    oldValue,
    newValue,
    error,
    formatValue,
}: DataCellProps<T>) => {
    const formatedOldValue = formatValue ? formatValue(oldValue) : oldValue;
    const formatedNewValue = formatValue ? formatValue(newValue) : newValue;

    if (!diff) {
        return (
            <Container>
                <NormalValue>{formatedOldValue}</NormalValue>
            </Container>
        );
    }

    const hasError = Boolean(error);

    return (
        <DiffContainer hasError={hasError}>
            {hasError && (
                <ErrorHindContainer>
                    <Tooltip content={error}>
                        <Icons.ErrorHint />
                    </Tooltip>
                </ErrorHindContainer>
            )}
            <OldValue>{formatedOldValue}</OldValue>
            <NewValue hasError={hasError}>{formatedNewValue}</NewValue>
        </DiffContainer>
    );
};
