import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { mapBaseCurrency } from "~components/filters";
import { useAppSelector } from "../app/store";
import { selectSelectedAmount, selectSelectedInstrument, selectSelectedPeriod } from "../app/store/appSlice";
import { onMobile } from "../const";
import { SkeletonLine } from "../style";
import { SkeletonTitleWrapper } from "./Card";

const TitleSkeletonLine = styled(SkeletonLine)`
    width: 5.3rem;
`;

const PeriodTitle = styled.span`
    ${onMobile} {
        display: none;
    }
`;

const ChartTitleWrapper = styled.div`
    margin-left: 1px;
`;

const getLocalGMT = () => {
    const offsetInMinutes: number = new Date().getTimezoneOffset() * -1;

    const sign = offsetInMinutes > 0 ? "+" : "-";
    const hours = `0${Math.floor(offsetInMinutes / 60)}`.slice(-2);
    const minutes = `0${offsetInMinutes % 60}`.slice(-2);

    return `local GMT(${sign}${hours}:${minutes})`;
};

const useTimePeriod = () => {
    const period = useAppSelector(selectSelectedPeriod);
    const [periodTitle, setPeriodTitle] = useState<string>("");

    const localGMT = useMemo(() => getLocalGMT(), []);

    useEffect(() => {
        if (period === "1D") {
            setPeriodTitle(`Last 24 hours, ${localGMT}`);
        }
        if (period === "1W") {
            setPeriodTitle(`Last 7 days, ${localGMT}`);
        }
        if (period === "1M") {
            setPeriodTitle(`Last 1 month, ${localGMT}`);
        }
    }, [period]);

    return { periodTitle };
};

const CHART_TITLE_TEXT = "Spread";

export const ChartTitle: React.FC = () => {
    const instrument = useAppSelector(selectSelectedInstrument);
    const amount = useAppSelector(selectSelectedAmount);
    const baseCurrency = mapBaseCurrency(instrument ?? "");
    const amountTitle = amount === undefined ? null : `, ${amount} ${baseCurrency} size`;
    const { periodTitle } = useTimePeriod();

    return instrument ? (
        <ChartTitleWrapper>
            {instrument.replace("/", "-")} {CHART_TITLE_TEXT}
            {amountTitle}
            <PeriodTitle>{periodTitle}</PeriodTitle>
        </ChartTitleWrapper>
    ) : (
        <SkeletonTitleWrapper>
            <TitleSkeletonLine />
            {CHART_TITLE_TEXT}
            <TitleSkeletonLine />
        </SkeletonTitleWrapper>
    );
};
