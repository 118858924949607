import { FC } from "react";
import type { PopoverProps } from "react-tiny-popover";
import { Popover as TinyPopover } from "react-tiny-popover";
import { PopoverContent } from "./styled";
import { DelayMode, useDelayedIsOpen } from "./useDelayedIsOpen";

type UIKitPopoverProps = Omit<PopoverProps, "containerClassName" | "containerStyle"> & {
    delayMode?: DelayMode;
    delayAnimationTimeout?: number;
};

export const Popover: FC<UIKitPopoverProps> = ({
    children,
    isOpen: opened,
    content,
    delayMode = DelayMode.OnClose,
    delayAnimationTimeout,
    ...restPopoverProps
}) => {
    const isOpen = useDelayedIsOpen(opened, delayMode, delayAnimationTimeout);
    const popoverContent = <PopoverContent opened={isOpen}>{content}</PopoverContent>;

    return (
        <TinyPopover
            isOpen={isOpen}
            content={popoverContent}
            containerClassName=""
            containerStyle={{ zIndex: "1", transition: "none" }}
            {...restPopoverProps}
        >
            {children}
        </TinyPopover>
    );
};
