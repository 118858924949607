import React from "react";
import { Container, Bottom, Top } from "./style";
import { DownTrendIcon, UpTrendIcon } from "../icons";
import { SkeletonLine } from "../../style";

export interface TurnoverPeriodProps {
    period: string;
    percent: string;
    isUpTrend: boolean;
    number: string;
    isSelected: boolean;
    onClick: () => void
}

export const TurnoverPeriod: React.FC<TurnoverPeriodProps> = ({
                                                                  period,
                                                                  percent,
                                                                  isUpTrend,
                                                                  number,
                                                                  isSelected,
                                                                  onClick
                                                              }) => (
    <Container selected={isSelected} onClick={onClick}>
        <Top selected={isSelected} isUpTrend={isUpTrend}>
            <span>{period}</span>
            <span>{percent}</span>
        </Top>
        <Bottom selected={isSelected}>
            <span>${number}</span>
            {
                isUpTrend ? <UpTrendIcon/> : <DownTrendIcon/>
            }
        </Bottom>
    </Container>
)

export const TurnoverPeriodSkeleton: React.FC = () => (
    <Container>
        <Top >
            <SkeletonLine/>
            <SkeletonLine/>
        </Top>
        <Bottom >
            <SkeletonLine/>
        </Bottom>
    </Container>
)