import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { restartConnecting } from "../app/store/socketSlice";

const EVENT_TYPE = 'visibilitychange'

export const useIsActiveTracker = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const listener = () => {
            const isActive = !isHidden();
            isActive && dispatch(restartConnecting())
        };

        document.addEventListener(EVENT_TYPE, () => listener());

        return () => {
            document.removeEventListener(EVENT_TYPE, () => listener());
        };
    }, []);
};

const getHiddenProp = () => {
    const hiddenKey = 'hidden'
    const prefixes = ['webkit', 'moz', 'ms', 'o'];

    if(hiddenKey in document) {
        return hiddenKey
    }

    prefixes.forEach(prefix => {
        if((prefix + 'Hidden') in document) {
            return prefix + 'Hidden';
        }
    })

    return null;
}

const isHidden = () => {
    const prop = getHiddenProp();

    if(!prop) {
        return false;
    }

    return document[prop as keyof Document];
}