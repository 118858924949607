import { useContext, VFC } from "react";
import styled from "styled-components";
import { REST_CHART_COLOR } from "../barChart/utils";
import { ChartColorsContext } from "./Context";

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
    display: flex;
`;

export const Mark = styled.div<{ $color: string }>`
    background: ${(p) => p.$color};
    width: 2px;
    height: 100%;
`;

export const Chart = styled.div<{ $color: string; $percent: number }>`
    width: calc(1% * ${(p) => p.$percent});
    height: 100%;
    border-radius: 1px;
    background: ${(p) => p.$color};
    opacity: 0.12;
`;

export const TurnoverChart: VFC<{ clientId: number; percent: number }> = ({ clientId, percent }) => {
    const chartColors = useContext(ChartColorsContext);
    const color = chartColors.find((item) => item.clientId === clientId)?.color ?? REST_CHART_COLOR;

    return (
        <Container>
            <Mark $color={color} />
            <Chart $color={color} $percent={percent} />
        </Container>
    );
};
