import { selectors } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { onTablet } from "../../const";

export const BaseDropdown = styled.div`
    ${selectors.mixinBody1}

    color: #000;
    height: 44px;
    width: 100%;
    min-width: 100px;
    padding: 0 12px 0 0;
    border-radius: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    margin-bottom: 16px;
    -webkit-tap-highlight-color: transparent;
`;

export const FilterDropdown = styled(BaseDropdown)`
    background: ${(p) => p.theme.colors.selectLightBG};

    & > svg {
        position: absolute;
        right: 16px;
    }

    ${onTablet} {
        background: rgba(0, 0, 0, 0.05);
    }

    :hover {
        background: rgba(36, 48, 52, 0.12);
    }
`;

export const LogoutButton = styled.button`
    cursor: pointer;
    background-color: #e8e8e8;
    border-radius: 8px;
    width: 70px;
    height: 32px;

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.005em;
        color: #243034;
    }
`;

export const SelectValue = styled.span`
    padding-left: 12px;
    padding-right: 32px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
`;

export const SelectLabel = styled.span`
    padding-left: 12px;
    display: none;
    color: #999999;

    font-size: 14px;

    ${onTablet} {
        display: block;
    }
`;

export const DropdownList = styled.ul<{ active?: boolean }>`
    list-style: none;
    margin: 6px 0 0 0;
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 0 0 1px #0000000a, 0 16px 48px #00000029;
    border-radius: 8px;
    padding: 8px 0;
    opacity: 0;
    visibility: hidden;

    ${({ active }) =>
        active &&
        css`
            opacity: 1;
            visibility: visible;
        `}
    transition: visibility 0.3s linear, opacity 0.3s;
`;

export const DropdownItem = styled.li<{ selected?: boolean }>`
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 9px 15px;
    color: #243034;
    text-align: left;

    ${selectors.mixinInterface2}
    ${selectors.fontRegular}
  &:hover {
        background: #f7f7f7;
        color: #000;
    }

    ${({ selected }) =>
        selected &&
        css`
            color: #16c7b0;
        `}
`;
