import { MasterConfig, TurnoverConfig } from "./turnoverSlice.types";

export const ATTEMPTS_COUNT = 5;
export const DELAY_TIME = 5000;
export const AUTHENTICATION_FAILED_CODE = 1008;

export const createTokenParams = (token?: string) => {
    return token ? `?auth_token=${token}` : "";
};

export const buildAdminConfig = (apiConfigs: TurnoverConfig[]) => {
    const groupedByCompanyIdConfigs = apiConfigs.reduce<{ [id in number]: TurnoverConfig[] }>((acc, config) => {
        const { company_id } = config;
        acc[company_id] = acc[company_id] ?? [];
        acc[company_id].push(config);
        return acc;
    }, {});
    const adminConfig = Object.entries(groupedByCompanyIdConfigs)
        .sort(([companyId1], [companyId2]) => Number(companyId1) - Number(companyId2))
        .map(([, configs]) => {
            // length === 2 when it's master config
            if (configs.length === 2) {
                const counterpartiesConfig = configs.find(({ company_type }) => company_type === "primeBrokerExternal");
                const subaccountsConfig = configs.find(({ company_type }) => company_type === "primeBroker");

                if (counterpartiesConfig && subaccountsConfig) {
                    const masterConfig: MasterConfig = {
                        counterpartiesConfig,
                        subaccountsConfig,
                    };
                    return masterConfig;
                }

                console.error(`Wrong master configs types of client ${configs[0].company_id}`);
            }

            return configs[0];
        });

    return adminConfig;
};
