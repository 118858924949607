import { useAppDispatch, useAppSelector } from "app/store";
import { selectAuthenticated, selectColors, setActiveMembers } from "app/store/appSlice";
import { ItemType, MembersState } from "app/store/appSlice.types";
import React, { useEffect, useMemo } from "react";
import { Row, useRowSelect, useTable } from "react-table";
import { FlexCell, FlexRow, FlexTable, TableCellWrapper } from "./style";
import { TableData } from "./Table";
import { TableCheckbox } from "./TableCheckbox";
import { getAvailableColumns, mapHeaderColumns, mapSelectedRows, TableColumn } from "./utils";

export const SimpleTable: React.FC<{
    data: TableData[];
    type: ItemType;
    bestSpread: number;
}> = ({ data, type, bestSpread }) => {
    const dispatch = useAppDispatch();
    const colors = useAppSelector(selectColors);
    const isAuthenticated = useAppSelector(selectAuthenticated);
    const activeMembers = useAppSelector((state) => state.app.membersState.activeMembers);

    const memoColumns = useMemo<TableColumn<TableData>[]>(() => {
        const availableColumns = getAvailableColumns(isAuthenticated);
        return mapHeaderColumns(type, availableColumns);
    }, [isAuthenticated, type]);

    const {
        rows,
        headerGroups,
        flatRows,
        prepareRow,
        toggleRowSelected,
        getTableProps,
        state: { selectedRowIds },
    } = useTable(
        {
            columns: memoColumns,
            data,
            autoResetSelectedRows: false,
            disableResizing: true,
            initialState: {
                selectedRowIds: mapSelectedRows(data, activeMembers),
            },
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "Selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <TableCellWrapper>
                                <TableCheckbox header {...getToggleAllRowsSelectedProps()} />
                            </TableCellWrapper>
                        );
                    },
                    Cell: ({ row }: any) => {
                        return (
                            <TableCellWrapper>
                                <TableCheckbox
                                    background={colors[row.original.key]}
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </TableCellWrapper>
                        );
                    },
                },
                ...columns,
            ]);
        },
    );

    useEffect(() => {
        const activeMembers: MembersState = {};

        flatRows.forEach((row) => {
            activeMembers[row.original.key] = row.isSelected;
        });

        dispatch(setActiveMembers(activeMembers));
    }, [selectedRowIds]);

    const handleRowClick = (e: React.MouseEvent, row: Row<TableData>) => {
        e.preventDefault();
        e.stopPropagation();

        if (row) {
            toggleRowSelected(row.id);
        }
    };

    return (
        <FlexTable {...getTableProps()}>
            {headerGroups.map((headerGroup) => (
                <FlexRow header {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <FlexCell header {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </FlexCell>
                    ))}
                </FlexRow>
            ))}
            {rows.map((row) => {
                prepareRow(row);
                const isPositive = row.original.positive === bestSpread;

                return (
                    <FlexRow
                        onClick={(e) => handleRowClick(e, row)}
                        positive={isPositive}
                        inactive={!row.isSelected}
                        {...row.getRowProps()}
                    >
                        {row.cells.map((cell) => {
                            return (
                                <FlexCell positive={isPositive && cell.column.id !== "Name"} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </FlexCell>
                            );
                        })}
                    </FlexRow>
                );
            })}
        </FlexTable>
    );
};
