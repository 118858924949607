import React, { useState } from "react";
import { Button, Error, ErrorContainer, FormError, FormLink, FormRow, HeadTitle } from "./style";
import { useForm } from "react-hook-form";
import { SignInSteps } from "./SignIn";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { INCORRECT_LOGIN_PASSWORD, ValidationRules } from "./utils";
import { Loading } from "../../components/Loading";
import { useAppDispatch } from "../../app/store";
import { forceRestartConnecting } from "../../app/store/socketSlice";

export const Login: React.FC<{
    setUser: (user: any) => void,
    setStep: (step: SignInSteps) => void
}> = (
    {
        setUser,
        setStep
    }) => {

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<any>({
        mode: "onChange"
    });

    const history = useHistory();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onSubmit = (data: any) => {
        if(!isValid) {
            return;
        }

        setLoading(true);

        Auth.signIn(data.email, data.password)
            .then((user: any) => {
                setError(undefined);
                setLoading(false);
                setIsForgotPassword(false);
                setUser(user);

                if(user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    setStep(SignInSteps.ConfirmMfaCode)
                    return;
                }
                if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setStep(SignInSteps.ChangePassword)
                    return;
                }
                if(user.challengeName === 'MFA_SETUP') {
                    setStep(SignInSteps.SetupMfaCode)
                    return;
                }

                dispatch(forceRestartConnecting());
                history.push('/spreads')
            })
            .catch((error: any) => {
                setError(error?.message);
                setLoading(false);

                if(error?.message === INCORRECT_LOGIN_PASSWORD) {
                    setIsForgotPassword(true);
                }
                if(error['code'] === 'PasswordResetRequiredException') {
                    setStep(SignInSteps.ResetPassword)
                }
            });
    }


    return (
        <>
            <HeadTitle>Log In</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    <label>Email</label>
                    <input {...register('email', {
                        ...ValidationRules.email

                    })}
                           placeholder='trader@example.com'
                    />
                    {
                        errors.email && (
                            <Error>{errors.email.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    <label>Password</label>
                    <input {...register('password', {
                        ...ValidationRules.password

                    })}
                           type={'password'}
                           placeholder="************"/>
                    {
                        errors.password && (
                            <Error>{errors.password.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    <ErrorContainer>
                        {
                            error && (
                                <FormError>{error}</FormError>
                            )
                        }
                        {
                            isForgotPassword && (
                                <FormLink onClick={(e) => {
                                    e.preventDefault();
                                    setStep(SignInSteps.ResetPassword)
                                }}>
                                    <a>Forgot password?</a>
                                </FormLink>
                            )
                        }
                    </ErrorContainer>
                    <Loading loading={loading}>
                        <Button type={'submit'}>Login</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    )
}