import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AnalyticsPeriods,
    ClientConfig,
    MasterConfig,
    Periods,
    TurnoverConfig,
    TurnoverFilter,
    TurnoverPeriod,
    TurnoverSlice,
} from "./turnoverSlice.types";

const initialState: TurnoverSlice = {
    isLoading: true,
};

const createDefaultPeriod = () =>
    ({
        turnover: [0, 0],
        turnover_by: {
            client: [],
            instrument: [],
            time: [],
            time_clients: [],
        },
        pocket_share: [],
        instrument_pocket_share: [],
    } as TurnoverPeriod);

export const DEFAULT_PERIODS_STATE: Periods = {
    "24h": createDefaultPeriod(),
    today: createDefaultPeriod(),
    "7d": createDefaultPeriod(),
    "30d": createDefaultPeriod(),
    month: createDefaultPeriod(),
};

const turnoverSlice = createSlice({
    name: "turnover",
    initialState: initialState,
    reducers: {
        setTurnoverConfig: (state, { payload }: PayloadAction<TurnoverConfig | undefined>) => {
            state.config = payload;
        },
        setAdminConfig: (state, { payload }: PayloadAction<ClientConfig[]>) => {
            state.adminConfig = payload;
        },
        setMasterConfig: (state, { payload }: PayloadAction<MasterConfig | undefined>) => {
            state.masterConfig = payload;
        },
        setTurnoverResponse: (
            state,
            {
                payload,
            }: PayloadAction<{
                filtered: Periods;
                unfilteredByClients: Periods;
                unfilteredByInstruments: Periods;
            }>,
        ) => {
            state.periods = payload;
        },
        submitTurnoverFilter: (state, action: PayloadAction<Partial<TurnoverFilter>>) => {
            return;
        },
        setSelectedPeriod: (state, { payload }: PayloadAction<AnalyticsPeriods>) => {
            state.selectedPeriod = payload;
        },
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        resetPeriods: (state) => {
            state.periods = {
                filtered: DEFAULT_PERIODS_STATE,
                unfilteredByClients: DEFAULT_PERIODS_STATE,
                unfilteredByInstruments: DEFAULT_PERIODS_STATE,
            };
        },
    },
});

export const {
    setTurnoverConfig,
    setAdminConfig,
    setMasterConfig,
    setTurnoverResponse,
    submitTurnoverFilter,
    setSelectedPeriod,
    setIsLoading,
    resetPeriods,
} = turnoverSlice.actions;

export default turnoverSlice.reducer;
