import { CompanyTypes } from "~app/store/appSlice.types";
import { PocketShare, TurnoverBy, TurnoverByTimeClients } from "~app/store/turnoverSlice.types";
import { toPercent } from "~components/barChart/utils";
import { formatAsPercent, TurnoverTableData } from "~components/table/utils";
import { fmt, FormatConfig } from "~utils/format";

export const AGGREGATED_USERS_LIST = ["All clients", "All makers", "All takers", "All masters", "All sub-accounts"];

export const getUserName = (name: string, fallbackTitle: string) => {
    return AGGREGATED_USERS_LIST.find((userName) => userName === name) ?? fallbackTitle;
};
export const fmtConfig: FormatConfig = { significantDigits: 2, showZero: true, type: "currency" };

export const DEFAULT_TURNOVER_ITEM = {
    amount: 0,
    amountFormatted: "0.00",
    pocketShare: 0,
    pocketShareFormatted: "0.00%",
    turnover: 0,
    turnoverFormatted: "0.00%",
};

export const mapTurnoverInitTableData = <T extends number | string>(
    turnoverArray: T[],
    companyType?: CompanyTypes,
    turnoverAlias?: Record<T, string>,
): TurnoverTableData[] => {
    if (!turnoverArray) {
        return [];
    }

    return turnoverArray.map((arrayItem) => ({
        ...DEFAULT_TURNOVER_ITEM,
        key: String(arrayItem),
        name: turnoverAlias?.[arrayItem] ?? String(arrayItem),
        companyType: companyType,
    }));
};

export const mapTurnoverSelectedTableData = <T extends number | string>(turnoverArray: T[]) => {
    if (!turnoverArray) {
        return {} as Record<T, boolean>;
    }

    const selected = {} as Record<T, boolean>;

    turnoverArray.forEach((turnoverItem) => {
        selected[turnoverItem] = true;
    });

    return selected;
};

export const mapBarChartData = (
    turnoverByTimeClients: TurnoverByTimeClients[],
    clientNames?: Record<number, string>,
) => {
    if (!turnoverByTimeClients) {
        return [];
    }

    return turnoverByTimeClients.map(([[timestamp, clientId], [turnover, percent]]) => ({
        x: timestamp * 1e3,
        y: turnover,
        clientId,
        clientName: clientNames?.[clientId],
        percent,
    }));
};

export const mapTurnoverTableData = (
    tableData: TurnoverTableData[],
    turnoverBy: TurnoverBy[],
    totalTurnover: number,
    pocketShares?: PocketShare[],
): TurnoverTableData[] => {
    if (!tableData || !turnoverBy) {
        return [];
    }

    return tableData.map((tableDataItem) => {
        const turnover = turnoverBy?.find((item) => String(item[0]) === tableDataItem.key);
        const pocketShare = pocketShares?.find((item) => String(item[0]) === tableDataItem.key);

        const updatedTableDataItem: TurnoverTableData = {
            ...DEFAULT_TURNOVER_ITEM,
            key: tableDataItem.key,
            name: tableDataItem.name,
            companyType: tableDataItem.companyType,
        };

        if (turnover) {
            const [, amount, amountTrend] = turnover;
            updatedTableDataItem.amountFormatted = fmt(amount, fmtConfig).formattedValue;
            updatedTableDataItem.amount = amount;
            updatedTableDataItem.turnoverFormatted = formatAsPercent(
                String(totalTurnover ? amount / totalTurnover : 0),
            );
            updatedTableDataItem.amountTrend = amountTrend === -1 ? undefined : amountTrend > 0;
            updatedTableDataItem.turnover = toPercent(totalTurnover ? amount / totalTurnover : 0);
        }
        if (pocketShare) {
            const [, value] = pocketShare;
            updatedTableDataItem.pocketShareFormatted = formatAsPercent(value);
            updatedTableDataItem.pocketShare = toPercent(value);
        }

        return updatedTableDataItem;
    });
};

export const getSelectedTableItemsLabel = (tableData: TurnoverTableData[], selectedItems: Record<string, boolean>) => {
    if (!tableData || !selectedItems) {
        return "- of -";
    }

    const selectedCount = Object.values(selectedItems).filter((entity) => Boolean(entity)).length;

    if (selectedCount !== tableData.length) {
        return `${selectedCount} of ${tableData.length}`;
    }

    const activeCount = tableData.filter((item) => item.amount !== 0).length;

    return `${activeCount} of ${tableData.length}`;
};
