import { ReactNode } from "react";
import styled from "styled-components";
import { DesctructiveButton, PlainButton } from "../buttons";
import { Modal } from "../Modal";
import { H2, P as Description } from "../typography";

export interface ConfirmationModalProps {
    title: string | ReactNode;
    description: string | ReactNode;
    confirmButtonTitle: string | ReactNode;
    cancelButtonTitle?: string | ReactNode;
    onConfirm: () => void;
    onClose: () => void;
}

const Content = styled.div`
    padding: 12px 12px 24px 12px;
    color: ${(p) => p.theme.colors.ui100};
    width: 360px;
`;

const Title = styled(H2)`
    padding-bottom: 12px;
`;

const Actions = styled.div`
    display: flex;
    gap: 8px;
    padding: 16px 12px;
    width: 100%;
    & > * {
        flex: 1;
    }
    border-top: solid 1px ${(p) => p.theme.colors.ui8};
`;

export const ConfirmationModal = ({
    title,
    description,
    confirmButtonTitle,
    onConfirm,
    onClose,
    cancelButtonTitle = "Cancel",
}: ConfirmationModalProps) => (
    <Modal isOpen onClose={onClose}>
        <Content>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Content>
        <Actions>
            <PlainButton size="large" onClick={onClose}>
                {cancelButtonTitle}
            </PlainButton>
            <DesctructiveButton size="large" onClick={onConfirm}>
                {confirmButtonTitle}
            </DesctructiveButton>
        </Actions>
    </Modal>
);
