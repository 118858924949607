import { EmDash, when } from "@fm-frontend/utils";
import { useAppSelector } from "app/store";
import {
    selectExchanges,
    selectResetAllData,
    selectSelectedInstrument,
    selectSelectedVolume,
    selectTableUpdate,
} from "app/store/appSlice";
import { ItemType, MembersState } from "app/store/appSlice.types";
import React, { useEffect, useMemo, useState } from "react";
import { TableDataUpdate, UptimeData } from "types";
import { SimpleTable } from "./SimpleTable";
import { SkeletonTable } from "./SkeletonTable";
import { FlexTableWrapper } from "./style";
import { formatAsNumber, formatAsPercent, getAppliedFee } from "./utils";

const SPREAD_EXPIRATION_TIME = 10 * 1000;

export interface TableData {
    key: string;
    name?: string;
    uptime?: string;
    fee?: string;
    bid?: string;
    ask?: string;
    spread?: string;
    spreadInPercents?: string;
    spreadInCurrency?: string;
    imgUrl?: string;
    type?: ItemType;
    items: TableData[];
    positive?: number;
    timestamp: number;
}

export const Table: React.FC = () => {
    const exchanges = useAppSelector(selectExchanges);
    const isResetActive = useAppSelector(selectResetAllData);
    const instrument = useAppSelector(selectSelectedInstrument);
    const volume = useAppSelector(selectSelectedVolume);
    const tableUpdate = useAppSelector<TableDataUpdate | undefined>(selectTableUpdate);
    const uptimeUpdate = useAppSelector<UptimeData | undefined>((state) => state.app.uptimeUpdate);
    const enabledMembers = useAppSelector<MembersState>((state) => state.app.membersState.enabledMembers);
    const currency = instrument?.split("/")[1] ?? "";

    const [tableData, setTableData] = useState<TableData[]>([]);

    const formatSpreadInCurrency = useMemo(
        () => (bid: number | null, ask: number | null, effectiveSpread: number | null) => {
            if (bid === null || ask === null || effectiveSpread === null) {
                return "";
            }

            const middleValue = (Number(ask) + Number(bid)) / 2;
            const spreadValue = middleValue * effectiveSpread;
            const precision = Math.max(
                formatAsNumber(bid).split(".")[1]?.length,
                formatAsNumber(ask).split(".")[1]?.length,
                0,
            );

            return `${spreadValue.toFixed(precision)} ${currency}`;
        },
        [currency],
    );

    useEffect(() => {
        if (isResetActive) {
            setTableData([]);
            return;
        }

        const tableData: TableData[] = exchanges
            .filter((member) => Boolean(enabledMembers[member.name]))
            .map((exchange) => {
                return {
                    key: exchange.name,
                    name: exchange.alias,
                    fee: formatAsPercent(getAppliedFee(exchange, volume, instrument), 3),
                    bid: formatAsNumber(exchange.bid),
                    ask: formatAsNumber(exchange.ask),
                    spread: formatAsNumber(exchange.spread),
                    spreadInPercents: formatAsPercent(exchange.effectiveSpread),
                    spreadInCurrency: formatSpreadInCurrency(exchange.bid, exchange.ask, exchange.effectiveSpread),
                    imgUrl: exchange.imgUrl,
                    type: exchange.type,
                    timestamp: new Date().getTime(),
                } as TableData;
            });

        setTableData(tableData);
    }, [exchanges, isResetActive, instrument, enabledMembers]);

    useEffect(() => {
        if (!uptimeUpdate?.length) {
            return;
        }

        const [, key, uptime] = uptimeUpdate;

        setTableData((tableData) => {
            return tableData.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        uptime: formatAsPercent(uptime),
                    };
                }

                return item;
            });
        });
    }, [uptimeUpdate]);

    useEffect(() => {
        if (!tableUpdate?.length) {
            return;
        }

        const [, key, bid, ask, effectiveSpread, timestamp] = tableUpdate;

        const currentTimestamp = new Date().getTime();

        setTableData((tableData) => {
            return tableData.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        bid: formatAsNumber(bid),
                        ask: formatAsNumber(ask),
                        spread: formatAsNumber(bid && ask ? bid - ask : null),
                        spreadInPercents: formatAsPercent(effectiveSpread),
                        spreadInCurrency: formatSpreadInCurrency(bid, ask, effectiveSpread),
                        positive: effectiveSpread,
                        timestamp: timestamp,
                    } as TableData;
                }

                if (currentTimestamp - item.timestamp > SPREAD_EXPIRATION_TIME) {
                    return {
                        ...item,
                        bid: EmDash,
                        ask: EmDash,
                        spread: EmDash,
                        spreadInPercents: EmDash,
                        spreadInCurrency: "",
                        positive: undefined,
                    } as TableData;
                }

                return item;
            });
        });
    }, [tableUpdate]);

    const { exs, mps, lps, bestSpread } = useMemo(() => {
        const mps: TableData[] = [];
        const lps: TableData[] = [];
        const exs: TableData[] = [];
        const spreads: number[] = [];

        tableData.forEach((item) => {
            if (item.type === ItemType.FM) {
                mps.push(item);
            }
            if (item.type === ItemType.LP) {
                lps.push(item);
            }
            if (item.type === ItemType.Exchange) {
                exs.push(item);
            }

            item.positive && spreads.push(item.positive!);
        });

        return {
            exs,
            mps,
            lps,
            bestSpread: Math.min(...spreads),
        };
    }, [tableData]);

    return Boolean(tableData.length) ? (
        <FlexTableWrapper>
            {when(mps.length, <SimpleTable data={mps} type={ItemType.FM} bestSpread={bestSpread} />)}
            {when(lps.length, <SimpleTable data={lps} type={ItemType.LP} bestSpread={bestSpread} />)}
            {when(exs.length, <SimpleTable data={exs} type={ItemType.Exchange} bestSpread={bestSpread} />)}
        </FlexTableWrapper>
    ) : (
        <FlexTableWrapper>
            <SkeletonTable columnCount={7} rowCount={8} />
        </FlexTableWrapper>
    );
};
