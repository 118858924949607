import React from "react";
import { SkeletonLine } from "../../style";
import { FlexCell, FlexRow, FlexTable, TableSkeletonWrapper } from "./style";
import { getNumberList } from "./utils";

export const SkeletonTable: React.VFC<{
    columnCount: number,
    rowCount: number
}> = ({ columnCount, rowCount }) => (
    <FlexTable>
        {
            getNumberList(rowCount).map((value) => (
                <FlexRow key={value}>
                    {
                        getNumberList(columnCount).map(value => (
                                <FlexCell key={value}>
                                    <TableSkeletonWrapper>
                                        <SkeletonLine/>
                                    </TableSkeletonWrapper>
                                </FlexCell>
                            )
                        )
                    }
                </FlexRow>
            ))
        }
    </FlexTable>
)