import { DropdownOption, InlineDropdown, PSmallBold } from "@fm-frontend/uikit";
import { InlineContainer } from "@fm-frontend/uikit/src/components/dropdowns/Trigger/styled";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { routerPaths } from "../app/router/Router";
import { useAppDispatch, useAppSelector } from "../app/store";
import { setIsLoading, setMasterConfig, setTurnoverConfig } from "../app/store/turnoverSlice";
import { ClientConfig, MasterConfig } from "../app/store/turnoverSlice.types";
import { useIsActivePath } from "../hooks/useIsActivePath";

const DropdownWrapper = styled.div`
    ${InlineContainer} {
        height: 32px;

        ${PSmallBold} {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.005em;
        }
    }
`;

interface SelectClient {
    client: string;
}

const ClientsDropdown: React.FC<{
    clientOptions: DropdownOption[];
    defaultClientId: string;
    onClientSelect: (companyId: string) => void;
}> = ({ clientOptions, defaultClientId, onClientSelect }) => {
    const { control, watch } = useForm<SelectClient>({
        defaultValues: {
            client: defaultClientId,
        },
    });
    const clientWatch = watch("client");

    useEffect(() => {
        onClientSelect(clientWatch);
    }, [clientWatch, onClientSelect]);

    return (
        <DropdownWrapper>
            <InlineDropdown
                size="small"
                align={"end"}
                placeholder="Admin"
                searchable
                name="client"
                control={control}
                options={clientOptions}
            />
        </DropdownWrapper>
    );
};

const isMasterConfig = (config?: ClientConfig): config is MasterConfig => {
    return (
        Boolean(config) &&
        (config as MasterConfig).counterpartiesConfig !== undefined &&
        (config as MasterConfig).subaccountsConfig !== undefined
    );
};

export const AdminClientSearch: React.FC = () => {
    const dispatch = useAppDispatch();
    const { adminConfig, config } = useAppSelector((state) => state.turnover) ?? {};
    const { isActivePath } = useIsActivePath();
    const isAnalyticsPage = isActivePath(routerPaths.analytics.path);

    const clientOptions: DropdownOption[] = useMemo(() => {
        if (!adminConfig?.length) {
            return [] as DropdownOption[];
        }

        return adminConfig
            .map((clientConfig) => {
                const turnoverConfig = isMasterConfig(clientConfig)
                    ? clientConfig.counterpartiesConfig
                    : clientConfig;
                const { company_id, company_name, client, instrument } = turnoverConfig;

                return {
                    value: `${company_id}`,
                    text: company_id > 0 ? `(${company_id}) ${company_name}` : company_name,
                    disabled: !client?.length && !instrument?.length,
                } as DropdownOption;
            })
            .sort((left, right) =>
                left.disabled === right.disabled ? 0 : right.disabled ? -1 : 1,
            );
    }, [adminConfig]);

    const onClientSelect = useCallback(
        (clientId: string) => {
            const selectedClientConfig = adminConfig?.find((clientConfig) => {
                if (isMasterConfig(clientConfig)) {
                    return clientConfig.counterpartiesConfig.company_id === Number(clientId);
                }
                return clientConfig.company_id === Number(clientId);
            });

            dispatch(setIsLoading(true));

            if (isMasterConfig(selectedClientConfig)) {
                dispatch(setMasterConfig(selectedClientConfig));
                dispatch(setTurnoverConfig(selectedClientConfig.counterpartiesConfig));
                return;
            }

            dispatch(setMasterConfig(undefined));
            dispatch(setTurnoverConfig(selectedClientConfig));
        },
        [dispatch, adminConfig],
    );

    if (adminConfig && isAnalyticsPage && config?.company_id) {
        const searchParams = new URLSearchParams(window.location.search);
        const companyId = searchParams.get("companyId");

        return (
            <DropdownWrapper>
                <ClientsDropdown
                    clientOptions={clientOptions}
                    onClientSelect={onClientSelect}
                    defaultClientId={companyId ?? `${config.company_id}`}
                />
            </DropdownWrapper>
        );
    }

    return null;
};
