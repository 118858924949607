import alpha from "color-alpha";
import { css, DefaultTheme } from "styled-components";
import { onHover } from "../const";
import { BaseMappedColors, MappedColors, StaticColors } from "../types";

type ThemeCtx = { theme: DefaultTheme };
// TODO get rid of selectors
export const selectors = {
    colorSmallButtonBG: ({ theme }: ThemeCtx) => theme.colors.smallButtonBG,
    colorRed: ({ theme }: ThemeCtx) => theme.colors.red,
    brandColor: ({ theme }: ThemeCtx) => theme.colors.brand,
    mainBG: ({ theme }: ThemeCtx) => theme.colors.mainBG,
    activeBG: ({ theme }: ThemeCtx) => theme.colors.activeBG,
    textFG: ({ theme }: ThemeCtx) => theme.colors.textFG,
    textBody: ({ theme }: ThemeCtx) => theme.colors.textBody,
    textBody1: ({ theme }: ThemeCtx) => theme.colors.textBody1,
    textBody2: ({ theme }: ThemeCtx) => theme.colors.textBody2,
    textSubdued: ({ theme }: ThemeCtx) => theme.colors.textSubdued,
    textSubdued2: ({ theme }: ThemeCtx) => theme.colors.textSubdued2,
    textActiveFG: ({ theme }: ThemeCtx) => theme.colors.textActiveFG,
    colorSelectBg: ({ theme }: ThemeCtx) => theme.colors.selectBG,
    colorSelectFg: ({ theme }: ThemeCtx) => theme.colors.selectFG,
    mixinHeader1: ({ theme }: ThemeCtx) => theme.mixins.header1,
    mixinHeader2: ({ theme }: ThemeCtx) => theme.mixins.header2,
    mixinHeader3: ({ theme }: ThemeCtx) => theme.mixins.header3,
    mixinBody1: ({ theme }: ThemeCtx) => theme.mixins.body1,
    mixinBody2: ({ theme }: ThemeCtx) => theme.mixins.body2,
    mixinBody3: ({ theme }: ThemeCtx) => theme.mixins.body3,
    mixinInterface1: ({ theme }: ThemeCtx) => theme.mixins.interface1,
    mixinInterface2: ({ theme }: ThemeCtx) => theme.mixins.interface2,
    mixinInterface3: ({ theme }: ThemeCtx) => theme.mixins.interface3,
    mixinInterface4: ({ theme }: ThemeCtx) => theme.mixins.interface4,
    mixinBubble: ({ theme }: ThemeCtx) => theme.mixins.bubble,
    mixinHover: ({ theme, disabled }: ThemeCtx & { disabled?: boolean }) => (disabled ? null : theme.mixins.hover),
    pSmall: ({ theme }: ThemeCtx) => theme.mixins.pSmall,
    pSmallBold: ({ theme }: ThemeCtx) => theme.mixins.pSmallBold,
    fontRegular: () => css`
        font-family: "InterVariable", sans-serif;
        font-weight: 400;
    `,
    fontMedium: () => css`
        font-family: "InterVariable", sans-serif;
        font-weight: 600;
    `,
};

type OpacitiesMap = {
    [Key in BaseMappedColors]: readonly number[];
};
export const COLOR_OPACITIES: OpacitiesMap = {
    ui: [100, 96, 72, 52, 32, 24, 20, 12, 8, 4],
    uiWhite: [100, 80, 72, 52, 32, 20, 12, 8, 4],
    brand: [100, 80, 72, 52, 32, 20, 12, 8, 4],
    positive: [100, 80, 72, 52, 32, 20, 16, 8, 4],
    negative: [100, 80, 72, 52, 32, 20, 16, 8, 4],
    process: [100, 80, 72, 52, 32, 20, 16, 8, 4],
    attention: [100, 80, 72, 52, 32, 20, 16, 8, 4],
    subpurple: [100, 80, 72, 52, 32, 20, 16, 8, 4],
} as const;

type BaseColors = Record<BaseMappedColors | StaticColors, string>;
const generateColors = (baseColors: BaseColors) => {
    const mappedColors = Object.entries(COLOR_OPACITIES).reduce(
        (colors, [baseColorName, opacities]) => ({
            ...colors,
            ...opacities.reduce(
                (generatedColors, opacity) => ({
                    ...generatedColors,
                    [`${baseColorName}${opacity}`]: alpha(
                        baseColors[baseColorName as keyof OpacitiesMap],
                        opacity / 100,
                    ),
                }),
                {},
            ),
        }),
        {},
    );

    const { black, bg } = baseColors;

    return {
        ...(mappedColors as Record<MappedColors, string>),
        black,
        bg,
    };
};

const commonTextCssLightTheme = css`
    font-family: "InterVariable", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
`;
const light: DefaultTheme = {
    customGradients: {
        // subBg: "linear-gradient(180deg, #F3F3F5 46vh, #E3E0F7 100vh)",
        subBg: "linear-gradient(180deg, #F3F3F5 46%, #E3E0F7 100%) fixed 100vh",
    },
    colors: {
        ...generateColors({
            ui: "rgba(36, 48, 52, 1)",
            uiWhite: "rgba(255, 255, 255, 1)",
            brand: "rgba(22, 199, 176, 1)",
            positive: "rgba(24, 189, 90, 1)",
            negative: "rgba(246, 60, 48, 1)",
            process: "rgba(54, 98, 255, 1)",
            attention: "rgba(242, 147, 5, 1)",
            subpurple: "rgba(116, 99, 224, 1)",
            black: "#05090B",
            bg: "#F5F5F5",
        }),
        // TODO delete deprecated colors
        action: "rgba(22, 199, 176, 1)", // brand100
        action1: "rgba(22, 199, 176, 0.72)", // brand72
        action2: "rgba(22, 199, 176, 0.32)", // brand32
        action3: "rgba(22, 199, 176, 0.16)", // brand16
        action4: "rgba(22, 199, 176, 0.12)", // brand12
        textBody: "rgba(36, 48, 52, 1)", // ui100
        textBody1: "rgba(36, 48, 52, 0.72)", // ui72
        textBody2: "rgba(36, 48, 52, 0.52)", // ui52
        textBody3: "rgba(36, 48, 52, 0.32)", // ui32
        textBody4: "rgba(36, 48, 52, 0.2)", // ui20
        textBody5: "rgba(36, 48, 52, 0.12)", // ui12
        textBody6: "rgba(36, 48, 52, 0.08)", // ui8
        textBody7: "rgba(36, 48, 52, 0.04)", // ui4
        ask: "rgba(103, 208, 53, 1)",
        bid: "rgba(246, 60, 48, 0.8)",
        attention: "rgba(255, 194, 38, 1)",
        error: "rgba(246, 60, 48, 1)",
        extra: "rgba(54, 98, 255, 1)",
        extra1: "rgba(240, 128, 58, 1)",
        black: "rgba(5, 9, 11, 1)",
        background: "rgba(255, 255, 255, 0.002)",
        brand: "#16C7B0",
        textFG: "#000000",
        textActiveFG: "#16C7B0",
        textSubdued: "#999999",
        textSubdued2: "#CCCCCC",
        mainBG: "#F5F5F5",
        activeBG: "#FFFFFF",
        selectBG: "#E8E8E8",
        selectBgHover: "#E3E3E3",
        selectLightBG: "#F2F2F2",
        selectFG: "#010101",
        commitButtonBG: "#329588",
        smallButtonBG: "#F2F2F2",
        sendButtonBG: "#E1F5F3",
        iconFG: "#C2C2C2",
        red: "#FE1833",
        blue: "#3662FF",
        yellow: "#FFC226",
        green: "rgb(51, 184, 167)",
        checkboxStroke: "#E5E5E5",
        menuHrBG: "#EBEAEA",
        listItemHover: "#F7F7F7",
    },
    transitions: {
        default: "75ms ease-out",
        long: "150ms ease-out",
        flicker: "250ms cubic-bezier(0,0,.73,1.87)",
    },
    mixins: {
        body1: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.005em;
        `,
        body2: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.005em;
        `,
        body3: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.005em;
        `,
        header1: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.005em;
            color: ${selectors.textBody};
        `,
        header2: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0;
            color: ${selectors.textBody};
        `,
        header3: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        `,
        interface1: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.02em;
        `,
        interface2: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
        `,
        p: css`
            ${commonTextCssLightTheme};
            font-size: 14px;
            line-height: 18px;
        `,
        interface3: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: -0.005em;
        `,
        interface4: css`
            font-family: "InterVariable", sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.005em;
        `,
        pBold: css`
            ${commonTextCssLightTheme};
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
        `,
        pSmall: css`
            ${commonTextCssLightTheme};
            font-size: 12px;
            line-height: 16px;
        `,
        pSmallBold: css`
            ${commonTextCssLightTheme};
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
        `,
        pXSmall: css`
            ${commonTextCssLightTheme};
            font-size: 10px;
            line-height: 12px;
        `,
        bubble: css`
            border: 1px solid ${selectors.colorSelectBg};
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
            border-radius: 12px;
        `,
        hover: css`
            background-color: ${selectors.colorSmallButtonBG};
            transition: background-color ${(p) => p.theme.transitions.default};
            cursor: pointer;
            && {
                ${onHover} {
                    background-color: ${selectors.colorSelectBg};
                }
            }
        `,
        nonInteractive: css`
            pointer-events: none;
            user-select: none;
        `,
        hyphens: css`
            overflow-wrap: break-word;
            hyphens: auto;
        `,
    },
};

export const themes = { light };
