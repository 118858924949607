import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateColor } from "components/linerChart/utils";
import { CACHED_MEMBERS_STATE, COLORS, LpKey } from "const";
import { QueryParams } from "hooks/useQueryParams";
import { SelectItem, SnapshotData, SnapshotDataUpdate, TableDataUpdate, UptimeData } from "types";
import { getCachedActiveMembers } from "utils";
import { AppSlice, Config, ItemType, MembersState, UserRoles } from "./appSlice.types";
import { RootState } from "./index";

const MAKER_LP_REGEX = /_lp_\d+$/;

const initConfig: Config = {
    access_type: UserRoles.Public,
    instruments: [],
    sizes: {},
    volumes: [],
    exchanges: [],
    periods: [],
    exchange_names: {},
};

const initialState: AppSlice = {
    config: initConfig,
    resetAllData: false,
    resetChartData: false,
    membersState: {
        enabledMembers: {},
        activeMembers: {},
    },
    periods: [],
    selectedPeriod: "",
    snapshot: [],
    authenticated: true,
    colors: COLORS,
    error: false,
};

const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        resetConfig: (state) => {
            state.config = initConfig;
        },
        setConfig: (state, action: PayloadAction<Config>) => {
            state.config = action.payload;
            state.config.exchanges.forEach((exchange) => {
                exchange.uptime = null;
                exchange.bid = null;
                exchange.ask = null;
                exchange.spread = null;
                exchange.spreadInPercents = null;
                exchange.effectiveSpread = null;
                exchange.imgUrl = "img/" + exchange.name + ".png";
                exchange.type =
                    exchange.name === "efx"
                        ? ItemType.FM
                        : exchange.name.includes(LpKey)
                        ? ItemType.LP
                        : ItemType.Exchange;
                exchange.alias = action.payload.exchange_names[exchange.name];

                if (!state.colors[exchange.name]) {
                    const color = generateColor(Object.values(state.colors));
                    state.colors = {
                        ...state.colors,
                        [exchange.name]: color,
                    };
                }
            });

            state.config.exchanges = state.config.exchanges.sort((left, right) => {
                if (left.type === ItemType.FM) {
                    return -1;
                }
                if (right.type === ItemType.FM) {
                    return 1;
                }
                if (left.type === ItemType.LP && right.type !== ItemType.LP) {
                    return -1;
                }
                if (left.type !== ItemType.LP && right.type === ItemType.LP) {
                    return 1;
                }

                return left.alias.localeCompare(right.alias);
            });
        },
        resetAllData: (state, action: PayloadAction<boolean>) => {
            state.resetAllData = action.payload;

            if (action.payload) {
                state.membersState.enabledMembers = {};
                state.snapshot = [];
            }
        },
        resetSnapshot: (state) => {
            state.snapshot = [];
        },
        resetChartData: (state, action: PayloadAction<boolean>) => {
            state.resetChartData = action.payload;
        },
        updateInstrumentLabel: (state, action: PayloadAction<string>) => {
            state.selectedInstrument = action.payload;
        },
        setVolume: (state, action: PayloadAction<number>) => {
            state.selectedVolume = action.payload;
        },
        setAmount: (state, action: PayloadAction<number>) => {
            state.selectedAmount = action.payload;
        },
        updatePeriod: (state, action: PayloadAction<string>) => {
            state.selectedPeriod = action.payload;
        },
        setPeriods: (state, action: PayloadAction<SelectItem[]>) => {
            state.periods = action.payload;
        },
        setTableUpdate: (state, { payload }: PayloadAction<TableDataUpdate>) => {
            state.tableUpdate = payload;

            const [, key, , ,] = payload;
            const isEnabled = state.membersState.enabledMembers[key];

            if (!isEnabled) {
                state.membersState.enabledMembers[key] = true;
            }
        },
        setChartUpdate: (state, action: PayloadAction<SnapshotDataUpdate>) => {
            state.chartUpdate = action.payload;
        },
        setUptimeUpdate: (state, { payload }: PayloadAction<UptimeData>) => {
            state.uptimeUpdate = payload;
        },
        submitFilter: (state, action: PayloadAction<QueryParams>) => {
            return;
        },
        setSnapshotData: (state, action: PayloadAction<SnapshotData[]>) => {
            state.snapshot = action.payload;
        },
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setToken: (state, action: PayloadAction<string | undefined>) => {
            state.token = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setEnabledMembers: (state) => {
            state.config.exchanges.forEach((member) => {
                const isSnapshotExist = state.snapshot.some(([key, ,]) => key === member.name);
                state.membersState.enabledMembers[member.name] = isSnapshotExist;
            });
        },
        setActiveMembers: (state, { payload }: PayloadAction<MembersState>) => {
            for (const [key, isEnabled] of Object.entries(payload)) {
                state.membersState.activeMembers[key] = isEnabled;
            }

            sessionStorage.setItem(CACHED_MEMBERS_STATE, JSON.stringify(state.membersState.activeMembers));
        },
        setDefaultActiveMembers: (state) => {
            const cachedActiveMembers: MembersState = getCachedActiveMembers();

            state.config.exchanges.forEach((member) => {
                let isActive = false;

                if (member.type === ItemType.FM) {
                    isActive = true;
                }
                if (state.config.access_type === UserRoles.Maker && MAKER_LP_REGEX.test(member.name)) {
                    isActive = true;
                }
                if (state.config.access_type !== UserRoles.Maker && member.type === ItemType.Exchange) {
                    isActive = true;
                }

                if (cachedActiveMembers.hasOwnProperty(member.name)) {
                    isActive = Boolean(cachedActiveMembers[member.name]);
                }

                state.membersState.activeMembers[member.name] = isActive;
            });
        },
    },
});

export const {
    resetConfig,
    setConfig,
    resetAllData,
    resetSnapshot,
    resetChartData,
    updateInstrumentLabel,
    updatePeriod,
    setVolume,
    setPeriods,
    setAmount,
    setChartUpdate,
    setTableUpdate,
    setUptimeUpdate,
    submitFilter,
    setSnapshotData,
    setAuthenticated,
    setToken,
    setError,
    setEnabledMembers,
    setActiveMembers,
    setDefaultActiveMembers,
} = appSlice.actions;

export const selectResetAllData = (state: RootState) => state.app.resetAllData;
export const selectResetChartData = (state: RootState) => state.app.resetChartData;
export const selectSelectedInstrument = (state: RootState) => state.app.selectedInstrument;
export const selectSelectedPeriod = (state: RootState) => state.app.selectedPeriod;
export const selectSelectedVolume = (state: RootState) => state.app.selectedVolume;
export const selectSelectedAmount = (state: RootState) => state.app.selectedAmount;
export const selectPeriods = (state: RootState) => state.app.periods;

export const selectTableUpdate = (state: RootState) => state.app.tableUpdate;
export const selectChartUpdate = (state: RootState) => state.app.chartUpdate;
export const selectSnapshot = (state: RootState) => state.app.snapshot;

export const selectExchanges = (state: RootState) => state.app.config.exchanges;
export const selectConfig = (state: RootState) => state.app.config;

export const selectAuthenticated = (state: RootState) => state.app.authenticated;

export const selectColors = (state: RootState) => state.app.colors;
export const selectError = (state: RootState) => state.app.error;

export default appSlice.reducer;
