import styled from "styled-components";
import { Container } from "~components/uiCard/style";
import { HIDE_SCROLLBAR, onMobile, onTablet } from "~const";

export const TurnoverContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 8px;
`;

export const TablesContainer = styled.div`
    display: flex;
    flex-direction: row;

    gap: inherit;

    ${Container} {
        width: 50%;
        padding-bottom: 0;
        height: fit-content;
        overflow-x: hidden;
        overflow-y: auto;
    }

    ${onTablet} {
        flex-direction: column;

        ${Container} {
            width: 100%;
        }
    }
`;

export const FilterShortCut = styled.div`
    width: 95%;
    max-width: 1180px;
    gap: 4px;
    display: flex;
    position: relative;
    margin: 0 auto;
    min-width: 320px;
    padding-bottom: 0;
`;

export const TurnoverFilterButton = styled.button`
    display: flex;
    padding: 4px 8px;
    gap: 5px;

    min-width: fit-content;
    height: 24px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;

    color: rgba(22, 199, 176, 1);
    background-color: #ffffff;
    border-radius: 100px;
    box-shadow: 0 1px 4px rgba(36, 48, 52, 0.08);
`;

export const ActionButton = styled(TurnoverFilterButton)`
    color: #ffffff;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 6.5px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: none;
    cursor: pointer;
`;

export const FilterShortCutContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, rgba(36, 48, 52, 0.08), rgba(36, 48, 52, 0.08)), #16c7b0;
    overflow: auto;
    margin-bottom: 12px;
    margin-top: -12px;
`;

export const AnalyticsTableWrapper = styled.div`
    position: relative;
    overflow: auto;
    width: calc(100% + 22px);
    max-height: calc(100vh - 485px);
    min-height: 80px;
    margin-left: -11px;
    margin-right: -12px;
    padding-right: 0;

    ${HIDE_SCROLLBAR}

    ${onMobile} {
        max-height: 400px;
    }
`;
