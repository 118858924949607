import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { SignInSteps } from "./SignIn";
import { Button, Error, FormError, FormRow, HeadTitle } from "./style";
import { ValidationRules } from "./utils";
import { Loading } from "../../components/Loading";

export const PasswordReset: React.FC<{
    setEmail: (email: string) => void,
    setStep: (step: SignInSteps) => void
}> = ({
          setEmail,
          setStep
      }) => {

    const {
        register,
        handleSubmit,
        formState: {isValid, errors},
    } = useForm<any>({
        mode: "onChange"
    });

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        if(!isValid) {
            return;
        }

        setLoading(true);

        Auth.forgotPassword(data.email)
            .then((user) => {
                setError(undefined);
                setLoading(false);
                setEmail(data.email);
                setStep(SignInSteps.ResetPasswordSubmit)
            })
            .catch((error) => {
                setLoading(false);
                setError(error?.message);
            });
    }

    return (
        <>
            <HeadTitle>Reset your password</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    <label>Email</label>
                    <input {...register('email', {
                        ...ValidationRules.email

                    })}
                           placeholder='trader@example.com'
                    />
                    {
                        errors.email && (
                            <Error>{errors.email.message}</Error>
                        )
                    }
                </FormRow>
                <FormRow>
                    {
                        error && (
                            <FormError>{error}</FormError>
                        )
                    }
                    <Loading loading={loading}>
                        <Button type={'submit'}>Send Code</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    )
}