import React from "react";
import { TableToggleRowsSelectedProps } from "react-table";
import styled from "styled-components";
import { EyeIcon } from "~components/icons";

export const TableEyeContainer = styled.div``;

interface TableEyeProps extends TableToggleRowsSelectedProps {
    checked?: boolean;
}

export const TableEye: React.VFC<TableEyeProps> = ({ checked }) => {
    return (
        <TableEyeContainer>
            <EyeIcon checked={checked} />
        </TableEyeContainer>
    );
};
