import { getEnv } from "@fm-frontend/utils";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "~app/store";
import { forceRestartConnecting } from "~app/store/socketSlice";
import { Loading } from "~components/Loading";
import { SignInSteps } from "./SignIn";
import { Button, Error, FormError, FormRow, HeadTitle } from "./style";
import { ValidationRules } from "./utils";

export const PasswordChange: React.FC<{
    userState: any;
    setStep: (step: SignInSteps) => void;
}> = ({ userState, setStep }) => {
    const isAdminPool: boolean = getEnv().REACT_APP_IS_ADMIN_POOL === "true";

    const {
        watch,
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<any>({
        mode: "onChange",
    });

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const password = watch("password");
    const history = useHistory();
    const dispatch = useAppDispatch();

    const onSubmit = (data: any) => {
        if (!isValid) {
            return;
        }

        setLoading(true);

        Auth.completeNewPassword(userState, data.password, {
            name: data.name,
            family_name: data.familyName,
        })
            .then((user: any) => {
                setError(undefined);
                setLoading(false);

                if (user.challengeName === "MFA_SETUP") {
                    setStep(SignInSteps.SetupMfaCode);
                    return;
                }

                dispatch(forceRestartConnecting());
                history.push("/spreads");
            })
            .catch((error) => {
                setLoading(false);
                setError(error?.message);
            });
    };

    return (
        <>
            <HeadTitle>Change Password</HeadTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow>
                    <label>Password</label>
                    <input
                        {...register("password", {
                            ...ValidationRules.password,
                        })}
                        placeholder="Password"
                        type={"password"}
                    />
                    {errors.password && <Error>{errors.password.message}</Error>}
                </FormRow>
                <FormRow>
                    <label>Confirm Password</label>
                    <input
                        {...register("confirmPassword", {
                            ...ValidationRules.confirmPassword(password),
                        })}
                        placeholder="Confirm Password"
                        type={"password"}
                    />
                    {errors["confirmPassword"] && <Error>{errors["confirmPassword"].message}</Error>}
                </FormRow>
                {isAdminPool && (
                    <>
                        <FormRow>
                            <label>Family Name</label>
                            <input
                                {...register("familyName", {
                                    ...ValidationRules.required,
                                })}
                                placeholder="Family name"
                            />
                            {errors["familyName"] && <Error>{errors["familyName"].message}</Error>}
                        </FormRow>
                        <FormRow>
                            <label>Name</label>
                            <input
                                {...register("name", {
                                    ...ValidationRules.required,
                                })}
                                placeholder="Name"
                            />
                            {errors.name && <Error>{errors.name.message}</Error>}
                        </FormRow>
                    </>
                )}
                <FormRow>
                    {error && <FormError>{error}</FormError>}
                    <Loading loading={loading}>
                        <Button type={"submit"}>Change Password</Button>
                    </Loading>
                </FormRow>
            </form>
        </>
    );
};
