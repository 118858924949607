import styled, { css } from "styled-components";
import { onHover, onMobile, onTablet } from "../../const";

export const Container = styled.div`
    width: 100%;
    height: 140px;
`;

export const BarChartTooltip = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: -20px;
    width: fit-content;
    left: 50%;
    transform: translate(-50%);

    span {
        color: rgba(22, 199, 176, 1);
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.005em;
    }
`;

export const BarChartXAxis = styled.div<{ isVisible?: boolean }>`
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};

    ${onMobile} {
        display: none;
    }

    justify-content: center;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
    width: 70px;

    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.005em;

        color: rgba(36, 48, 52, 0.72);
    }
`;

export const BarChartContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 34px;
    max-width: 34px;
    height: 100%;
    padding: 0 5px;
    transition: opacity 0.3s;

    ${BarChartTooltip} {
        display: none;
    }

    &:hover {
        opacity: 1 !important;
    }

    ${onHover} {
        ${BarChartTooltip} {
            display: block;
        }

        ${BarChartXAxis} {
            display: flex;

            span {
                color: ${(p) => p.theme.colors["textFG"]};
            }
        }
    }
`;

export const BarContainer = styled.div<{ inactive?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    height: 120px;
    border-radius: 2px;
    width: 100%;
    background-color: rgba(36, 48, 52, 0.04);
    overflow: hidden;
    gap: 1px;

    ${({ inactive }) =>
        inactive &&
        css`
            background-color: transparent;
            border: 1px solid rgba(36, 48, 52, 0.04);
            transition: border 0s;
        `}

    ${({ inactive }) =>
        !Boolean(inactive) &&
        css`
            ${onHover} {
                background-color: rgba(36, 48, 52, 0.08);
            }
        `}
`;

type BarLineProps = { heightPart: number; $color?: string };
export const BarLine = styled.div.attrs<BarLineProps>((props) => ({
    style: {
        backgroundColor: props.$color ?? "rgba(22, 199, 176, 1)",
        height: props.heightPart > 100 ? "100%" : `${props.heightPart}%`,
    },
}))<BarLineProps>`
    border-radius: 2px;
    min-height: 2px;
    width: 100%;
`;

export const MiddleCenteredContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ChartWrapper = styled.div`
    display: flex;
    height: 136px;
    position: relative;
    justify-content: space-between;

    &:hover ${BarChartContainer} {
        opacity: 0.5;
    }

    ${BarChartContainer} {
        :first-child {
            ${BarChartTooltip} {
                left: 0;
                transform: none;
            }
        }

        ${onTablet} {
            :nth-child(2) {
                ${BarChartTooltip} {
                    left: 10%;
                    transform: translate(-10%);
                }
            }

            :nth-last-child(2) {
                ${BarChartTooltip} {
                    left: 90%;
                    transform: translate(-90%);
                }
            }
        }

        ${onMobile} {
            :nth-child(3) {
                ${BarChartTooltip} {
                    left: 20%;
                    transform: translate(-20%);
                }
            }

            :nth-last-child(3) {
                ${BarChartTooltip} {
                    left: 80%;
                    transform: translate(-80%);
                }
            }
        }

        :last-child {
            ${BarChartTooltip} {
                left: 100%;
                transform: translate(-100%);
            }
        }
    }
`;
